@font-face {
    font-family: 'Neon';
    src: url('./assets/fonts/Neon-Regular.woff') format('woff'), url('./assets/fonts/Neon-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neon';
    src: url('./assets/fonts/Neon-Medium.woff') format('woff'), url('./assets/fonts/Neon-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neon';
    src: url('./assets/fonts/Neon-Black.woff') format('woff'), url('./assets/fonts/Neon-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Regular.woff') format('woff'), url('./assets/fonts/Montserrat-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Medium.woff') format('woff'), url('./assets/fonts/Montserrat-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

img[alt] {
    font-size: 12px;
}

.text-blue {
    color: #11D6B9;
}

.text-yellow {
    color: #fdbc64;
}

.text-purple {
    color: #1554F0;
}

.text-orange {
    color: #f9896b;
}

h1 {}

h2 {
    font-size: 36px;
    line-height: 50px;
    margin: 0;
    font-weight: 900;
    font-family: 'Neon';

}

h3 {
    font-size: 26px;
    font-weight: 900;
    line-height: 38px;
    margin: 0;
    font-family: 'Neon';

}

h4 {
    font-size: 22px;
    font-weight: 900;
    line-height: 32px;
    margin: 0;
    font-family: 'Neon';

}

h5 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
    margin: 0;
    font-family: 'Neon';

}

p {
    margin-bottom: 0;
}

a {
    text-decoration: none;
    color: #fff;
}

a:hover {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

a:active,
a:focus {
    color: inherit;
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

body {
    font-family: "Montserrat";
    font-size: 16px;
    color: #F2F2F2;
    background-color: #1A1A1A;
}

body .wrapper {
    overflow-x: hidden;
    position: relative;
    min-height: 100vh;
    z-index: 2;
}

.wrapper::before {
    content: '';
    background: url('./assets/images/home-overlay.png') repeat;
    background-position: top center;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: -1;
}

.custom-padding {
    padding: 0 60px;
}

.btn-gradient-pink {
    background: transparent linear-gradient(72deg, #fd24d8 0%, #fed130 100%) 0% 0% no-repeat padding-box;
    border-radius: 0;
    min-width: 220px;
    font-weight: 500 !important;
    margin-left: 50px;
}

.btn-gradient-blue {
    background-color: #2cfafe;
    clip-path: polygon(3% 3%, 100% 0, 100% 100%, 0 92%);
    border-radius: 17px 8px 8px 14px;
    /* border-radius: 4px; */
    font-weight: 600;
    padding: 14px 22px;
    font-family: 'Neon';
    font-size: 14px;
    color: #000;
    border: none;
}

.btn-dark {
    background: rgba(132, 233, 244, 0.1);
    color: #84e9f4;
}

.btn-dark:hover {
    background: rgba(132, 233, 244, 0.5);
}

.header .custom-padding {
    padding-right: 0;
}

.header .navbar-brand {
    color: rgb(255 255 255);
    font-weight: 500;
    font-size: 22px;
    display: flex;
    align-items: center;
}

.header .navbar-brand:hover {
    color: #fff;
}

.header .navbar-brand .image {
    width: 50px;
    height: 50px;
    margin-right: 5px;
}

.header .navbar-brand .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header .navbar-toggler-icon {
    filter: invert(1);
}

.header .navbar-nav .nav-link {
    color: rgb(255 255 255);
    padding: 30px 30px;
    font-weight: 500;
}

.header .navbar-nav .nav-link.main-btn-style {
    color: #000;
}

.header .navbar-nav .nav-link.main-btn-style:hover {
    color: #fff !important;
    background-color: #1554F0;
    border-color: #1554F0;
}

.header .navbar-nav .nav-link:active {
    text-decoration: none;
}

.header .navbar-nav .nav-link:focus {
    color: rgb(255 255 255);
    text-decoration: none;
}

.sidebar .section-dark-bg .logo-link:active,
.sidebar .section-dark-bg .logo-link:focus {
    text-decoration: none;
    color: #fff;
}

.section-dark-bg .logo-link:focus-visible {
    outline: none;
}

.main-btn-style {
    background: #2cfafe;
    color: #000;
    font-size: 17px;
    font-weight: 600;
    padding: 10px 20px;
    border: 1px solid #2cfafe;
    border-radius: 6px;
    transition: all 0.3s;
}

.main-btn-style:hover,
.main-btn-style:active {
    background: transparent;
    color: #2cfafe;
    border-color: #2cfafe;
}

.main-btn-style:active {
    background: #2cfafe !important;
    color: #000 !important;
    border-color: #2cfafe !important;
}

/* .header .navbar-nav .nav-link:hover {
    color: #fff;
} */

.breadcrum {
    background: #2a2d3a6b;
    margin-bottom: 30px;
}

.breadcrum ul {
    display: flex;
    padding: 12px 0;
    color: #ffffff80;
}

.breadcrum ul li a {
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
}

.main-leaderboard {
    padding: 0 15px;
}

.main-leaderboard .not-found {
    background-color: #323239;
    min-height: 400px;
}

.main-leaderboard .table-wrapper {
    max-width: 1560px;
    margin: 50px auto;
    background: url("./assets/images/table-middle.png");
    background-size: 100%;
    padding: 100px 40px 60px;
    position: relative;
    background-repeat: repeat;
    background-position: top;
    z-index: 9;
    min-height: 550px;
}

.main-leaderboard .table-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    background: url("./assets/images/table-top.png");
    height: 100px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top;
    background-color: #1a1a1a;
}

.main-leaderboard .table-wrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    background: url("./assets/images/table-bottom.png");
    height: 40px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 100%;
    background-color: #1a1a1a;
}

.main-leaderboard .table-wrapper .table {
    color: #fff;
    border-spacing: 0 20px;
    border-collapse: separate;
    padding-right: 10px;
}

.main-leaderboard .table-wrapper .loader {
    min-height: 60vh;
    align-items: center;
}

.main-leaderboard .table-wrapper .table td,
.main-leaderboard .table-wrapper .table th {
    border: none;
    box-shadow: none;
    text-align: left;
    font-weight: 500;
    font-size: 15px;
}

.main-leaderboard .table-wrapper .table .user-details,
.main-leaderboard .table-wrapper .table .badges ul {
    justify-content: flex-start;
    /* min-width: 180px;     */
    align-items: center;
}

.badges-image {
    /* max-width: 150px; */
}

.special-notes-modal .badges-image {
    width: 250px;
    height: 250px;
}

.special-notes-modal .badges-image img {
    height: 100%;
    object-fit: contain;
}

.main-leaderboard .table-wrapper .table tbody {
    border: none;
}

.main-leaderboard .table-wrapper .table td {
    background: transparent;
    vertical-align: middle;
    font-weight: 500;
}

.main-leaderboard .table-wrapper .table tbody tr {
    clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%);
    background: linear-gradient(to right, #1f1f27, #1f1f27);
}

.main-leaderboard .table-wrapper .table tbody tr.user-record {
    background: #2cfafe;
}

.main-leaderboard .table-wrapper .table tbody tr.user-record td {
    color: #000;
}

.main-leaderboard .table-wrapper .table tr.first {
    clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%);
    background: linear-gradient(to right, #fd3448, #fd25e6);
}

.main-leaderboard .table-wrapper .table tr.second {
    clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%);
    background: linear-gradient(to right, #fd2cd0, #feca36);
}

.main-leaderboard .table-wrapper .table tr.third {
    clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%);
    background: linear-gradient(to right, #7f3afb, #2ce3fd);
}

.main-leaderboard .table-wrapper .table tr.forth {
    /* clip-path:  polygon(0% 0%, 100% 0%, 99.5% 100%, 0.5% 100%); */
    /* background: #323239; */
}

.main-leaderboard .table-responsive {
    overflow: auto;
    height: 540px;
}

.main-leaderboard .table-responsive tbody {
    width: 100%;
    position: relative;
}

.main-leaderboard .table-responsive .sc-gsnTZi {
    position: absolute;
    left: 0;
    right: 0;
}

.leader-board-header thead {
    /* position: sticky; */
    top: 0px;
    margin: 0 0 0 0;
    z-index: 11111;
    border-radius: 3px;
}

.leader-board-header th {
    padding: 15px 8px;
}

.main-profile {
    margin-bottom: 20px;
}

.user-details {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-details .image {
    width: 42px;
    background: #f2f2f2;
    height: 42px;
    border-radius: 4px;
    overflow: hidden;
    /* padding: 6px 2px 0 2px; */
}

.user-details .image img {
    height: 100%;
    object-fit: cover;
}

.user-details .user-info {
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0;
    flex: 1;
}

.user-details .user-info h5 {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    display: flex;
}

.user-details .user-info h5 strong {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-width: 190px;
    word-break: break-word;
}

.user-details .user-info span {
    font-size: 12px;
}

.badges ul {
    display: flex;
    justify-content: space-evenly;
}

.badges-description {
    border: 1px solid;
    transition: all 0.3s;
    border-image: linear-gradient(45deg, #1e1e24 0%, #1e1e24 100%) 1;
    /* border-image: linear-gradient(45deg, purple, orange) 1;; */

}

.badges-description:hover {
    border-image: linear-gradient(45deg, #7659f8 0%, #2be7fd 100%) 1;
    transform: scale(1.04);
    /* border-radius: 5px; */
}

.badges ul li {
    background: #00000030;
    width: 35px;
    height: 35px;
    margin-right: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.badges ul li img {
    width: 18px;
}

.main-leaderboard .table-wrapper h2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    text-align: center;
    color: #fff;
}

.section-dark-bg {
    background: #2a2d3a6b;
    box-shadow: 0px 8px 21px #00000045;
    border-radius: 16px;
    padding: 25px;
    /* height: 100%; */
    min-height: 100%;
}

.section-dark-bg .heading,
.heading,
.section.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    margin-bottom: 22px;
    flex-wrap: wrap;
}

.section-dark-bg .heading .view-all,
.section.heading .view-all,
.heading .view-all {
    text-decoration: none;
    font-size: 14px;
    color: rgb(255, 255, 255, 0.5);
    font-weight: 500;
    text-transform: uppercase;
}

.gift-card .heading .view-all.icon {
    width: 40px;
    height: 40px;
    background-color: #21FAFF26;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    transition: all 0.3s;
}

.gift-card .heading .view-all.icon:hover {
    background-color: #21FAFF99;
    color: #fff;
}

.gift-card .heading .view-all.icon:hover svg {
    color: #000;
}

.gift-card .heading .view-all.icon svg {
    font-size: 16px;
    color: #21FAFF;
}

.badges-earned .heading .view-all {
    color: rgb(255, 255, 255, 1);
}

.section-dark-bg .heading .view-all:hover,
.section.heading .view-all:hover {
    color: #fff;
}

/* edit-avatr-modal */
.d-edit-image .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #343a40;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
}

.image.d-edit-image:hover .overlay {
    height: 100%;
}

.hove-edit {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.hove-edit button {
    padding: 7px 14px;
    border-radius: 8px 4px 4px 6px;
    font-size: 13px;
    line-height: 18px;
}

#edit-avatr-modal .modal-content {
    background-color: #1a1a1a !important;
    box-shadow: 0px 8px 21px #00000045;
    border: 1px solid #1554F0;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(79deg, #1554F0 0%, #11D6B9 100%);
    border-radius: 0;
}

#edit-avatr-modal .modal-header {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1) !important;
    background: transparent linear-gradient(79deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border-radius: 0;
    margin-bottom: 10px;
}

#edit-avatr-modal .modal-header .btn-close {
    filter: invert(1);
}

#edit-avatr-modal .title {
    /* color: #11D6B9; */
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
}

#edit-avatr-modal .modal-footer {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
}

/* .d-edit-image img{background-color: red;} */
img.select-img {
    border: 2px solid transparent;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

img.select-img:hover {
    cursor: pointer;
    border: 2px solid #0d6efd;
    border-radius: 18px;
    transform: scale(1.1);
}

.user-info .user-about {
    display: flex;
    margin-bottom: 20px;
}

.user-info .user-about .image {
    width: 100px;
    height: 100px;
    margin-right: 30px;
    position: relative;
    /* background: transparent linear-gradient(0deg, #FD24D8 0%, #FED130 100%) 0% 0% no-repeat padding-box;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  padding: 7px; */
}

.user-info .user-about .image img {

    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* object-fit: contain; */
    clip-path: circle;
    left: 50%;
    transform: translate(-50%);
}

.user-stats ul {
    display: flex;
    flex-wrap: wrap;
}

.user-stats ul li {
    width: 50%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.user-stats ul li h4 {
    font-size: 18px;
    color: #111115;
}

.user-stats ul li h4 sup {
    top: -10px;
    right: 6px;
    font-weight: 600;
    font-size: 11px;
}

.user-stats ul li span {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
    color: #111115;
    text-transform: uppercase;
}

.user-stats ul li:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.user-stats ul li:nth-child(2) {
    padding-left: 20px;
}

.user-stats ul li:nth-child(3) {
    border-bottom: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.user-stats ul li:last-child {
    border-bottom: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 20px;
}

.badges-earned {
    margin-top: 20px;
    margin-bottom: 20px;
}

.left-sidebar .badges-earned {
    margin: 0;
    height: 100%;
}

.avatars-badges table tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: #3c3c3c;
}

.avatars-badges table thead tr th {
    padding: 15px;
}

.avatars-badges table thead {
    background-color: #000;
}

.avatars-badges table tbody tr {
    background-color: #323239;
}

.badges-list ul {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.badges-list ul li {
    margin-right: 10px;
    width: 21%;
    /* height: 30px; */
    /* background: rgba(47, 47, 54, 1); */
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 5px; */
    margin-bottom: 10px;
    border-radius: 50%;
}

.badges-list ul li canvas {
    width: 40px;
}

.badges-list ul li img {
    width: 100%;
    height: 108px;
    object-fit: contain;
}

.shine {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}

.shine::before {
    content: '';
    position: absolute;
    top: 0;
    left: -38px;
    width: 25px;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    transform: skewX(-30deg);
    transition: 1s;
}

.shine:hover::before {
    left: 100px !important;
}



/* .badges-list ul .shine {
	position: relative;
	overflow: hidden;
}

.badges-list ul .shine::before {
    background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  ) !important;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: skewX(-27deg);
    width: 50%;
    z-index: 2;
}

.badges-list ul .shine::before,
.badges-list ul .shine:focus::before {
    animation: shine 2s infinite;
	}   
    @keyframes shine {
		100% {
			left: 125%;
		}
	} */

.left-sidebar .about-sec .section-dark-bg,
.left-sidebar .collection-sec .section-dark-bg,
.left-sidebar .activities {
    min-height: 405px;
    height: 100%;
}

.left-sidebar .user-info {
    height: 100%;
}

.left-sidebar .activities {
    margin: 0;
    background: #323239;
    padding: 20px;
    border-radius: 16px;
}

.left-sidebar .activities ul li {
    border-color: #525252;
    padding: 16px 0;
}

.about-sec p {
    word-wrap: break-word;
}

.stats-sec {
    margin-bottom: 30px;
    min-height: 100%;
}

.left-sidebar .stats-sec {
    margin-bottom: 0;
}

.left-sidebar .stats-sec .section-dark-bg,
.left-sidebar .stats-sec {
    height: 100%;
}

.stats-sec .chart {
    height: 340px;
}

.stats-sec .heading ul {
    display: flex;
}

.stats-sec .heading ul li {
    margin-right: 10px;
}

.stats-sec .heading ul li:last-child {
    margin-right: 0;
}

.stats-sec .heading ul li a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    opacity: 0.3;
}

.stats-sec .heading ul li a.active {
    opacity: 1;
    color: #2cfafe;
    background: none;
}

.collectibles {
    /* height: 100%; */
}

.collectibles-list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.collectibles-list ul li {
    width: 30%;
    margin-bottom: 20px;
    height: 110px;
}

/* .collectibles-list ul li:not(:last-child) {
    margin-right: 10px;
} */

.collectibles-list ul li .image {
    width: 100%;
    height: 100%;
}

.collectibles-list ul li .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 14px;
}

.nft-list ul {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.nft-list .name a:active,
.nft-list .name a:focus {
    color: #fff;
    text-decoration: none;
}

.nft-list ul li:first-child {
    /* height: 200px; */
    /* width: 36%; */
}

.nft-list .name a:active,
.nft-list .name a:focus {
    color: #fff;
    text-decoration: none;
}

.nfts-gallery .nft-list ul li {
    width: 29%;
    margin: 0 10px 20px 0;
}

.nfts-gallery .nft-list ul li:first-child,
.nfts-gallery .nft-list ul li:nth-child(2) {
    width: 46%;
}

.nfts-gallery .nft-list ul li .image {
    width: 100%;
    height: 200px;
    position: relative;
}

.nfts-gallery .nft-list ul li .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
}

.collection-list ul {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
}

.collection-list ul li {
    width: 48%;
    /* margin-bottom: 20px; */
}

.collection-list ul li .collection-wrap {
    position: relative;
}

.collection-list ul li .collection-text h2 {
    font-size: 14px;
    font-family: "Neon";
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 36px;
    word-break: break-word;
}

.collection-list ul li .collection-text span {
    font-size: 12px;
    opacity: 0.5;
    color: #fff;
}

.collection-list ul li .image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #ffffff9c;
    position: absolute;
    bottom: -32px;
    left: 32px;
}

.collection-list ul li .image img,
.collection-list ul li .banner-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 14px;
}

.collection-list ul li .banner-img {
    width: 200px;
    height: 200px;
    max-width: 100%;
    margin-bottom: 60px;
}

.sidebar-links li a {
    width: 100% !important;
}

.activities {
    margin-bottom: 20px;
    margin-top: 20px;
}

.activities ul {
    background: #323239;
    border-radius: 16px;
}

.activities ul li {
    padding: 18px 18px;
    border-bottom: 1px solid #121212;
    color: #fff;
}

.activities ul li:last-child {
    border-bottom: 0;
}
.activities ul li .icon{margin-right: 12px; padding-top: 3px; font-size: 15px;}
.activities ul li .icon svg{font-size: 20px;} 
.activities ul li .achieved-icon{color: #2cfafe;}
.activities ul li .pending-icon{color: #EEFF00;}
.activities ul li .rejected-icon{color: #FF0000;}
.activities ul li .points{font-weight: 900;margin-left: 12px; padding-top: 3px;}
.activities ul li .achieved-points{color: #2cfafe;}
.activities ul li p {font-size: 14px; flex: 1;}

.all-activities{max-height: 90vh; overflow-y: auto; padding-right: 30px;}
.all-activities .btn-group{background-color: #323239; border-radius: 30px; margin-bottom: 60px;}
.all-activities .btn-group button{padding: 16px 36px; border-radius: 30px; font-size: 18px; color: #CACACC; background-color: transparent; border: none; transition: all 0.4s;}
.all-activities .btn-group button:hover, .all-activities .btn-group button.active{background-color: #2cfafe; color: #000;}
.all-activities ul li{clip-path: polygon(2% 0%, 98% 0%, 100% 100%, 0% 100%); background: linear-gradient(to right, #1f1f27, #1f1f27); padding: 13px 35px; margin-bottom: 15px;}
.all-activities ul li p{font-size: 18px; flex: 1;}
.all-activities ul li .icon{margin-right: 20px; font-size: 24px;}
.all-activities ul li .icon svg{font-size: 30px;} 
.all-activities ul li .achieved-icon{color: #2cfafe;}
.all-activities ul li .rejected-icon{color: #FF0000;}
.all-activities ul li .pending-icon{color: #EEFF00;}
.all-activities ul li .points{font-weight: 900; margin-left: 20px; padding-top: 4px; font-size: 20px;}
.all-activities ul li .achieved-points{color: #2cfafe;}

.recent-offers ul li {
    border-bottom: 1px solid #ffffff29;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.recent-offers ul li:last-child {
    padding: 0;
    border: none;
    margin: 0;
}

.about-details h5 {
    font-size: 19px;
    line-height: 21px;
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 500;
    color: #3e3e3e;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 68px;
    word-break: break-word;
    margin: 0 auto 8px;
}

/* Dashboard */

.main-dashboard .sidebar {
    width: 100%;
    max-width: 380px;
    height: 100vh;
    position: fixed;
    z-index: 1;
    /* overflow-y: auto; */
    padding-right: 0;
}

.main-dashboard .sidebar::-webkit-scrollbar {
    width: 5px;
}

.main-dashboard .right-sidebar {
    max-width: 100%;
}

.sidebar .logo-link:focus-visible {
    outline: 0;
}

.sidebar .logo {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 25px 0 0;
}

.sidebar .logo .image {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.sidebar .logo .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sidebar .logo .name span {
    font-size: 14px;
    opacity: 0.5;
}

.sidebar-links li {
    /* margin: 0 15px; */
    margin-bottom: 5px;
}

.sidebar-links li.static {
    margin-top: 0;
    margin-bottom: 0;
}

.sidebar-links li a {
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
    display: flex;
    align-items: center;
    padding: 15px 30px;
    font-family: 'Neon';
    font-weight: 700;
    color: #fff;
    overflow: hidden;
    clip-path: polygon(1% 5%, 100% 0, 100% 100%, 0 95%);
    border-radius: 18px 9px 9px 16px;
}

.sidebar-links li a.active {
    background: #21FAFF;
    color: #111115;
    /* transform: matrix(0.9, 0.01, -0.1, 0.9, 1, 4); */
    /* border-radius: 10px; */
    clip-path: polygon(1% 5%, 100% 0, 100% 100%, 0 95%);
    /* border-radius: 18px 9px 9px 16px; */
}

.sidebar-links li a.active span:nth-child(2) {
    /* transform: matrix(1.2, -0.02, 0.1, 1, 0, 2); */
}

.sidebar-links li a:hover,
.sidebar-links li.active a {
    opacity: 1;
    background: #21FAFF !important;
    color: #000;
    /* transform: matrix(0.9, 0.01, -0.1, 0.9, 1, 4); */
    /* border-radius: 10px; */
    /* border-radius: 18px 9px 9px 16px; */
}

.sidebar-links li a:hover span:nth-child(2) {
    /* transform: matrix(1.2, -0.02, 0.1, 1, 0, 2); */
}

.sidebar-links .nav-link {
    color: rgba(255, 255, 255, .7) !important;
}

.sidebar-links li a .icon {
    margin-right: 30px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-links li a .icon svg {
    width: 20px;
    height: 20px;
}

.sidebar-links li a.active .icon svg,
.sidebar-links li a:hover .icon svg {
    fill: #000;
    stroke: #000;
}

.sidebar-links li a.active .icon svg path,
.sidebar-links li a:hover .icon svg path,
.sidebar-links li a.active .icon svg circle,
.sidebar-links li a:hover .icon svg circle,
.sidebar-links li a.active .icon svg rect,
.sidebar-links li a:hover .icon svg rect,
.sidebar-links li a.active .icon svg line,
.sidebar-links li a:hover .icon svg line {
    stroke: #000;
}

.main-content-area {
    flex: 1 1;
    padding-bottom: 30px;
    max-width: calc(100% - 380px);
    width: 100%;
    margin-left: auto;
}

.points-list {
    margin-bottom: 40px;
}

.points-list a {
    position: relative;
}

.points-list h3 {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 8px;
    text-align: center;
    text-shadow: 2px 2px #646464;
}

.points-list h5 {
    font-weight: 500;
    /* opacity: 0.5; */
    font-size: 14px;
    text-align: center;
}

.user-info {
    margin-bottom: 30px;
    position: relative;
}

.left-sidebar .user-info {
    margin-bottom: 0;
}

/* .left-sidebar .user-info{margin-bottom: 0;} */
.user-info .user-about {
    flex-direction: column;
    align-items: center;
}

.user-info .user-edit {
    background-color: #111115;
    font-size: 16px;
    width: 45px;
    height: 45px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2cfafe;
    position: absolute;
    top: -1px;
    right: -1px;
    border-radius: 0 0 0 16px;
    opacity: 0;
    transition: all 0.3s;
}

.user-info .user-about .image {
    padding: 7px;
    margin-right: 0;
    margin-bottom: 14px;
    background: url("./assets/images/user-bg-frame.svg");
    /* padding: 10px 10px 10px; */
    background-size: cover;
}

.user-info .user-about .image img {
    background: #44444E;
    border-radius: 2px 2px 3px 1px;
}

.image.d-edit-image {
    position: relative;
    width: 100%;
}

.custom-avatar-img+img>img {
    clip-path: none;
    background: none;
    border-radius: 12px;
    width: 56px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.about-details p {
    font-family: "Montserrat";
    /* opacity: 0.5; */
    font-size: 14px;
    text-align: center;
    color: #6374A0;
    margin-bottom: 12px;
    font-weight: 600;
}

.my-nfts {
    margin-bottom: 30px;
}

/* .my-nfts .nft-list {
    display: flex;
    align-items: center;
} */

/* .my-nfts .nft-list .nft-slider {
    width: 30%;
    padding-left: 20px;
} */

.nft-list .item {
    padding: 0 12px;
}

.nft-list .item .image {
    height: 290px;
}

.nft-list img,
.nfts-gallery ul li .nft-img-link img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.nfts-gallery ul li .nft-img-link img {
    object-fit: fill;
}

.my-nfts .nft-list ul li {
    width: 50%;
    padding: 0 10px;
}

.nft-list ul li .name {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    display: block;
}

.nft-list .slick-slide {
    display: flex;
}

.nft-image .sale {
    position: absolute;
    right: -10px;
    top: 0;
    background: #11111540;
    border-radius: 16px;
    font-size: 14px;
    font-weight: bold;
    padding: 7px 26px;
}

.nft-image .timer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #1e1e24;
    border-radius: 16px;
    color: #1e1e24;
    padding: 6px 14px;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    bottom: -17px;
    right: 0;
    left: 0;
    text-align: center;
    width: 90%;
    margin: 0 auto;
}

.slick-slide .nft-image img {
    /* display: unset; */
    /* width: 340px !important;
    height: 340px; */
}

.price label {
    font-size: 14px;
    opacity: 0.5;
    font-weight: 500;
}

.price .currency {
    font-weight: 500;
    display: flex;
    align-items: center;
}

.price .currency .image {
    width: 15px;
    height: 25px;
    margin-right: 5px;
}

.daily-rewards {
    margin-top: 30px;
}

.daily-rewards li {
    border-bottom: 1px solid #4b4b50;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.daily-rewards li span {
    font-weight: 500;
}

.daily-rewards li a {
    font-weight: 500;
}

.daily-rewards li.claim a {
    color: #84e9f4;
}

.daily-rewards li.claimed a {
    color: #f9896b;
}

.daily-rewards li.locked a {
    opacity: 0.5;
}

.daily-rewards li.locked a {
    color: #fff;
}

.daily-rewards li:last-child {
    margin: 0;
    border: none;
    padding: 0;
}

.game-purchase {
    margin-top: 40px;
}

.game-list ul li {
    border-radius: 16px;
    padding: 30px;
    position: relative;
    padding-bottom: 60px;
}

.game-list ul li.orange {
    background: transparent linear-gradient(30deg, #fdbc64 0%, #f9896b 100%) 0% 0% no-repeat padding-box;
}

.game-list ul li.pink {
    background: transparent linear-gradient(30deg, #fc3546 0%, #fc26e7 100%) 0% 0% no-repeat padding-box;
}

.game-list ul li.blue {
    background: transparent linear-gradient(30deg, #84e9f4 0%, #4f46ba 100%) 0% 0% no-repeat padding-box;
}

.game-list ul li h3 {
    height: 100px;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 30px;
}

.game-list ul li a {
    margin-top: 20px;
    display: block;
    text-align: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.game-list ul li.treasury-box {
    margin-top: 80px;
    padding-top: 100px;
    /* background: #2a2d3a6b; */
    height: 250px;
}

.game-list ul li.treasury-box .image {
    position: absolute;
    top: -100px;
    width: 185px;
    height: 185px;
    left: 0;
    right: 0;
    margin: auto;
}

.game-list ul li.treasury-box p {
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
    font-weight: 500;
}

.game-list ul li.treasury-box .btn-dark {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    font-family: "Neon";
    padding: 15px;
    background: #1E1E24;
    border: 1px solid #1E1E24;
    text-transform: uppercase;
    text-decoration: none;
    position: unset;
    display: block;
    width: 100%;
    margin-top: 20px;
    color: #ffffff;
    border-radius: 8px;
    transition: all 0.3s;
}

.game-list ul li.treasury-box .btn-dark:hover {
    background: transparent linear-gradient(84deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border-color: transparent;
    /* box-shadow: 0px 8px 21px #00000045; */
    /* color: #fff; */
}

.task-rewards li {
    display: flex;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    background: #32323940;
    margin-bottom: 13px;
}

.task-rewards li:hover {
    transform: scale(1.03);
    transition: .5s all ease-out;
}

.task-rewards li .task {
    width: 70%;
}

.task-rewards li .rewards {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-rewards .head p {
    font-size: 15px;
    font-weight: 900;
    /* opacity: 0.5; */
    font-family: 'Neon';
}

.task-rewards .head {
    background: transparent;
    margin: 0;
}

.task-rewards .head:hover {
    transform: none;
}

.task-rewards li .rewards .link {
    color: #fff;
    opacity: 0.5;
}

.task-rewards li .rewards .link:hover {
    opacity: 1;
}

.task-rewards .view-all {
    text-align: center;
    font-size: 14px;
}

.task-rewards .view-all a {
    /* color: #4f46ba; */
    font-weight: 500;
}

.game-list ul li .bg-image {
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    height: 100px;
}

.game-list ul li .bg-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.promotion-wrapper {
    border-radius: 10px;
    text-align: center;
    /* padding: 0 12px; */
}

.promotion-img {
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.promotion-img p {
    margin-bottom: 20px;
    max-height: 110px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* position: absolute;
    top: 15px;
    left:15px;
    padding: 5px 10px;
    padding-right:70px;
    text-align: left;
    font-size: 12px;
    width: 50%;
    height: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.promotion-description h3 {
    font-size: 18px;
    line-height: 27px;
}

.promotion-wrapper .promotion-img .promotion-description a {
    /* padding: 10px 20px; */
    /* position: absolute;
    bottom: 80px;
    left:15px;
    margin: 5px 10px; */
}

.promotion-wrapper .promotion-img .promotion-description a:hover {
    color: #000;
}

.promotion-wrapper img {
    width: 100%;
    margin: 0 auto;
    height: 305px;
    object-fit: cover;
}

.set-slider {
    justify-content: space-between !important
}

.nft-slider .slick-track .slick-slide {
    height: 100%;
    /* width: fit-content !important; */
    /* display: block; */
}

.nft-slider .slick-track {
    justify-content: flex-start !important;
}

.card-wrapper {
    /* background: transparent linear-gradient(79deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box; */
    background: #f7fd29;
    border-radius: 10px;
    text-align: center;
    padding: 30px;
    /* box-shadow: 0 42px 0px -22px #ffffff24; */
}

.gift-card .section {
    margin-bottom: 20px;
}

.card-wrapper {
    position: relative;
}

.gift-card .card-wrapper {
    min-height: 260px;
    height: 100%;
}

/* .gift-card .card-wrapper::-webkit-scrollbar{width: 6px;} */
.card-wrapper p {
    font-size: 12px;
    color: #0581ed;
    font-weight: 500;
    margin-top: 20px;
}

.card-wrapper:after {
    content: "";
    width: 93%;
    height: 20px;
    position: absolute;
    background: #ffffff;
    opacity: 0.1;
    top: calc(100% + -3px);
    border-radius: 5px;
    transform: translateX(-50%);
}

.card-wrapper .vibes {
    font-family: "Great Vibes";
    color: #0581ed;
    font-weight: 400;
    z-index: 9;
    position: relative;
    display: block;
    text-transform: capitalize;
    font-size: 30px;
}

.card-wrapper h2 {
    line-height: 15px;
    font-size: 40px;
    font-family: 'Montserrat';
}

.card-wrapper .value-wrapper {
    border: 2px solid #0581ed;
    border-radius: 7px;
    margin: 0 auto;
    margin-top: 20px;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-wrapper .value-wrapper label {
    width: 50%;
    font-size: 14px;
    text-align: right;
    padding: 0 5px;
    line-height: 14px;
    font-weight: 200;
    color: #0581ed;
}

.card-wrapper .value-wrapper span {
    width: 50%;
    display: block;
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    padding: 0 5px;
    color: #0581ed;
}

.about-details h5 span {
    background: #2f2f36;
    border-radius: 4px;
    padding: 5px;
    font-size: 10px;
    font-weight: 500;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    margin-left: 5px;
}

.about-details h5 span img {
    width: 13px;
    height: 13px !important;
    margin-right: 3px;
}

.toggle-button {
    display: none;
    background: #2cfafe;
    flex-direction: column;
    border: 1px solid #2cfafe;
    padding: 00;
    border-radius: 4px;
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    align-items: center;
    margin: auto 0px;
    width: 45px;
    height: 38px;
    justify-content: center;
}

.toggle-button span {
    display: block;
    width: 23px;
    height: 2px;
    background: #000;
    margin-bottom: 5px;
}

.toggle-button span:last-child {
    margin: 0;
}

.custom-modal .modal-content {
    background: #1e1e24;
    border: 1px solid #0077b5;
    overflow: hidden;
}

.custom-modal .modal-content .modal-header {
    border: none;
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1) !important;
    background: transparent linear-gradient(79deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border-radius: 0;
}

.custom-modal .modal-content .modal-header .modal-title {
    font-weight: 500;
    font-size: 30px;
    /* font-family: 'Neon'; */
}

.single-badge-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* margin-bottom: 20px;
    width: 125px; */
}

.edit-avatar-style {
    width: 110px;
    margin-bottom: 10px;
    height: 110px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.edit-avatar-style.active {
    background: transparent;
    box-shadow: 0 0 6px #2cfafe;
    padding: 2px;
}

.custom-modal-content p {
    margin-bottom: 20px;
}

.custom-modal-content .form-check-input[type=checkbox] {
    margin: 0px 10px;
    width: 75px;
    height: 75px;
    background-color: transparent;
    border: 2px solid #1554f0;
    cursor: pointer !important;
    border-radius: 7px;
}

.custom-modal-content .form-check-input[type=checkbox]:focus {
    box-shadow: none;
}

.form-check-input:checked[type=checkbox] {
    background-image: url('./assets/images/logo.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.custom-modal-content label {
    padding-top: 17px;
    font-size: 13px;
}

.custom-modal-content .form-check {
    display: inline-grid;
    padding-left: 0px;
    margin-bottom: 0.125rem;
}

.custom-modal .modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.custom-modal .modal-footer .btn-primary,
.create-gift-form .btn-primary,
.login-wrapper .btn-primary {
    background: transparent linear-gradient(84deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: none;
    outline: none;
    font-weight: 500;
}

.connect-wallet .modal-content .modal-body {
    text-align: center;
}

.connect-wallet .modal-content .modal-body .image {
    width: 80px;
    height: 80px;
    margin: 20px auto 0;
}

.connect-wallet .modal-content .connect-btn {
    border: none;
    height: 45px;
    margin: 0;
    outline: none;
    box-shadow: none;
    background-color: #2cfafe;
    border: 1px solid #2cfafe;
    transition: all .3s;
    color: #000;
    position: absolute;
    right: -4px;
    top: -4px;
    border: none;
    height: 28px;
    width: 28px;
    margin: 0;
    outline: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    font-size: 15px;
}

.fb-model .select-content .fb-insta-select,
.insta-model .select-content .fb-insta-select {
    margin-bottom: 80px;
}

.fb-model .modal-title {
    font-size: 22px;
    font-weight: 700;
    padding-right: 40px;
}

.connect-wallet .modal-content .modal-body h4 {
    margin-bottom: 30px;
    font-family: 'Montserrat';
    font-weight: 500;
}

.edit-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    background: transparent linear-gradient(72deg, #fd24d8 0%, #fed130 100%) 0% 0% no-repeat padding-box;
    padding: 8px;
}

.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login-box {
    max-width: 500px;
    margin: 0 auto;
    background: #1e1e24;
    padding: 20px;
    width: 100%;
}

.login-box h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
}

.login-box .btn-action {
    text-align: right;
}

.form-modal .form-control,
.login-box .form-control {
    background: #000;
    border: none;
    color: #fff;
    outline: none;
    box-shadow: none;
    height: 45px;
}

.form-modal input#formFile,
.login-wrapper input#formFile {
    height: auto;
}

.form-validate {
    color: #ff0000;
}

.notifications .rdt_TableRow:hover {
    /* background-color: #eeeeeed6;
    outline-width: 0;
    border-bottom: none; */
}

.notifications .rdt_TableRow:hover a {
    color: #000 !important;
}

.notifications .rdt_TableRow p.task {
    text-transform: capitalize;
}

.notifications .rdt_Table .rdt_TableBody .rdt_TableRow .link,
.notifications .rdt_TableRow .link.view-link {
    padding-left: 22px;
    position: relative;
    font-family: inherit;
}

.notifications .rdt_Table .rdt_TableBody .rdt_TableRow .link::before,
.notifications .rdt_TableRow .link.view-link::before {
    font-family: "FontAwesome";
    content: "\f06e";
    position: absolute;
    top: 0px;
    left: 0;
    width: 20px;
    height: 20px;
    font-family: 15px;
}

/* .rdt_TableRow:hover a {
    color: #000;
} */
.form-modal .form-control::placeholder {
    color: #fff;
}

.form-modal .form-control {
    color: #fff;
    outline: none;
    box-shadow: none;
    height: 55px;
    border-radius: 10px;
    padding: 8px 16px;
    margin-top: 10px;
    background: #1B1A21 !important;
    border: 2px solid #323239 !important;
}

.form-modal textarea.form-control {
    height: auto;
    resize: none;
}

.create-gift-form {
    max-width: 500px;
    margin: 0 auto;
}

.create-gift-form h3 {
    margin-bottom: 30px;
    text-align: center;
}

.create-gift-form .form-control {
    background: #000;
    border: none;
    color: #fff;
    outline: none;
    box-shadow: none;
    height: 45px;
}

.giftcard-table {
    height: 100%;
}

.eA-dBJK:hover a {
    color: #000 !important;
}

.giftcard-table h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #84e9f4;
}

.form-check label {
    font-size: 12px;
    padding-top: 8px;
}

.badges-images li {
    margin: 0px 10px;
}

.badges-images {
    flex-wrap: wrap;
}

/* daily claim start */
.tresure-week .disable {
    pointer-events: none;
    background: #43434E;
    color: #ffffff99;
}

.tresure-week .claimed {
    background: transparent linear-gradient(44deg, #03a3a6 12%, #00216e 100%) 0% 0% no-repeat padding-box;
}

.tresure-week .claimed p,
.tresure-week .claimed .image,
.tresure-week .claimed .claim-status {
    opacity: 0.6;
}

.tresure-week .able.to-claim .coming-soon,
.tresure-week .able.to-claim .availed,
.tresure-week .able.to-claim .lottie-animations,
.tresure-week .disable .availed,
.tresure-week .disable .lottie-animations,
.tresure-week .disable .expires-in,
.tresure-week .claimed.disable .coming-soon,
.tresure-week .claimed.disable .lottie-animations,
.tresure-week .claimed.disable .expires-in {
    display: none;
}

.tresure-week .claimed.disable .availed,
.tresure-week .claimed.disable .lottie-animations {
    display: block;
}

/* daily claim end */
.tresure-week .claim-status {
    font-family: "Neon";
}

.giftcard-table .table {
    border: 2px solid #fd39c4;
    color: #fff;
}

.giftcard-table .table thead th {
    background: #fd39c4;
    color: #fff;
}

.giftcard-table .table tr td {
    /* border-color: #fff; */
}

.giftcard-table .table> :not(:first-child) {
    border-top: 1px solid #fff;
}

.giftcard-table .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.giftcard-table .title .create {
    cursor: pointer;
    font-weight: 500;
}

.giftcard-table .title .create:hover {
    color: #7e3dfb;
}

.notifications {
    display: flex;
    padding-right: 30px;
}

.notifications .list {
    flex: 1;
    padding-right: 50px;
}

.notifications .head {
    background: #1b1a21c4;
    margin: 0;
    padding: 12px 30px;
}

.notifications li {
    display: flex;
    padding: 15px 30px;
    font-size: 15px;
    font-weight: 500;
    background: #1e1e24;
    /* margin-bottom: 12px; */
}

.notifications .head p {
    font-size: 14px;
    font-weight: 500;
}

.notifications li .task {
    width: 50%;
    padding-right: 10px;
}

.notifications li .rewards {
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.notifications li .action {
    width: 25%;
    display: flex;
    align-items: center;
}

.action .link .icon {
    margin-right: 5px;
}

.form-modal h2,
.form-modal h5 {
    margin-bottom: 15px;
}

.notice {
    background: #f8ef96 0% 0% no-repeat padding-box;
    border: 2px dashed #c9bd48;
    max-width: 400px;
    width: 100%;
    color: #000;
    padding: 30px;
    display: flex;
    flex-direction: column;
    max-height: 50vh;
}

.notice ul {
    list-style: number;
    padding-left: 0;
}

.notice li {
    background: none;
    color: rgba(0, 0, 0, 0.4);
    display: list-item;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid rgba(201, 189, 72, 1);
}

.notice li:last-child {
    border: none;
    padding-bottom: 0;
}

.main-dashboard .main-content-area {
    padding-top: 45px;
    position: relative;
}

.main-content-area .page-title h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.main-dashboard .sidebar .sidebar-wrap {
    padding: 25px 8px;
    /* position: relative; */
    opacity: 1;
    overflow-x: hidden;
}

.main-dashboard .sidebar .sidebar-wrap::before {
    content: "";
    position: absolute;
    background-image: url(./assets/images/sidebar-bg-copy.png);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    left: -15px;
    top: 0px;
    background-size: 100% 100%;
    z-index: -1;
}

.main-dashboard .sidebar .sidebar-wrap .sidebar-links ul {
    height: calc(100vh + -180px);
    overflow-y: hidden;
    padding-right: 30px;
}

.main-dashboard .sidebar .sidebar-wrap .sidebar-links ul::-webkit-scrollbar {
    width: 6px;
    border-radius: 4px;
}

.main-dashboard .sidebar .sidebar-wrap:hover .sidebar-links ul {
    overflow-y: auto;
}

.main-dashboard .sidebar .sidebar-wrap .sidebar-links ul::-webkit-scrollbar-thumb {
    background: #918f8f;
    border-radius: 4px;
}

.main-dashboard .sidebar .sidebar-wrap .sidebar-links ul::-webkit-scrollbar-track {
    background: #48494a;
    border-radius: 4px;
}

/*  content: "";
    position: absolute;
    background-image: url(./assets/images/sidebar-bg.svg);
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    background-size: cover; */

.all-notifications {
    max-width: 1100px;
}

.all-notifications ul li {
    padding: 20px 0;
}

.all-notifications ul li {
    position: relative;
    z-index: 0;
    cursor: pointer;
}

.all-notifications ul li::after {
    content: "";
    position: absolute;
    width: calc(100% + 76px);
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    background-color: transparent;
    border-bottom: 1px solid #474752;
    z-index: -1;
}

.all-notifications ul li.unread::after {
    background-color: #26262C;
}

.all-notifications ul li.unread::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translatey(-50%);
    left: -31px;
    background-color: #2cfafe;
    border-radius: 50%;
}

.all-notifications ul li p {
    font-size: 16px;
    font-weight: 500;
    padding-right: 165px;
}

.all-notifications ul li span {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
    color: #fff;
    opacity: 0.6;
}

.setting-wrapper,
.all-notifications {
    margin: 0 auto;
    background: #323239 0% 0% no-repeat padding-box;
    border: 2px solid #323239;
    border-radius: 20px;
    padding: 25px;
    position: relative;
}

.all-notifications {
    overflow-x: hidden;
    /* overflow-y: hidden; */
    border: none;
    min-height: 400px;
    max-height: 75vh;
}

.notifications-wrap {
    height: 680px;
    overflow: auto;
}

.all-notifications .notifi-time {
    color: #6C6C86;
    font-size: 15px;
}

.notifications-model {
    position: fixed;
    top: 0;
    right: -100%;
    transition: all 0.7s;
    width: 100%;
    height: 100vh;
    z-index: 22;
    background-color: #0000008c;
}

.notifications-model.show {
    right: 0;
}

.notifications-model .notifications-model-content {
    max-width: 1430px;
    margin-left: auto;
    background-color: #323239;
    height: 100%;
    border-radius: 35px 0 0 35px;
    padding: 40px;
    position: relative;
    overflow: auto;
    z-index: 9999999;
}

.notifications-model .notifications-model-content .left-content {
    min-height: 100%;
    background: transparent linear-gradient(84deg, #1554F0 0%, #0C3EB9 100%) 0% 0% no-repeat padding-box;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.notifications-model .notifications-model-content .left-content::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("./assets/images/star.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
    opacity: 0.5;
}

.notifications-model .notifications-model-content .left-content .rewards-box {
    background-image: url("./assets/images/rewards-bg.png");
    width: 500px;
    max-width: 100%;
    min-height: 500px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.notifications-model .notifications-model-content .left-content .avatar-img {
    width: 85px;
    height: 85px;
    overflow: hidden;
    border-radius: 10px;
    position: absolute;
}

.notifications-model .notifications-model-content .left-content .avatar-img.image-1 {
    width: 115px;
    height: 115px;
    left: 53%;
    transform: translateX(-50%);
    bottom: 85px;
}

.notifications-model .notifications-model-content .left-content .avatar-img.image-2 {
    top: 50%;
    transform: translateY(-50%) rotate(-5deg);
    left: 110px;
}

.notifications-model .notifications-model-content .left-content .avatar-img.image-3 {
    top: 47%;
    transform: translateY(-50%) rotate(5deg);
    right: 110px;
}

.notifications-model .notifications-model-content .left-content .avatar-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.notifications-model .notifications-model-content .left-content h3 {
    font-size: 22px;
    text-transform: uppercase;
    font-family: "Montserrat";
    color: #fff;
}

.notifications-model .notifications-model-content .right-content th,
.notifications-model .notifications-model-content .right-content td {
    padding: 20px;
    text-align: left;
}

.notifications-model .notifications-model-content .right-content th {
    font-weight: 400;
}

.notifications-model .notifications-model-content .right-content table {
    border-collapse: separate;
    border-spacing: 0;
}

.notifications-model .notifications-model-content .right-content table tr {
    border: 1px solid #5B5B6A
}

.notifications-model .notifications-model-content .right-content table tr td,
.notifications-model .notifications-model-content .right-content table tr th {
    border: 1px solid #5B5B6A;
    vertical-align: baseline;
}

.notifications-model .notifications-model-content .right-content table tr:first-child th {
    border-radius: 16px 0 0 0;
}

.notifications-model .notifications-model-content .right-content table tr:first-child td {
    border-radius: 0 16px 0 0;
}

.notifications-model .notifications-model-content .right-content table tr:last-child th {
    border-radius: 0 0 0 16px;
}

.notifications-model .notifications-model-content .right-content table tr:last-child td {
    border-radius: 0 0 16px 0;
}

.notifications-model .notifications-model-content .right-content .time {
    color: #fff;
    opacity: 0.6;
    font-size: 18px;
}

.notifications-model .notifications-model-content .right-content h2 {
    font-family: "Montserrat";
    font-weight: 400;
    margin-bottom: 60px;
}

.notifications-model .notifications-model-content .right-content h4 {
    font-family: "Montserrat";
    font-weight: 700;
    margin-bottom: 30px;
    font-size: 18px;
    color: #2cfafe;
}

.notifications-model .notifications-model-content .right-content table tr td span {
    font-size: 16px;
    color: #21FAFF;
    margin-left: 20px;
}

.notifications-model .notifications-model-content .close-model {
    width: 55px;
    height: 55px;
    background: #ffffff30;
    /* border: 1px solid #ffffff30; */
    color: #fff;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 61px;
    left: 61px;
    border-radius: 15px;
    z-index: 3;
}

.notifications-model .notifications-model-content .close-model:active {
    border-color: #ffffff30;
}



.field-wrapper {
    background: #1B1A21;
    position: relative;
    height: 55px;
    padding: 8px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    border: 2px solid #323239;
    border-radius: 10px;
}

.field-wrapper .btn {
    height: 100%;
}

.setting-wrapper .wallet-address strong,
.setting-wrapper .personal-info strong {
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 20px;
    display: block;
}

.setting-wrapper .personal-info strong span:last-child {
    padding-left: 20px;
    font-size: 17px;
}

.setting-wrapper .personal-info {
    margin-top: 30px;
}

.setting-wrapper .personal-info .flex-1 {
    flex: 1;
}

.setting-wrapper .personal-info .information {
    display: flex;
}

.setting-wrapper .personal-info .information form label {
    font-size: 17px;
    margin-bottom: 6px;
    font-weight: 500;
}

.setting-wrapper .personal-info .information form label span {
    color: #ff0000;
}

.setting-wrapper .personal-info .information .image {
    width: 200px;
    height: 200px;
    margin-right: 50px;
    overflow: hidden;
    border-radius: 15px;
    overflow: hidden;
    /* background-color: #f0f0f0; */
}

.setting-wrapper .personal-info .information .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.setting-wrapper .personal-info .information form {
    flex: 1;
}

.setting-wrapper .personal-info .information form .form-control {
    background: #1B1A21;
    border: none;
    color: #fff;
    padding: 8px 16px;
    font-size: 16px;
    height: 60px;
    outline: none;
    box-shadow: none;
    border: 2px solid #323239;
    border-radius: 10px;
}

.setting-wrapper .personal-info .information form .form-control::placeholder {
    color: #ffffff75;
}

.setting-wrapper .personal-info .information form textarea.form-control {
    height: 170px;
    padding: 10px 20px;
    /* border-radius: 10px !important; */
    resize: none;
}

.user-info .RSPBprogressBar .RSPBstep {
    top: -25px;
}

.steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    white-space: normal;
}

.steps img {
    width: 200px !important;
}

/* .user-info .RSPBprogressBar .RSPBstep:first-child {
    transform: none;
} */

/* .user-info .RSPBprogressBar .RSPBstep:nth-child(5) {
    left: 97% !important;
} */

.user-info .RSPBprogressBar {
    border: 2px solid #00000012;
    margin-bottom: 0;
    background-color: #1756a329;
}

.user-info .RSPBprogressBar .RSPBprogression {
    background-image: linear-gradient(128deg, #253dbd 0%, #2bfcd0 60%);
}

.user-info .progress-stats h2 {
    font-size: 12px;
    line-height: 15px;
    font-family: "Montserrat";
    position: absolute;
    top: -26px;
    right: 30px;
    color: #000;
    margin-top: 0;

}

.user-info .RSPBprogressBar .RSPBprogression::after {
    font-family: "FontAwesome";
    content: "\f0d7 ";
    color: #000;
    font-size: 12px;
    padding: 4px;
    position: absolute;
    font-size: 20px;
    top: -21px;
    right: -8px;
    text-align: center;
    transition: left 0.4s linear;
}

.user-info .RSPBprogressBar .RSPBprogression::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 13px;
    background-color: #000000c9;
    top: -6px;
    right: 0px;
}

.progress-stats h2 {
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    position: absolute;
    top: -18px;
    left: 0;
}

.left-sidebar .user-info .user-about {
    flex-direction: row;
}

.left-sidebar .user-info .user-about .image {
    margin-right: 10px;
}

.left-sidebar .about-details {
    flex: 1;
}

.left-sidebar .about-details h5 {
    text-align: left;
}

.left-sidebar .about-details p {
    text-align: left;
}

.progress-stats {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.progress-stats .bar-bg {
    background: #323239;
    padding: 20px;
    border-radius: 13px;
}

.progress-stats .bar-width {
    width: 20%;
    max-width: 100%;
}

.mini-progress-stats .progress-stats {
    margin: 0 auto 30px;
    width: 100%;
    max-width: 100%;
}

.mini-progress-stats .progress-stats .bar-width {
    width: 120px;
    max-width: 100%;
    margin: 0 auto;
}

.mini-progress-stats .CircularProgressbar .CircularProgressbar-text,
.progress-stats .CircularProgressbar .CircularProgressbar-text {
    fill: #fff;
    font-size: 20px;
    font-weight: 900;
}

.mini-progress-stats .bar-width {
    background: none;
    padding: 0;
    border-radius: 0;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: #111111 !important;
    stroke-width: 5px;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #2cfafe !important;
    stroke-width: 6px;
}

.progress-stats .progress-tasks {
    width: 78%;
}

.progress-stats .notifications .list {
    padding: 0;
}

.progress-stats .progress-tasks .list ul {
    display: flex;
    flex-wrap: wrap;
}

.progress-stats .progress-tasks .list ul li {
    background: transparent linear-gradient(180deg, #1554F0 0%, #21FAFF 100%) 0% 0% no-repeat padding-box;
    padding: 20px;
    position: relative;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10px 10px 0;
    width: 24%;
    min-height: 135px;
}

.progress-stats .progress-tasks .list ul li.inactive {
    background: transparent linear-gradient(180deg, #1e59ef 0%, #2dd0d4 100%) 0% 0% no-repeat padding-box;
    color: #fff;
}

.progress-stats .progress-tasks .list ul li .task-name {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.progress-stats .progress-tasks .list ul li .percentage {
    font-size: 20px;
    font-weight: 500;
}

.progress-stats .progress-tasks .list ul li .lotties-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.progress-stats .progress-tasks .list ul li.active {
    background: transparent linear-gradient(180deg, #001d65 0%, #00676a 100%) 0% 0% no-repeat padding-box;
    color: #ffffff96;
}

.progress-stats .progress-tasks .list ul li.active .lotties-wrap {
    opacity: 1;
}

.progress-stats .progress-tasks .list ul li .lotties-wrap .claimed-lottie-1 {
    width: 19px;
    height: 19px;
    display: block;
    position: relative;
    top: 25px;
    right: -50%;
}

.progress-stats .progress-tasks .list ul li .lotties-wrap .claimed-lottie-2 {
    width: 110px;
    height: 110px;
    display: block;
    cursor: default;
}

.progress-stats .progress-tasks .list ul li .lotties-wrap .claimed-lottie-2 div {
    cursor: default;
}

.daily-task-wrapper .lotties-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.daily-task-wrapper.achieved {
    color: #ffffff96;
}

.daily-task-wrapper.achieved::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #000000BD;
}

.daily-task-wrapper.achieved .platform-name,
.daily-task-wrapper.achieved .image {
    opacity: 0.4;
}

.daily-task-wrapper.achieved .lotties-wrap {
    opacity: 1;
}

.daily-task-wrapper.achieved .lotties-wrap .claimed-lottie-1 {
    width: 25px;
    height: 25px;
    display: block;
    position: relative;
    top: 25px;
    right: -50%;
}

.daily-task-wrapper.achieved .lotties-wrap .claimed-lottie-2 {
    width: 170px;
    height: 170px;
    display: block;
    cursor: default;
}

.daily-task-wrapper.achieved .lotties-wrap .claimed-lottie-2 div {
    cursor: default;
}

.progress-stats .notifications .list .head p {
    font-weight: 900;
    /* opacity: 0.5; */
    font-size: 16px;
    /* font-family: 'Neon'; */
}

.progress-stats .notifications .list li {
    font-size: 14px;
    font-weight: 400;
    border-bottom: 2px solid #323239;
}

.progress-stats .notifications .list li:last-child {
    border: none;
}

.progress-stats .notifications .list li.inactive {
    opacity: 0.6;
    cursor: not-allowed;
}

.progress-stats .RSPBprogressBar img {
    /* width: 20px !important; */
    padding: 0;
}

.not-acomplished-style {}

.progress-stats:nth-child(3) {
    background-color: red !important;
}

.progress-stats .RSPBprogressBar .RSPBstep {
    top: 25px;
    border: 3px solid #f1f1f1;
    border-radius: 50%;
    /* padding: 2px; */
    width: 28px;
    height: 28px;
}

/* .progress-stats .RSPBprogressBar .RSPBstep:first-child {
    transform: none;
} */

/* .progress-stats .RSPBprogressBar .RSPBstep:nth-child(5) {
    left: 98% !important;
} */

.badges .item {
    background: #1e1e24;
    padding: 15px;
    width: 100%;
    height: 100%;
    position: relative;
    white-space: normal;
    text-align: left;
}

.tags-spinner-border {
    width: 20px !important;
    height: 20px !important;
}

.badges .item.active::before {
    display: none;
}

.badges .item:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000e0;
    z-index: 9;
    background-image: url(./assets/images/locked-badge.png);
    background-repeat: no-repeat;
    background-position: center;
}

.badges .item .image-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 20px;
    background: #333339;
}

.leaderboardlogo {
    display: flex;
    align-items: center;
}

.custom-padding .header-links .nav-link:hover {
    color: #34c3ff !important;
}

.header .navbar-nav .nav-link:last-child:hover {
    color: #fff;
}

.badges .item.daily .image-bg {
    background-image: linear-gradient(128deg, #2bfcd0 0%, #253dbd 100%);
}

.badges .item.early .image-bg {
    background-image: linear-gradient(128deg, #ec666c 0%, #a21a20 100%);
}

.badges .item.glitter .image-bg {
    background-image: linear-gradient(128deg, #761ac0 0%, #eb90d3 100%);
}

.badges .item.golden .image-bg {
    background-image: linear-gradient(128deg, #f5d174 0%, #f1b029 100%);
}

.badges .item.light .image-bg {
    background-image: linear-gradient(128deg, #96c93d 0%, #77ac1a 100%);
}

.badges .item.ambassador .image-bg {
    background-image: linear-gradient(128deg, #eca627 0%, #bb6c19 100%);
}

.badges .item.creator .image-bg {
    background-image: linear-gradient(128deg, #b7bbbc 0%, #989fa0 100%);
}

.badges .item.earner .image-bg {
    background-image: linear-gradient(128deg, #ee34d9 0%, #8bfdfd 100%);
}

.badges .item.virtual .image-bg {
    background-image: linear-gradient(128deg, #ec7e53 0%, #bf5040 100%);
}

.badges .item.pent .image-bg {
    background-image: linear-gradient(128deg, #ca2b5e 0%, #763a87 100%);
}

.badges .item.weekly .image-bg {
    background-image: linear-gradient(128deg, #2bfcd0 0%, #253dbd 100%);
}

.badges .item.speed .image-bg {
    background-image: linear-gradient(128deg, #7346ff 0%, #3d17c4 100%);
}

.badges .item.welcome .image-bg {
    background-image: linear-gradient(128deg, #c6b199 0%, #816c59 100%);
}

.badges .item.influencer .image-bg {
    background-image: linear-gradient(128deg, #926adb 0%, #4f3383 100%);
}

.badges .item.hyper .image-bg {
    background-image: linear-gradient(128deg, #eecaa2 0%, #ef639f 100%);
}

.badges .item.magician .image-bg {
    background-image: linear-gradient(128deg, #d161ac 0%, #db37a4 100%);
}

.badges .item.monthly .image-bg {
    background-image: linear-gradient(128deg, #2bfcd0 0%, #253dbd 100%);
}

.badges .item .image {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.badges .item .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.badges .item h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 55px;
    word-break: break-word;
}

.badges .item p {
    font-size: 15px;
    opacity: 0.4;
    line-height: 22px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 46px;
    word-break: break-word;
}

.no-data {
    height: 150px;
    border: 1px dashed #84e9f4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    text-transform: capitalizes;
}

.setting-wrapper .personal-info .information form .btn-primary,
.contest-btn {
    background: #2cfafe;
    color: #111115;
    border: 1px solid #2cfafe;
    outline: none;
    box-shadow: none;
    margin-top: 20px;
    clip-path: polygon(2% 6%, 100% 0, 100% 100%, 0 97%);
    border-radius: 24px 8px 8px 12px;
    padding: 15px 30px;
    font-family: "Neon";
}

.sidebar.active {
    background: none;
}

.active {
    opacity: 1;
    background: transparent linear-gradient(90deg, #84e9f427 0%, #84e9f400 100%) 0% 0% no-repeat padding-box;
}

.setting-wrapper .personal-info .information form .btn-primary:hover,
.congrats-modal .modal-dialog .modal-content .modal-body .btn-gradient-blue:hover,
.contest-btn:hover {
    background: #2cfafe;
    border-color: #2cfafe;
    color: #000;
    /* border: 1px solid #84e9f4; */
}

.points-table .table {
    color: #fff;
}

/* .points-table .table th{background: #000;} */
.points-table .table th,
.points-table .table td {
    padding: 10px;
    text-align: left;
}

.points-table .table> :not(:first-child) {
    border: none;
}

.points-table .table td {
    background: #1e1e24;
    border-color: #4a4a50;
}

.coinmarket-rewards {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 170px;
    /* padding-right: 50px; */
}

.coinmarket-rewards .content {
    flex: 1;
    padding-right: 200px;
}

.coinmarket-rewards .content h3 {
    font-family: "Montserrat";
    margin-bottom: 15px;
    font-size: 24px;
}

.coinmarket-rewards .content p {
    opacity: 0.4;
    margin-bottom: 35px;
}

.coinmarket-rewards .content .actions .btn-primary {
    background: #84e9f4;
    color: #000;
    height: 65px;
    font-weight: 500;
    border-color: #84e9f4;
    padding: 10px 20px;
    margin-right: 30px;
}

.coinmarket-rewards .content .actions .link {
    color: #84e9f4;
}

.coinmarket-rewards .image-box {
    margin-left: 200px;
    position: relative;
    border-radius: 18px;
    text-align: center;
    background: #1554F0;
    padding: 10px;
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.coinmarket-rewards .image-box span:first-child {
    font-size: 22px;
    font-family: "Neon";
    color: #fff;
    margin-bottom: 10px;
    display: block;
    font-weight: 700;
}

.coinmarket-rewards .image-box span:last-child {
    font-size: 60px;
    line-height: 70px;
    font-weight: 900;
    color: #21FAFF;
}

.claim-rewards {
    background-color: #323239;
    padding: 40px;
    border-radius: 20px;
    position: relative;
}

.claim-rewards .treasure-image {
    width: 300px;
    height: 300px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -150px;
}

.claim-rewards .treasure-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.claim-rewards .claim-rewards-header {
    max-width: 520px;
}

.claim-rewards .claim-rewards-header h3 {
    margin-bottom: 20px;
    font-family: 'Montserrat';
    font-size: 24px;
}

.claim-rewards .claim-rewards-header p {
    margin-bottom: 40px;
    opacity: 0.4;
}

.next-rewards-list {
    /* border: 3px dashed #2e484d; */
    /* border-radius: 10px; */
    text-align: center;
    padding-top: 70px;
    /* margin-top: 80px; */
}

.claim-rewards .time-title {
    background: #11111580;
    border-radius: 8px;
    max-width: 230px;
    color: #fff;
    padding: 14px 20px;
    width: fit-content;
    position: absolute;
    right: 110px;
    /* right: 0; */
    top: 0;
}

.claim-rewards .time-img {
    width: 130px;
    height: 148px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../src/assets/images/claim-time.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -18px;
    right: 0;
    font-weight: 700;
    font-size: 20px;
}

.claim-rewards .time-count {
    font-size: 24px;
    font-weight: 700;
    font-family: "Montserrat";
}

.claim-rewards .time-title strong {
    font-family: "Montserrat";
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: block;
}

.next-rewards-list ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.next-rewards-list ul li {
    position: relative;
    cursor: pointer;
    width: 23%;
    border-radius: 16px;
    padding: 18px 35px;
    margin: 7px;
    background: transparent linear-gradient(44deg, #21FAFF 12%, #1554F0 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
    min-height: 200px;
    height: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: space-between;
}

.next-rewards-list ul li:last-child {
    width: 48%;
    position: relative;
    overflow: hidden;
    background: #6eced0;
    color: #000;
}

.next-rewards-list ul li:last-child .claim-status {
    opacity: 0.3;
}

.next-rewards-list ul li:last-child::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("./assets/images/star.png");
    opacity: 1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.next-rewards-list ul li .image {
    height: 90px;
    width: 125px;
}

.next-rewards-list ul li .image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.tresure-week .claimed .claim-status {
    text-align: start;
}

.tresure-week .claimed .claim-status strong {
    font-size: 17px;
    font-family: "Neon";
    text-align: start;
    color: #000;
    font-weight: 700;
}

.tresure-week .able .claim-status p {
    font-size: 14px;
    margin-bottom: 5px;
    text-align: start;
    color: #000;
    text-transform: uppercase;
    font-weight: 500;
}

.tresure-week .able .claim-status h6 {
    font-family: "Montserrat";
    text-align: start;
    color: #000;
    font-size: 16px;
}

.tresure-week .able .claim-status h6 .countdown-container {
    justify-content: flex-start !important;
    font-weight: 700;
}

.tresure-week .able .claim-status h6 .countdown-container .countdown-wrapper {
    display: flex;
    align-items: center;
}

.tresure-week .able .claim-status h6 .countdown-container .countdown-wrapper .daily {
    font-weight: 700;
    font-size: 16px;
}

.next-rewards-list ul li .day {
    font-size: 16px;
    font-family: "Neon";
    font-weight: 700;
}

.next-rewards-list ul li .points {
    font-size: 26px;
    font-weight: 900;
    margin-top: 0;
}

.next-rewards-list ul li span {
    font-weight: 500;
}

.next-rewards-list ul li:hover {
    background: transparent linear-gradient(44deg, #62dcdf 12%, #4d81ff 100%) 0% 0% no-repeat padding-box;
}

.next-rewards-list ul li .lotties-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.next-rewards-list ul li .claimed-lottie-1 {
    width: 25px;
    height: 25px;
    display: block;
    position: relative;
    top: 25px;
    right: -50%;
}

.next-rewards-list ul li .claimed-lottie-2 {
    width: 130px;
    height: 130px;
    display: block;
}

.breadcrum.daily {
    margin-left: -24px;
}

.banner-title {
    background: #2e2a96;
    padding: 50px 30px 35px;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 50px;
}

.banner-title p {
    font-size: 26px;
    max-width: 400px;
    margin-left: 90px;
}

.banner-title h1 {
    font-size: 120px;
    color: #84e9f4;
    text-align: center;
    line-height: 100px;
    font-weight: 500;
    margin: 0;
}

.banner-title h1 span {
    font-size: 50px;
    display: block;
    color: #fff;
    margin-bottom: -40px;
}

.avatar-badge {
    display: flex;
    width: 100%;
}

.buyers-rewards .item {
    /* display: flex; */
    padding: 50px 0;
    border-bottom: 2px solid #cccccc1c;
}

.buyers-rewards .item .overview {
    padding-right: 130px;
}

.avatar-badge .image {
    height: 110px;
    margin-bottom: 15px;
    width: 110px;
    max-width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background: #2cfafe;
}

.avatar-badge .image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.avatar-badge span {
    font-weight: 500;
    color: #84e9f4;
    font-size: 15px;
    line-height: 21px;
}

.avatar-badge .avatar {
    margin-right: 35px;
}

.buyers-rewards .item .overview>p {
    /* max-width: 850px; */
    opacity: 0.6;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
}

.more-items>p {
    margin-bottom: 30px;
    font-weight: 500;
    cursor: pointer;
}

.more-items .others>p {
    color: #84e9f4;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 17px;
}

.more-items .others ul li {
    width: 85px;
    margin-right: 25px;
}

.more-items .others ul {
    display: flex;
}

.icon svg {
    fill: rgb(81, 132, 140);
    font-size: 20px;
}

.icon .bitcoin-icon {
    width: 20px
}

.more-items .others ul li .image {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 7px;
    overflow: hidden;
    background-color: #2cfafe;
}

.more-items .others ul li span {
    font-size: 14px;
    opacity: 0.6;
    line-height: 18px;
    display: block;
}

.buyers-rewards .item .overview>.others>ul {
    margin-top: 15px;
}

.buyers-rewards .item .overview>.others>ul li {
    font-weight: 500;
    margin-bottom: 10px;
    /* background: url("./assets/images/logo.svg"); */
    padding-left: 20px;
    background-position: left 5px;
    background-size: 10px;
    background-repeat: no-repeat;
}

.buyers-rewards .load-more {
    text-align: center;
    margin-top: 30px;
}

.buyers-rewards .load-more span {
    cursor: pointer;
}

.buyers-rewards .item .others {
    margin-bottom: 20px;
    transition: ease all 0.4s;
    /* max-width: 835px; */
}

.buyers-rewards .item .others ul li {
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
}

.buyers-rewards .item .others ul li::before {
    font-family: "FontAwesome";
    content: "\f00c";
    position: absolute;
    top: 0px;
    left: 0;
    font-size: 20px;
    color: #2cfafe;
    line-height: 22px;
}

.buyers-rewards .more-items .others ul li::before {
    display: none;
}

.buyers-rewards .more-items .others ul li {
    padding-left: 0;
}

.buyers-rewards .item .others.hide {
    height: 0;
    opacity: 0;
}

.buyers-rewards .item .others.show {
    height: auto;
    opacity: 1;
}

.buyers-rewards .item .overview .more {
    color: #fff;
    opacity: 1;
    cursor: pointer;
    transition: all 0.3s;
    background-color: #32323970;
    padding: 8px 12px;
    border-radius: 8px;
    /* position: relative; */
}

.buyers-rewards .item .overview .more:hover,
.buyers-rewards .item .overview .more.active {
    color: #2cfafe;
    background-color: #323239a6;
    transition: all 0.3s;
}

.buyers-rewards .item .overview .more.active .icon {
    transform: rotate(180deg);
}

.buyers-rewards .item .overview .more .fa {
    color: #84e9f4;
    margin-left: 20px;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filters .btn-secondary {
    color: #1554F0;
    background-color: #ffffff;
    border-color: #1554F0;
    padding: 7px 15px;
    font-size: 15px;
    box-shadow: none;
    font-weight: 500;
}

.filters .btn-secondary.active,
.filters .btn-secondary:hover,
.filters .btn-secondary:focus {
    background: transparent linear-gradient(64deg, #8136fb 0%, #2be7fd 100%) 0% 0% no-repeat padding-box;
    border-color: #1554F0;
    color: #fff;
}

.purchase-item-list .purchase-item {
    margin-top: 20px;
}

.purchase-item .item {
    display: flex;
    align-items: center;
    background: #32323940;
    padding: 15px 10px;
    margin-bottom: 5px;
}

.purchase-item .item:hover {
    background: #393943;
}

.purchase-item .item .image {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #42757a;
    border-radius: 50%;
    margin-right: 15px;
}

.purchase-item .item .content {
    flex: 1;
}

.purchase-item .item .content h4 {
    font-size: 18px;
}

.purchase-item .item .content p {
    font-size: 14px;
}

.choose-format .btn-close {
    filter: invert(1);
    position: absolute;
    right: 20px;
    top: 20px;
}

.create-game-wrapper {
    background: #32323940;
    padding: 10px 25px 20px;
    padding-right: 50px;
}

.create-game-wrapper .form-modal h3 {
    font-size: 22px;
}

.create-game-wrapper label {
    padding-left: 10px;
}

.custom-picker .rs-picker-toggle {
    border-color: #1554F0 !important;
    color: #1554F0;
    box-shadow: none;
}

.custom-picker .rs-picker-toggle .rs-picker-toggle-textbox,
.custom-picker .rs-picker-toggle .rs-picker-toggle-value {
    color: #1554F0;
    cursor: pointer;
}

.rs-picker-daterange-header {
    color: #1554F0;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #1554F0;
}

.rs-calendar-table-cell-in-range:before,
.rs-calendar-table-cell-content:hover,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: #1554f030;
    color: #333;
}

.rs-btn-link {
    color: #1554F0;
}

.rs-btn-primary {
    background-color: #1554F0;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px #1554F0;
}

.form-control.editor {
    height: auto;
    padding: 0;
    color: #000;
}

.form-control.editor .editor-class.rdw-editor-main {
    padding: 0 10px;
    color: #fff;
}

.form-control.editor a {
    color: #000;
}

.image .form-label {
    display: none !important;
}

.upload-wrapper {
    position: relative;
    height: 100%;
    background: #44444E;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    font-size: 24px;
}

.upload-wrapper .upload-camera {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: #00000047; */
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
}

.upload-wrapper input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.upload-wrapper .upload-img {
    width: 100%;
    height: 100%;
}

.upload-wrapper .upload-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.custom-form-modal .form-modal label {
    width: 100%;
}

.kJrhuj option {
    color: #fff !important
}

.form-modal label {
    width: 100%;
}

.main-content-area select {
    background: #1B1A21 !important;
    border: 2px solid #323239 !important;
    color: #fff;
}

.main-content-area select option {
    color: #fff;
}

.main-content-area select:focus {
    box-shadow: none !important;
    border: none !important;
}

.giftcard-table .actions {
    /* min-width: 80px; */
    display: flex;
}

.main-dashboard {
    position: relative;
    padding-right: 20px;
}

/* .main-dashboard::before {
    content: '';
    background: url('./assets/images/home-overlay.png') no-repeat;
    background-position: top center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
    z-index: -1;
} */
.heading h4 {
    font-size: 18px;
    flex: 1;
}

.rs-picker-menu {
    z-index: 9;
}

.buyers-rewards {
    /* background: #32323940 ;
    padding: 20px 20px; */
}

.form-bg-wrapper {
    background: #323239 0% 0% no-repeat padding-box;
    border: 2px solid #323239;
    border-radius: 20px;
    padding: 25px;
}

.guide {
    margin-bottom: 30px;
}

.guide .section-dark-bg {
    background: linear-gradient(90deg, #E55F5F 0%, #E55F5F80 100%);
    color: rgba(255, 255, 255, 0.753);
    border-radius: 6px;
    font-size: 16px;
    padding: 14px 30px;
}

.guide .section-green-bg {
    background: linear-gradient(90deg, #4bfa06 0%, #33FF68 100%);
    color: rgba(255, 255, 255, 0.753);
    border-radius: 6px;
    font-size: 20px;
    padding: 14px 30px;
    font-weight: bolder;

}

.guide span {
    font-weight: 500;
    color: #fff;
}

#edit-avatr-modal .content p {
    margin-bottom: 20px;
}

#edit-avatr-modal .content ul {
    list-style: circle;
    padding-left: 20px;
}

#edit-avatr-modal .content ul li {
    margin-bottom: 10px;
}

#edit-avatr-modal .content ul li a {
    color: #11d6b9;
}

#edit-avatr-modal .content ul li a:hover {
    text-decoration: underline;
}

.form-modal.contest {
    margin-top: 0;
}

.form-modal.contest input::placeholder {
    opacity: 0.3;
}

.contest-card {
    background: #323239;
    padding: 25px;
    max-width: 100%;
    text-align: left;
    word-break: break-word;
    margin: 0 auto;
    border-radius: 20px;
    position: relative;
    padding-bottom: 100px;
    height: 100%;
}

.contest-card ul.contest-model {
    max-height: 225px;
    overflow: auto;
}

.contest-card ul.contest-model::-webkit-scrollbar {
    width: 3px;
}

.contest-card h4 {
    font-size: 28px;
    line-height: 40px;
    color: #ffffff;
    word-break: break-all;
}

.contest-card p {
    font-size: 18px;
    font-weight: 500;
    color: #7F8890;
    word-break: break-all;
}

.contest-card p:last-child {
    font-size: 15px;
}

.contest-card .points {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    background: rgb(61 34 239 / 15%);
    padding: 15px;
    border-radius: 10px;
    color: #fff;
    font-family: 'Neon';
    font-weight: 900;
    margin-top: 16px;
    margin-bottom: 30px;
}

.form-modal.contest .contest-card .points label {
    width: auto;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 900;
}

.contest-card .card-top {
    font-size: 24px;
    color: #FFFFFF;
    font-family: 'Neon';
    font-weight: 900;
    padding-bottom: 13px;
    margin-bottom: 27px;
    border-bottom: 1px solid #606060;
}

.contest-card ul.contest-model li {
    margin-bottom: 13px;
    position: relative;
    padding-left: 35px;
    font-size: 14px;
    font-weight: 500;
}

.contest-card ul.contest-model li::before {
    font-family: "FontAwesome";
    content: "\f00c";
    position: absolute;
    top: 0px;
    left: 0;
    font-size: 20px;
    color: #2cfafe;
    line-height: 22px;
}

.contest-card .card-bottom {
    position: absolute;
    background: rgb(0 0 0 / 15%);
    left: 0;
    bottom: 0;
    width: 100%;
    color: #7F8890;
    font-size: 14px;
    font-weight: bold;
    /* text-align: center; */
    padding: 16px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.contest-card .card-bottom strong {
    color: #fff;
    font-size: 16px;
}

.contest-card .wallet-address-copy {
    border: 1px solid #2cfafe;
    padding-left: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.contest-card .wallet-address-copy p {
    font-size: 15px;
    color: #fff;
}

.contest-card .wallet-address-copy .copy-icon {
    font-size: 16px;
    background: #2cfafe;
    padding: 5px;
    width: 35px;
    height: 35px;
    /* border-radius: 0 5px 5px 0; */
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.address-wrapper {
    /* padding-bottom: 20px; */
    border-bottom: 2px solid #323239;
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    align-items: center;
}

.setting-wrapper .share-btns {
    background: #404048;
    padding: 20px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.address-wrapper .copy-wrapper {
    background: #1B1A21;
    position: relative;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    border: 2px solid #323239;
    border-radius: 10px;
    width: 100%;
}

.address-wrapper .share-btns {
    width: 30%;
    padding-left: 20px;
}

.copy-wrapper .copy-icon,
.field-wrapper .copy-icon {
    position: absolute;
    right: 0;
    background: #84e9f4;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    color: #000;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}

.copy-wrapper .copy-icon:hover .fa,
.field-wrapper .copy-icon:hover .fa {
    /* transform: scale(1.1); */
}

.share-btns label {
    margin-bottom: 0;
    margin-right: 10px;
}

.share-btns button {
    margin-right: 10px;
}

.share-btns button svg {
    width: 40px;
    height: 40px;
}

.not-found a {
    color: #84e9f4;
}

.form-modal.contest label {
    color: #7F8890;
    font-size: 18px;
    font-weight: bold;
    width: fit-content;
}

.platform-wallet-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
    background: #00000063;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.simple-layout {
    padding-top: 50px;
    padding-bottom: 20px;
}

.simple-layout .page-title {
    margin-bottom: 50px;
}

.infinite-scroll-component::-webkit-scrollbar {
    display: none;
}

.form-modal .react-tagsinput {
    background-color: #1B1A21;
    border: 2px solid #323239 !important;
}

.form-modal .react-tagsinput-tag {
    background-color: #000;
    border-radius: 2px;
    border: 1px solid #323239 !important;
    color: #F2F2F2;
}

.form-modal .react-tagsinput-input {
    color: #F2F2F2;
}

.form-modal .custom-group {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.promotion-modal .modal-content {
    background-image: url('./assets/images//popup.png');
    border-radius: 20px;
    background-size: 100% 100%;
    padding: 50px 0;
    border: 2px solid #fff;
}

.special-notes-modal .modal-content {
    background-image: url('./assets/images/popup.png');
    height: 492px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
}

.special-notes-modal .modal-body {
    /* padding-top: 70px; */
    width: 100%;
}

.promotion-modal .modal-dialog {
    /* max-width: 1080px; */
    width: 100%;
    max-width: 950px;
    padding: 0 12px;
}

.modal-animation {
    width: 0%;
}

.modal-backdrop {
    width: 0 !important;
}

.modal-backdrop.show {
    width: 100vw !important;
    transition: 0.6s ease-in-out !important;
}

.modal-animation.show {
    width: 100%;
    transition: .6s ease-in-out;
}

.promotion-modal .modal-content .modal-header {
    border: none;
    position: absolute;
    right: 20px;
    top: 20px;
    background: #ffffff45;
    color: #fff;
    width: 45px;
    height: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    z-index: 5;
}

.promotion-modal .modal-content .modal-header .btn-close {
    filter: invert(1);
    opacity: 1;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.promotion-modal .modal-content .image {
    width: 220px;
    height: 220px;
    /* margin-left: 150px; */
}

.special-notes-modal .modal-content .image {
    width: 300px;
    height: 300px;
    /* margin-left: 140px; */
}

.promotion-modal .modal-content .image .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* opacity: 0.5; */
}

.special-notes-modal .modal-content .image .img-fluid {
    opacity: 1;
}

.promotion-modal .modal-content .content h2 {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 40px;
}

.promotion-modal .modal-content .content {
    height: 356px;
    overflow-y: auto;
}

.promotion-modal .modal-content .content::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background: #323239;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb {
    background: #00e4e9;
    border-radius: 0px;
}

* {
    scrollbar-width: 7px;
    scrollbar-color: #00e4e9 #323239;
}

*::-moz-scrollbar-thumb {
    background-color: #00e4e9;
}

*::-moz-scrollbar-track {
    background-color: #323239;
}

.promotion-modal .modal-content .content p,
.promotion-modal .modal-content .content ul {
    margin-bottom: 10px;
    margin-top: 0;
}

.promotion-modal .modal-content .content ul li {
    margin-bottom: 5px;
}

.promotion-modal .modal-content .content .action-btn {
    /* margin-top: 50px; */
}

.promotion-modal .modal-content .content .btn-primary {
    background: #84E9F4;
    color: #000;
    font-family: 'Neon';
    font-weight: 900;
    padding: 15px 20px;
    border-radius: 8px;
    border-color: #84E9F4;
    box-shadow: none;
    outline: none;
}

.promotion-modal .modal-content .content .btn-primary:hover {
    background: transparent;
    color: #84E9F4;
}

.social-sharing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.social-sharing button {
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #fff;
    border: none;
    margin: 4px;
    padding: 9px 18px;
    font-size: 16px;
    font-weight: 500;
}

.social-sharing button:active {
    outline: 0;
}

.social-sharing button:focus {
    box-shadow: none;
}

.social-sharing button:hover {
    color: #fff;
}

.social-sharing button.facebook {
    background-color: #3b5998;
}

.social-sharing button.facebook:hover {
    background-color: #30529b;
}

.social-sharing button.instagram {
    background-color: #fa31a9;
}

.social-sharing button.instagram:hover {
    background-color: #fc0499;
}

.social-sharing button.twitter {
    background-color: #26a7de;
}

.social-sharing button.twitter:hover {
    background-color: #0098da;
}

.social-sharing button svg {
    font-size: 16px;
}

.posts-myntist .container1 .featured-area-wrapper {
    height: 450px;
    display: flex;
    margin-bottom: 50px;
}

.posts-myntist .container1 .featured-area-wrapper .fp-col2 {
    display: flex;
    width: 60%;
    flex-wrap: wrap;
}

.posts-myntist .container1 .featured-area-wrapper .col1 {
    width: 40%;
    position: relative;
}

.posts-myntist .container1 .featured-area-wrapper .col2,
.posts-myntist .container1 .featured-area-wrapper .col3,
.posts-myntist .container1 .featured-area-wrapper .col4,
.posts-myntist .container1 .featured-area-wrapper .col5 {
    width: 50%;
    height: 50%;
    position: relative;
}

.posts-myntist .author-info .auth-url:hover {
    text-decoration: underline;
}

.fp-col1 .featured-header,
.fp-col2 .f-col .featured-header {
    position: absolute;
    width: 100%;
    padding: 5%;
    left: 0;
    bottom: 0;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .7));
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.featured-header .auth-byline {
    color: #a3a3a3;
}

.featured-item:hover+.featured-header .author-info {
    margin-bottom: 20px !important;
    transition: all .3s ease-out;
}

.featured-header:hover .author-info {
    margin-bottom: 20px !important;
    transition: all .3s ease-out;
}

.masonry-item .content-tags {
    background-color: #333134;
    padding: 16px 32px;
    display: flex;
    flex-wrap: wrap;
}

.masonry-item .content-tags a {
    text-transform: uppercase;
    color: #a3a3a3;
    font-size: 12px;
}

.masonry-item .content-tags a:hover {
    text-decoration: underline;
}

.masonry-item .content-tags a:not(:last-child) {
    margin-right: 15px;
}

.featured-header {
    font-size: 14px;
}

.notification-dot {
    position: relative;
}

.table th,
.table td {
    color: #fff;
    background: transparent;
}

.table .ava-img {
    width: 50px;
}

.table tbody tr {
    vertical-align: middle;
}

.notification-dot .icon-dot {
    background: #F9896B 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #ffffff;
    position: absolute;
    top: -12px;
    border-radius: 50%;
    right: -12px;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-dot .icon-dot .count {
    font-size: 10px;
    font-family: 'Montserrat';
}

.masonry-item a {
    position: relative;
}

.masonry-item a .masonry-img img,
.masonry-item a .masonry-img video {
    object-fit: cover;
}

.masonry-item .bb-post-meta {
    font-size: 10px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(40, 40, 40, .5);
    padding: 4px 8px;
    border-radius: 25px;
}

.slick-track {
    display: flex;
}

.promotion-wrapper .promotion-description {
    /* max-height: 105px; */
    /* overflow-y: auto !important; */
    /* overflow: hidden; */
    /* margin-top: 20px; */
    max-width: 45%;
    margin-right: auto;
    text-align: left;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
}

.promotion-img::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(90deg, #11111598 0%, #11111500 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    z-index: 1;
}

.table-r-inactive {
    opacity: 0.3;
    cursor: not-allowed;
}


.swal2-title {
    font-size: 18px !important;
}

.item .nft-image {
    position: relative;
    margin-bottom: 22px;
    overflow: hidden;
}

.item .nft-image .nft-img-link {
    display: block;
    width: 240px;
    max-width: 100%;
    height: 240px;
    background: #75d8ff;
    border-radius: 8px;
    margin: 0 auto;
}

.not-slider .item .nft-image .nft-img-link {
    height: 230px;
    max-width: 100%;
}

.nfts-gallery ul li .nft-img-link {
    width: 100%;
    height: 140px;
    display: inline-block;
}

.nfts-gallery ul li:first-child .nft-img-link,
.nfts-gallery ul li:nth-child(2) .nft-img-link {
    height: 225px;
}

.chat-close-component {
    position: relative;
}

.my-nfts .nft-list .item .nft-image .img {
    /* width: 200px;
    height: 200px;
    margin: 0 auto; */
}

.answer-wrapper .answer-wrapper-img {
    position: relative;
}

.answer-wrapper .correct-wrapper-icon {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid silver;
    border-radius: 50%;
}

.answer-wrapper .icon-wrapper-center {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    display: none !important;
}

.answer-wrapper .correct-wrapper-icon .fa-check-icon,
.answer-wrapper .correct-wrapper-icon .fa-times-icon {
    color: silver;
    font-size: 35px;
    font-weight: 900;
}

.answer-wrapper:hover .icon-wrapper-center {
    display: flex !important;
}

.answer-wrapper.correct-wrapper .icon-wrapper-center {
    display: flex !important;
}

.answer-wrapper.correct-wrapper .correct-wrapper-icon .fa-check-icon {
    color: rgba(0, 255, 146, 0.8) !important;
}

.answer-wrapper.wrong-wrapper .icon-wrapper-center {
    display: flex !important;
}

.answer-wrapper.wrong-wrapper .correct-wrapper-icon .fa-times-icon {
    color: #eb2739 !important;
}

.answer-wrapper.wrong-wrapper .answer-wrapper-img {
    opacity: 0.5;
}

.answer-wrapper.wrong-wrapper {
    border: 2px solid #eb2739 !important;
}

.answer-wrapper.correct-wrapper {
    border: 2px solid rgba(0, 255, 146, 0.8) !important;
}

.answer2-wrapper .answer2-container .check-wrapper {
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid #e2e2e2;
    border-radius: 50%;
}

.answer2-wrapper .answer2-container .check-wrapper .fa-times-icon {
    color: #e2e2e2;
    font-size: 16px;
    font-weight: 900;
    display: none;
}

.answer2-wrapper:hover .check-wrapper .fa-times-icon {
    display: unset !important;
}

.answer2-wrapper.correct-wrapper .check-wrapper .fa-times-icon {
    display: unset !important;
}

.answer2-wrapper.correct-wrapper .answer2-container .check-wrapper .fa-check-icon {
    color: rgba(0, 255, 146, 0.8) !important;
}

.answer2-wrapper.wrong-wrapper .check-wrapper .fa-times-icon {
    display: unset !important;
}

.answer2-wrapper.wrong-wrapper .answer2-container .check-wrapper .fa-times-icon {
    color: #eb2739 !important;
}

.points-table .ava-img {
    width: 40px;
}

.nft-full-loader {
    min-height: 342px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.daily-points-bg {
    background: #f79980;
    clip-path: polygon(5% 6%, 96% 8%, 100% 100%, 1% 93%);
    border-radius: 40px 40px 8px 25px;
    position: relative;
}

.utility-points-bg {
    background: #9CEFF8;
    clip-path: polygon(5% 6%, 96% 8%, 100% 100%, 1% 93%);
    border-radius: 40px 40px 8px 25px;
    position: relative;
}

.social-points-bg {
    background: #FCC67C;
    clip-path: polygon(5% 6%, 96% 8%, 100% 100%, 1% 93%);
    border-radius: 40px 40px 8px 25px;
    position: relative;
}

.contest-points-bg {
    background: #6861B1;
    clip-path: polygon(5% 6%, 96% 8%, 100% 100%, 1% 93%);
    border-radius: 40px 40px 8px 25px;
    position: relative;
}

.contest-points-bg,
.daily-points-bg,
.utility-points-bg,
.social-points-bg {
    padding: 35px 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 145px;
}

.my-nfts-bg {
    background: #323239;
    clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%);
    transform: rotate(1deg);
    box-shadow: none;
    border-radius: 40px 20px 40px 28px;
}

.user-info .user-about-bg {
    background: transparent linear-gradient(229deg, #AAC1F8 0%, #1CB8F9 36%, #AAF8FA 100%) 0% 0% no-repeat padding-box;
    /* clip-path: polygon(4% 0, 96% 0, 100% 97%, 0% 100%); */
    border-radius: 16px;
    box-shadow: none;
    position: relative;
    overflow: hidden;
}

.user-info .user-about-bg:hover .user-edit {
    opacity: 1;
}

.user-info .user-about-bg::before {
    content: "";
    height: 120%;
    background: #00000024;
    width: 80%;
    position: absolute;
    top: -58px;
    left: -85px;
    /* z-index: -1; */
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.3;
    transform: rotate(188deg);
    overflow: hidden;
}

.badges-earned-bg {
    box-shadow: none;
    background: #323239;
    /* clip-path: polygon(0% 0%, 100% 5%, 96% 98%, 2% 100%); */
    border-radius: 16px;
    padding: 25px;
    min-height: 320px;
    position: relative;
}

.left-sidebar .badges-earned-bg {
    min-height: 100%;
}

.badges-earned-bg::before {
    content: "";
    height: 120%;
    background: #00000024;
    width: 80%;
    position: absolute;
    top: -30px;
    left: -80px;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.3;
    transform: rotate(188deg);
    overflow: hidden;
}

.badges-earned-bg .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-bg {
    /* height: unset; */
    clip-path: polygon(0 2%, 100% 0, 98% 100%, 0 98%);
    border-radius: 40px 20px 30px 50px;
}

/* .sidebar {
    position: relative;
}

.sidebar-item-bg {
    content: '';
    position: absolute;
    background: rgba(30, 30, 36, 0.7);
    top: 20px;
    left: 0;
    width: 100%;
    border-radius: 7px 7px 7px 26px;
    transform: rotate(-1deg);
    clip-path: polygon(0 0, 100% 0, 90% 100%, 5% 100%);
} */

.utility-points-item-bg::after {
    content: '';
    position: absolute;
    background-color: #84E9F4;
    opacity: 0.3;
    top: 25px;
    left: 4px;
    width: 100%;
    height: 83%;
    border-radius: 7px 7px 7px 26px;
    transform: rotate(-4deg);
    clip-path: polygon(0 0, 100% 0, 90% 100%, 5% 100%);
    z-index: -1;
    transition: all 0.3s;
}

.utility-points-item-bg:hover::after {
    transform: rotate(2deg);
}

.utility-points-bg::before,
.daily-points-bg::before,
.social-points-bg::before,
.contest-points-bg::before {
    content: "";
    height: 120%;
    background: #00000024;
    width: 55%;
    position: absolute;
    top: 0;
    right: -12px;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.3;
    transform: rotate(200deg);
    overflow: hidden;
    transition: all 0.3s;

}

.utility-points-bg:hover::before,
.daily-points-bg:hover::before,
.social-points-bg:hover::before,
.contest-points-bg:hover::before {
    /* transform: rotate(173deg) */
    /* right: 200px;
    top: -20px; */
}

.daily-points-item-bg:after {
    background-color: #f9896b;
}

.social-points-item-bg:after {
    background-color: #FDBC64;
}

.contest-points-item-bg:after {
    background-color: #625BAC;
}

.points-list a:hover {
    color: #fff;
}

.game-purchase-wrapper {
    border-radius: 10px;
    padding: 20px;
    position: relative;
    height: 100%;
    min-height: 260px;
}

.game-purchase-wrapper .puchase-link-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.game-purchase-wrapper .image {
    position: absolute;
    top: 0;
    right: 0;
}

.game-purchase-wrapper h5 {
    font-size: 18px;
    line-height: 26px;
    /* height: 115px; */
    margin-bottom: 18px;
}

.game-purchase-wrapper p {
    margin-bottom: 15px;
    font-size: 14px;
}

.monetary-discounts-bg {
    background: transparent linear-gradient(30deg, #FDBC64 0%, #F9896B 100%) 0% 0% no-repeat padding-box;
}

.place-binding-bg {
    background: transparent linear-gradient(30deg, #FC3546 0%, #FC26E7 100%) 0% 0% no-repeat padding-box;
}

.group-buying-bg {
    background: transparent linear-gradient(30deg, #84E9F4 0%, #4F46BA 100%) 0% 0% no-repeat padding-box;
}

.daily-rewards-bg {
    background: #323239;
    padding-top: 30px;
    /* clip-path: polygon(1% 2%, 100% 0, 99% 100%, 0 99%); */
    /* clip-path: polygon(0 3%, 100% 0%, 100% 100%, 0 98%); */
    border-radius: 16px;
}

.daily-tasks-reward {
    height: 100%;
}

.tasks-pagination {
    margin-top: 10px;
    display: flex;
    justify-content: end;
}

.tasks-pagination button {
    margin-right: 5px;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #1769f2;
    background-color: #000;
}

.tasks-pagination button.active {
    background-color: #1769f2;
}

.tasks-pagination button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.tasks-pagination .disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border: none;
    background-color: transparent;
}

.tasks-pagination .previous-arrow::before,
.tasks-pagination .next-arrow::after {
    content: '';
    display: inline-block;
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    padding: 0.25em;
}

.tasks-pagination .previous-arrow {
    transform: rotate(135deg);
    margin-right: 5px;
}

.tasks-pagination .next-arrow {
    transform: rotate(-45deg);
    margin-left: 5px;
}

.daily-task-wrapper {
    /* border: 1px solid #21FAFF; */
    background: transparent linear-gradient(37deg, #21FAFF 0%, #1554F0 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    /* clip-path: polygon(0 0, 100% 0, 91% 100%, 0 100%); */
    padding: 25px 20px;
    min-height: 310px;
    border-radius: 12px;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.claimed .daily-task-wrapper {
    filter: brightness(0.3);
}

.daily-task-wrapper::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50px;
    background: transparent linear-gradient(90deg, #EBEBEB00 0%, #C4C4C480 23%, #C4C4C480 73%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    bottom: -4px;
    left: 0;
    transform: rotate(-2deg);
}

.daily-task-wrapper h4 {
    font-family: 'Montserrat';
    font-size: 18px;
    line-height: 26px;
    font-weight: 900;
    text-transform: capitalize;
}

.daily-task-wrapper .platform-name {
    font-size: 12px;
    font-weight: 700;
    color: #21FAFF;
    display: block;
    margin-bottom: 25px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cards-wrapper ul li .lotties-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0;
}

.cards-wrapper ul li.claimed .lotties-wrap {
    opacity: 1;
}

.cards-wrapper ul li .lotties-wrap .claimed-lottie-1 {
    width: 8%;
    height: 8%;
    top: 35%;
    left: 60%;
}

.cards-wrapper ul li .lotties-wrap .claimed-lottie-2 {
    width: 55%;
    height: 55%;
}

.daily-task-wrapper .lo .daily-task-wrapper .text {
    margin-bottom: 10px;
}


.daily-task-wrapper .day {
    font-weight: 900;
    font-size: 18px;
}

.daily-task-wrapper .today {
    font-weight: 700;
    font-size: 12px;
    color: #000;
}

.daily-task-wrapper.active .today {
    color: #21FAFF;
}

.daily-task-wrapper .text {
    font-weight: 500;
    font-size: 12px;
    /* color: #FFFFFF; */
    /* opacity: 0.25; */
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 40px;
    word-break: break-word;
}

.daily-task-wrapper.active .text,
.daily-task-wrapper.active .points {
    opacity: 1;
    z-index: 1;
}

.daily-task-wrapper .points {
    font-weight: 900;
    font-size: 13px;
    /* background: transparent linear-gradient(90deg, #EBEBEB00 0%, #C4C4C480 23%, #C4C4C480 73%, #FFFFFF00 100%) 0% 0% no-repeat padding-box; */
    /* padding: 7px 0; */
    /* opacity: 0.25; */
    /* color: #fff; */
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.daily-task-wrapper .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.daily-task-wrapper .image {
    position: relative;
    width: 110px;
    height: 110px;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
}

.daily-task-wrapper .image div {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.daily-task-wrapper .image svg {
    width: 35px !important;
}

.points-list a:focus,
.points-list a:active {
    color: #fff;
    text-decoration: none;
}

.sell-avatar-btn {
    color: #fff;
    background: transparent linear-gradient(84deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    font-weight: 600;
    padding: 9px 15px;
    font-size: 15px;
}

.sell-avatar-btn:hover {
    /* background: transparent linear-gradient(84deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box; */
    /* color: #fff; */
}

.nft-list .curreny-image img {
    width: 20px;
    height: 20px;
}

.nft-list .item .time-img-wrap {
    position: relative;
}

.nft-list .item .time-wrap {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    background: #212329;
    padding: 10px;
    border-radius: 7px;
}

.nft-list .item .time-img-wrap.nftc .nft-img-link {
    background: #00000050;
}

.nft-list .item .time-img-wrap.nftc .nft-img-link img {
    filter: grayscale(1);
}

.nft-list .item .time-img-wrap.nftd .nft-img-link {
    background: #00000040;
}

.nft-list .item .time-img-wrap.nftd .nft-img-link img {
    filter: sepia(100%) hue-rotate(190deg) saturate(500%);
}

.nft-list .item .time-img-wrap.nftcd .nft-img-link {
    background: #00000060;
}

.nft-list .item .time-img-wrap.nftcd .nft-img-link img {
    filter: sepia(98%) saturate(876%) hue-rotate(143deg);
}

.nft-list .item .time-wrap .countdown-wrapper {
    font-size: 10px;
    padding: 0 2px;
    color: #fff;
    font-weight: 600;
}

.countdown-wrapper .daily {
    font-size: 13px;
    font-weight: 300;
}

.nft-list .item .time-wrap span {
    background-color: #fff;
    padding: 7px 15px;
    border-radius: 20px;
    color: #000;
    border: 1px solid #000;
}

.nft-list .item .name,
.nft-list .item .price {
    text-align: left;
}

.skelton-justify :first-child {
    justify-content: space-between;
}

.my-nfts .section-dark-bg {
    /* padding: 60px 0px; */
    /* min-height: 318px; */
    /* clip-path: polygon(1% 0, 100% 0%, 99% 100%, 0% 100%); */
    /* transform: rotate(1deg); */
    box-shadow: none;
    border-radius: 16px;
}

.sidebar-links li a:active,
.sidebar-links li a:focus {
    text-decoration: none;
}

.sidebar-links li a:focus-visible {
    outline: none;
}

.nft-list .item .name {
    margin-bottom: 15px;
    font-size: 14px;
    font-family: 'Neon';
    font-weight: 900;
}

.nft-list .item .name a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 45px;
    word-break: break-word;
    max-width: 265px;
}

.avatars-badges-img {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
}

.cards-wrapper ul li.badges-wrap .avatars-badges-img {
    width: 100px;
    height: 100px;
}

.avatars-badges-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.login-wrap {
    padding: 10px 15px 30px;
}

.login-wrap .intro {
    margin-bottom: 50px;
}

.login-wrap .card {
    cursor: pointer;
    padding: 25px 65px;
    background: #22232A;
    margin: 4px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    transition: all 0.3s;
}

.login-wrap .card:hover {
    box-shadow: 0px 0px 3px #2cfafe;
}

.login-wrap .card:hover .meta-tag h4 {
    color: #2cfafe;
}

.login-wrap .card .meta-tag {
    position: relative;
    color: #fff;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    text-align: start;
}

.login-wrap .card .meta-tag h4 {
    font-weight: bold;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgb(127, 136, 144);
    max-width: 300px;
}

.login-wrap .card .meta-tag .wallet-img {
    width: 80px;
    height: 80px;
    max-width: 100%;
    margin-right: 130px;
}

.login-wrap .card .meta-tag .wallet-img img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.points-table:first-child div::-webkit-scrollbar {
    width: 5px;
}

.notifications .rdt_Table .rdt_TableRow .rdt_TableCell span {
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    max-height: 25px;
    text-transform: capitalize;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.notifications .rdt_Table .rdt_TableRow .rdt_TableCell span ul,
.notifications .rdt_Table .rdt_TableRow .rdt_TableCell span ul li {
    background-color: transparent;
}

.notifications .rdt_Table .rdt_TableRow .rdt_TableCell span ul li {
    padding: 5px;
}

.page-header h2 {
    font-size: 30px;
    line-height: 40px;
}

.page-header nav {
    margin-top: 20px;
    margin-bottom: 30px;
    background: transparent linear-gradient(90deg, #283339 0%, #28333900 100%) 0% 0% no-repeat padding-box;
    padding: 12px 40px;
    border-radius: 2px;
}

.page-header nav .breadcrumb {
    margin-bottom: 0;
}

.page-header nav .breadcrumb .breadcrumb-item {
    font-family: "Montserrat";
    color: #fff;
    text-transform: capitalize;
}

.page-header nav .breadcrumb .breadcrumb-item.active {
    background: transparent;
    opacity: 0.5;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
    font-size: 20px;
    line-height: 25px;
    color: #fff;
}

/* success popup model design */
.success-popup .modal-dialog {
    max-width: 1100px;
}

.success-popup .modal-dialog .modal-content {
    background-color: #1554F0;
    position: relative;
    padding: 45px;
    border-radius: 25px;
}

.success-popup .modal-dialog .modal-content .close-btn {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #00000030;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    position: absolute;
    top: 15px;
    right: 15px;
}

.success-popup .modal-dialog .modal-content .modal-body h2 {
    font-family: "Montserrat";
    font-weight: 500;
    margin-bottom: 50px;
}

.success-popup .modal-dialog .modal-content .modal-body ul li {
    min-height: 380px;
    max-width: 300px;
    width: 30%;
    margin: 0 20px;
    background-color: #fff;
    padding: 40px 30px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.success-popup .modal-dialog .modal-content .modal-body ul li:first-child {
    margin-left: 0;
}

.success-popup .modal-dialog .modal-content .modal-body ul li:last-child {
    margin-right: 0;
}

.success-popup .modal-dialog .modal-content .modal-body ul li .image {
    width: 175px;
    height: 175px;
    margin: 0 auto 30px;
}

.success-popup .modal-dialog .modal-content .modal-body ul li .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.success-popup .modal-dialog .modal-content .modal-body ul li span {
    font-size: 22px;
    font-family: "Neon";
    font-weight: 900;
    color: #111115;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-height: 94px;
    word-break: break-all;
}

.congrats-modal .modal-dialog {
    max-width: 900px;
}

.congrats-modal h2 {
    font-size: 55px;
    font-weight: 900 !important;
    margin-bottom: 25px;
}

.congrats-modal .modal-dialog .modal-content .modal-body .btn-gradient-blue {
    padding: 20px 30px;
}

.congrats-modal .modal-dialog .modal-content .modal-body p {
    font-size: 20px;
    color: #fff;
    opacity: 0.6;
    margin-bottom: 100px;
}

.congrats-modal .modal-dialog .modal-content .modal-body {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.notifications .rdt_Table .rdt_TableRow .rdt_TableCell span ul,
.notifications .rdt_Table .rdt_TableRow .rdt_TableCell span ul li {
    background-color: transparent;
}

.notifications .rdt_Table .rdt_TableRow .rdt_TableCell span ul li {
    padding: 5px;
}

.page-header h2 {
    font-size: 30px;
    line-height: 40px;
}

.page-header nav {
    margin-top: 20px;
    margin-bottom: 30px;
    background: transparent linear-gradient(90deg, #283339 0%, #28333900 100%) 0% 0% no-repeat padding-box;
    padding: 12px 40px;
    border-radius: 2px;
}

.page-header nav .breadcrumb {
    margin-bottom: 0;
}

.page-header nav .breadcrumb .breadcrumb-item {
    font-family: "Montserrat";
    color: #fff;
}

.page-header nav .breadcrumb .breadcrumb-item.active {
    background: transparent;
    opacity: 0.5;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
    font-size: 20px;
    line-height: 25px;
    color: #fff;
}

.my-nft-sec {
    min-height: 312px;
}

.not-found {
    min-height: 215px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 18px;
}

.not-found p {
    font-size: 17px;
    margin-bottom: 10px;
    opacity: 0.6;
}

.not-found .main-btn-style {
    padding: 13px 25px;
    color: #000;
    clip-path: polygon(4% 5%, 100% 0, 100% 100%, 0 95%);
    border-radius: 19px 8px 10px 12px;
    border: 1px solid #2cfafe;
    font-weight: 500;
}

.not-found .main-btn-style:hover {
    color: #000;
    background-color: #2cfafe;
    transform: scale(1.02);
}

.avatars-badges .not-found {
    background-color: #323239;
}

/* success popup model design */
.success-popup .modal-dialog {
    max-width: 1100px;
}

.success-popup .modal-dialog .modal-content,
.social-share ul {
    background-color: #1554F0;
    position: relative;
    padding: 45px;
    border-radius: 25px;
}

.social-share ul {
    min-height: 55vh;
}

.success-popup .modal-dialog .modal-content .close-btn {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #00000030;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    position: absolute;
    top: 15px;
    right: 15px;
}

.success-popup .modal-dialog .modal-content .modal-body h2,
.social-share h2 {
    font-family: "Montserrat";
    font-weight: 500;
    margin-bottom: 40px;
}

.success-popup .modal-dialog .modal-content .modal-body ul li,
.social-share ul li {
    min-height: 380px;
    max-width: 300px;
    width: 30%;
    margin: 0 20px;
    background-color: #fff;
    padding: 30px 20px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.success-popup .modal-dialog .modal-content .modal-body ul li:first-child,
.social-share ul li:first-child {
    margin-left: 0;
}

.success-popup .modal-dialog .modal-content .modal-body ul li:last-child,
.social-share ul li:last-child {
    margin-right: 0;
}

.success-popup .modal-dialog .modal-content .modal-body ul li .image,
.social-share ul li .image {
    width: 175px;
    height: 175px;
    margin: 0 auto 30px;
}

.success-popup .modal-dialog .modal-content .modal-body ul li .image img,
.social-share ul li .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.success-popup .modal-dialog .modal-content .modal-body ul li span,
.social-share ul li {
    font-size: 22px;
    font-family: "Neon";
    font-weight: 900;
    color: #111115;
    text-transform: uppercase;
}

.congrats-modal .modal-dialog {
    max-width: 900px;
}

.congrats-modal h2 {
    font-size: 55px;
    font-weight: 900 !important;
    margin-bottom: 25px;
}

.congrats-modal .modal-dialog .modal-content .modal-body .btn-gradient-blue {
    padding: 20px 30px;
}

.congrats-modal .modal-dialog .modal-content .modal-body p {
    font-size: 20px;
    color: #fff;
    opacity: 0.6;
    margin-bottom: 100px;
}

.congrats-modal .modal-dialog .modal-content .modal-body {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}


/* social links in notifiactions styles */
.social-links li button {
    width: 50px;
    height: 50px;
    font-size: 23px;
    background-color: #00000070;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 10px;
    overflow: hidden;
}

/* .social-links a:first-child li{margin-left: 0;}
.social-links a:last-child li{margin-right: 0;} */
.social-links li.fa button {
    color: #1877f2;
}

.social-links li.fa button:hover {
    background-color: #1877f2;
    color: #fff;
    transition: .3s ease-in-out;
}

.social-links li.me button {
    color: #02b875;
}

.social-links li.me button:hover {
    background-color: #02b875;
    color: #fff;
    transition: .3s ease-in-out;
}

.social-links li.in button {
    color: #c32aa3;
}

.social-links li.in button:hover {
    background-color: #c32aa3;
    color: #fff;
    transition: .3s ease-in-out;
}

.social-links li.li button {
    color: #0077B5;
}

.social-links li.yt button {
    color: #ff0505;
}

.social-links li.li button:hover {
    background-color: #0077B5;
    color: #fff;
    transition: .3s ease-in-out;
}

.social-links li.yt button:hover {
    background-color: #df0404;
    color: #fff;
    transition: .3s ease-in-out;
}

.social-links li.ds button {
    color: #7289DA;
}

.social-links li.ds button:hover {
    background-color: #7289DA;
    color: #fff;
    transition: .3s ease-in-out;
}

.social-links li.re button {
    color: #ff4500;
}

.social-links li.re button:hover {
    background-color: #ff4500;
    color: #fff;
    transition: .3s ease-in-out;
}

.social-links li.gi button {
    color: #fff;
}

.social-links li.gi button:hover {
    background-color: #fff;
    color: #000;
    transition: .3s ease-in-out;
}

.social-links li.te button {
    color: #08c;
}

.social-links li.te button:hover {
    background-color: #08c;
    color: #fff;
    transition: .3s ease-in-out;
}

.social-links li.yo button {
    color: #FF2D2D;
}

.social-links li.yo button:hover {
    background-color: #FF2D2D;
    color: #fff;
    transition: .3s ease-in-out;
}

.social-links li.tw button {
    color: #0995DE;
}

.social-links li.tw button:hover {
    background-color: #0995DE;
    color: #fff;
    transition: .3s ease-in-out;
}

.social-links li.ln button {
    color: #0A66C2;
}

.social-links li.ln button:hover {
    background-color: #0A66C2;
    color: #fff;
    transition: .3s ease-in-out;
}

.custom-modal .modal-dialog {
    max-width: 600px;
}

.success-popup .points-detail-row,
.social-share .points-detail-row {
    background-color: #323239;
    padding: 15px 40px;
    border-radius: 30px;
}

.social-share .points-detail-row {
    position: relative;
    width: 75%;
    margin: -32px auto 0;
    z-index: 1;
}

.social-share .points-detail-row strong {
    color: #2cfafe;
    text-transform: capitalize;
}

.success-popup .points-detail-row {
    background-color: #323239;
    padding: 15px 40px;
    border-radius: 30px;
}

.success-popup .points-detail-row strong {
    color: #2cfafe;
}

.create-nft .modal-dialog {
    max-width: 600px;
}

.create-nft .modal-content {
    box-shadow: 0 0 10px #2cfafe;
    border-radius: 12px;
}

.create-nft .modal-content .h4.modal-title {
    font-size: 28px;
    font-weight: 700;
    margin-right: 40px;
}

.create-nft .modal-content .connect-btn {
    height: auto;
    border: none;
    margin: 0;
    outline: none;
    position: absolute;
    top: 0px;
    right: 0px;
    background: #2cfafe;
    color: #000;
    width: 35px;
    height: 35px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-nft .modal-content .modal-main-body {
    padding-bottom: 10px;
    border-bottom: 2px solid #494a4c;
}

.create-nft .modal-content .curr-heading {
    font-size: 16px;
    margin-bottom: 8px;
}

.create-nft .curr-chain {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: initial;
    border: 1px solid #2cfafe;
    transition: all .4s;
}

.create-nft .curr-chain.active {
    background-color: #2cfafe;
    box-shadow: 0 0 6px 0 #2cfafe;
}

.create-nft .curr-chain.active svg {
    fill: #000;
}

.create-nft .curr-chain svg {
    width: 25px;
    height: 25px;
    fill: #2cfafe;
}

.create-nft .curr-chain:hover {
    background-color: #2cfafe;
}

.create-nft .curr-chain:hover svg {
    fill: #000;
}

.create-nft .modal-content input {
    margin-bottom: 20px;
    background-color: initial;
    color: #fff;
    border: 2px solid #494a4c;
    padding: 11px 15px;
}

.create-nft .modal-content input:focus {
    box-shadow: none;
}

.profile-modal .modal-content .modal-header {
    position: relative;
    height: 70px;
    margin-bottom: 35px;
}

.profile-modal .modal-content .modal-header .image {
    position: absolute;
    top: 22px;
}

.profile-modal .modal-content .modal-header .image .icon {
    width: 70px;
    height: 70px;
    background: green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.profile-modal .modal-content .connect-btn {
    position: absolute;
    right: 12px;
    top: 12px;
    border: none;
    height: 30px;
    width: 30px;
    margin: 0;
    outline: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
}

.profile-modal .modal-content .modal-main-body {
    position: relative;
}

.profile-modal .modal-content .modal-main-body::after {
    content: "";
    position: absolute;
    width: 70%;
    height: 1px;
    background-color: #00abf0;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.profile-modal .modal-content .modal-main-body p strong {
    color: #2cfafe;
}

.profile-modal .modal-content .main-btn-style {
    text-transform: uppercase;
    font-size: 16px;
}

.social-share {
    max-width: 1200px;
    margin: 0 auto;
}

.share-svg {
    width: 17px;
}

.field-wrapper p,
.contest-card .wallet-address-copy p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
}

.facebook svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa,
.twitter svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa,
.instagram svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
    overflow: hidden;
}

.connect-twitter-model {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    background: #323239;
    padding: 40px 30px;
    border-radius: 20px;
}

.success-box h3 {
    font-size: 20px;
    line-height: 30px;
}

.success-box .main-btn-style span {
    font-size: 16px;
}


.nft-type-wrap {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    left: 5px;
    color: #fff;
    z-index: 1;
    font-size: 15px;
    font-weight: 600;
    padding: 5px 8px;
    background-color: #000;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-save {
    padding: 7px 20px;
    border-width: 2px;
    font-size: 15px;
}

.fb-model .custom-select__menu-list,
.insta-model .custom-select__menu-list {
    max-height: 120px !important;
}

/* .select-save */

.form-message {
    color: #ff0000;
}

.delete:hover {
    color: red;
}

.edit:hover {
    color: #fec041;
}

.notice .list-none {
    list-style-type: none;
}

.message-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.message-container::-webkit-scrollbar {
    display: none;
}

.center-col {
    flex: 1;
    padding-top: 20px;
    background: #f8ef96 0% 0% no-repeat padding-box;
    overflow-y: auto;
}

.custom-select__control {
    height: 45px;
    background: #1B1A21 !important;
    color: #fff;
    border: 2px solid #323239 !important;
    outline: none;
    box-shadow: none !important;
    text-align: initial;
    color: #fff;
    opacity: 0.7;
    font-weight: 600;
}

.custom-select__menu {
    position: relative;
    z-index: 2299999 !important;
}

.custom-select__option {
    background-color: #000 !important;
    cursor: pointer !important;
    text-align: left;
    border-bottom: 1px solid #eeeeee20;
    font-size: 14px !important;
    font-weight: 600;
}

.custom-select__option:hover {
    background-color: #2cfafe !important;
    color: #000;
}

.custom-select__menu {
    /* position: unset !important; */
}

.custom-select__menu-list {
    padding: 0 !important;
}

.custom-select__single-value {
    color: #fff !important;
}

.date-picker {
    width: 20%;
    display: inline;
    background: rgba(30, 30, 36, 1) !important;
    color: #fff;
}

.calendarWrap {
    display: inline-block;
    position: relative;
    width: 100%;
}

.calendarElement {
    position: absolute;
    left: 6%;
    transform: translateX(-10%);
    top: 40px;
    border: 1px solid #ccc;
    z-index: 999;
}

.rs-calendar-table {
    color: #000 !important;
}

.rs-picker-daterange-menu {
    /* left: 52% !important;
    transform: translateX(-50%); */
    right: 65px !important;
    left: auto !important;
    width: 600px;
    max-width: 100%;
    overflow: auto;
    margin-top: 10px !important;
}

.text-color {
    color: #000;
}

/* level achievement accordion start */

/* .accordion-header{background-color: transparent;}
.accordion-body{background: transparent;} */
.level-achievement-accordion .accordion-item:last-of-type .accordion-button.collapsed {
    background: #111115;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.level-achievement-accordion .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #111115;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: none;
}

.level-achievement-accordion {
    margin-bottom: 20px;
}

.level-achievement-accordion .level-achievement-accordion .accordion-item {
    background-color: transparent;
}

.level-achievement-accordion .accordion-item {
    border: none;
}

.level-achievement-accordion .accordion-body {
    background-color: #111115;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.accordion-button:focus {
    border: none;
    box-shadow: none;
}

.avatar-imagess img {
    width: 70px;
    margin: 0px 20px;
    max-height: 80px;
}

.achievement-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.level-achievement-accordion .accordion-body img {
    width: 100px;
    margin-bottom: 10px;
}

.level-achievement-accordion .accordion-body ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.accordion-button::after {
    background-image: url('./assets/images/chiveron.svg');
}

.achievement-content .others ul li span {
    background: none !important;
    text-align: start !important;
}

.achievement-content .others ul li {
    line-height: 30px;
}

/* level acheivement accoridon end */
/* .area-of-achievements{background-color: #000000;} */
.level-of-achievements {
    margin-bottom: 30px;
}

/* .modal-content ul li div img{width: 225px;} */
.level-of-achievements img {
    width: 80px;
}

.level-of-achievements span {
    padding-left: 20px;
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.others ul li span {
    background: none !important;
    font-size: 14px;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.level-images ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.level-images ul img {
    width: 120px;
    margin-bottom: 10px;
}

.area-of-achievements {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.level-of-achievements h3 {
    margin-bottom: 10px;
    font-size: 22px;
}

.level-of-achievements .you-will-get {
    padding-top: 60px;
}

.others p {
    margin-bottom: 10px;
    word-wrap: break-word;
}

.others p span {
    color: #fff !important;
}

.spinner-border {
    width: 2rem;
    height: 2rem;
}

.spinner-border-xl {
    width: 6rem;
    height: 6rem;
}

.section-dark-bg {
    background: #323239;
}


.page-title h2 {
    padding: 20px 0px;
}

.video-react-video {
    width: 100% !important;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.ant-upload-list-item-info {
    margin-bottom: 20px;
}

.ant-tooltip-inner {
    text-align: center;
}

.video-react-button {
    background: transparent linear-gradient(72deg, #fd24d8 0%, #fed130 100%) 0% 0% no-repeat padding-box;
    margin-bottom: 20px;
    border-radius: 5px;
}

.form-control.editor {
    margin-top: 30px;
}

/* .video-react-control-bar{display: flex;align-items: center;justify-content: center;} */
.video-react-big-play-button {
    display: none;
}

.video-react-current-time-display {
    margin-left: 30px;
}

/* .section-dark-bg .heading h4 {
    text-align: center;
} */

iframe {
    display: none !important;
}

.main-content-area .react-select-dd select {
    width: 100%;
    height: 45px;
    padding: 2px 8px;
}

#edit-avatr-modal img {
    max-width: -webkit-fill-available;
}

.post-content-wrapper {
    display: flex;
    width: 75%;
    float: left;
    padding: 0 12px;
}

.post-sidebar-secondray ul li {
    margin: 10px 0;
}

.post-main-content .post-details {
    background-color: #333134;
    padding: 25px 0;
    margin-bottom: 30px;
}

.post-details .title h5 {
    border-bottom: 2px solid #53535f;
    padding-bottom: 7px;
    margin-bottom: 15px;
}

.padding25 {
    padding: 0 25px;
}

.post-content-wrapper .post-sidebar-secondray {
    width: 33.3%;
}

.post-content-wrapper .post-main-content {
    width: 66.3%;
}

.auther-views {
    margin-bottom: 15px;
}

.auther-views .auther img {
    border-radius: 50%;
    width: 50px;
}

.auther-views .auther .name a:hover {
    text-decoration: underline;
}

.auther-views .views {
    font-size: 14px;
    font-weight: 600;
}

.post-details .post-img {
    margin-bottom: 15px;
}

.post-details .questions-container {
    margin-bottom: 15px;
}

.post-details .questions-container .answer-wrapper {
    padding: 10px;
    border: 1px solid #ebebeb;
}

.post-details .questions-container .answer-wrapper:hover {
    box-shadow: 1px 1px 9px #ebebeb;
}

.correct-container .correct-content {
    box-shadow: 1px 1px 9px #ebebeb;
    padding-bottom: 20px;
    margin-bottom: 15px;
}

.correct-container .correct-content h4 {
    background-color: rgba(0, 255, 146, .15);
    padding: 16px 30px;
}

.correct-container .correct-content span,
.correct-container .correct-content p {
    padding: 16px 30px;
}

.wrong-container .wrong-content {
    box-shadow: 1px 1px 9px #ebebeb;
    padding-bottom: 20px;
    margin-bottom: 15px;
}

.wrong-container .wrong-content h4 {
    background-color: rgba(255, 0, 25, .15);
    padding: 16px 30px;
}

.wrong-container .wrong-content span,
.wrong-container .wrong-content p {
    padding: 16px 30px;
}

.score-container {
    margin-bottom: 30px;
}

.score-container .score-content {
    box-shadow: 1px 1px 9px #ebebeb;
    padding: 20px 20px;
}

.score-container .score-content p {
    font-size: 14px;
}

.score-container .score-number-container {
    background-image: url("../src//assets/images/score-bg.jpg");
    text-align: center;
    padding: 15% 0;
    border-radius: 10px;
    margin-top: 20px;
}

.post-sidebar {
    width: 25%;
    float: right;
    padding: 0 12px;
}

.trending-posts .post {
    position: relative;
    /* margin-bottom: 20px; */
}

.trending-posts .post img {
    width: 100%;
}

.trending-posts .post p {
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
}

.trending-posts .post:not(:last-child) {
    margin-bottom: 30px;
}

/* .trending-posts a{display: block; margin-bottom: 20px;} */
.trending-posts .post .post-number {
    width: 30px;
    height: 30px;
    background-color: #dc27da;
    position: absolute;
    border-radius: 50%;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-details .tags {
    padding-top: 10px;
    margin-bottom: 30px;
}

.post-details .tags a {
    margin-bottom: 0;
    border: 2px solid #53535f;
    padding: 5px 9px;
    font-size: 12px;
    font-weight: 600;
}

.social-btns .facebook {
    background-color: #3a579a !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-btns .twitter {
    background-color: #00abf0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-btns div {
    width: 33.3%;
}

.custom-post-container {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 12px;
}

.point-btn {
    padding: 0.3rem;
    background-color: #333134;
    border: 1px solid;
    border-color: #757575;
}

.rate-btn {
    color: #757575;
    width: 1.5rem;
    height: 1.5rem;
}

.like-count {
    padding: 0.45rem 0.6rem 0.55rem 0.6rem;
    background-color: #333134;
    border: 1px solid;
    border-color: #757575;
}

.redirectMetamask a {
    color: blue;
}

.comments-container {
    padding: 32px;
    background-color: #333134;
}

.comments-container h5 {
    text-transform: capitalize;
    margin-bottom: 20px;
}

.comment-form {
    padding: 20px;
    background-color: #53535f;
}

.comment-form-comment textarea {
    background-color: #333134;
    border-color: #333134;
    padding-left: 15px;
}

.comment-form-comment textarea:focus-visible {
    outline: none;
}

.comment-form-comment .submit-post .btn {
    color: #fff;
    background-color: #353432;
    padding: 3px 5px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    border: none;
    width: 120px;
}

.comment-form-comment .submit-post .btn:hover {
    box-shadow: -3px 3px 8px 0 rgb(21 21 24 / 34%);
}

.comment-list .comment.comment-author {
    padding: 10px 0;
    border-bottom: 1px solid #f4f3f3;
}

.comment-list .replies .comment:last-child {
    border-bottom: 0;
}

.comment-body .comment-user-img img {
    border-radius: 50%;
    width: 40px;
}

.comment-body .comment-details {
    margin-left: 25px;
    width: 100%;
}

.comment-body .comment-details .user-written-comment {
    color: #a3a3a3;
}

.comment-body .date-edit a {
    font-size: 12px;
    color: #a3a3a3 !important;
}

.comment-body .date-edit a:hover {
    text-decoration: underline;
}

.comment-body .date-edit a:hover,
.comment-body .date-edit .edit-comment-btn:hover {
    text-decoration: underline;
}

.comment-body .date-edit .edit-comment-btn {
    font-size: 12px;
    color: #a3a3a3 !important;
    background-color: #53535f;
}


.comment-body .comment-reply-btn {
    background-color: #53535f;
    color: #fff;
    font-size: 10px;
    padding: 1px 10px;
}

.comment-body .comment-reply-btn:hover {
    text-decoration: underline;
}

.comment-body .user-comment-name:hover {
    text-decoration: underline;
}

.comment-list .children.comment.comment-author {
    border-bottom: 0 !important;
    padding-left: 60px;
}

.comment-list .children.comment.comment-author .comment-body {
    border-top: 1px solid #f4f3f3;
    padding-top: 10px;
}

.children .comment-body .comment-user-img img {
    width: 35px;
}

.comment-list .replies {
    border-bottom: 0 !important;
    padding-left: 60px;
}

.comment-list .replies .comment-body {
    border-top: 1px solid #f4f3f3;
    padding-top: 10px;
}

.replies .comment-body .comment-user-img img {
    width: 35px;
}

.load-more-btn {
    font-size: 12px;
    color: #a3a3a3 !important;
    background-color: #53535f;
}

/* 404 page design */
.notfound-container {
    height: 75vh;
    margin-top: -35px;
}

.main-title {
    color: white;
    font-weight: 600;
    font-size: 80px;
    line-height: 70px;
    text-align: center;
}

.subtitle {
    color: white;
    text-align: center;
}

.logo {
    display: flex;
    align-items: center;
}

.astronaut {
    position: absolute;
    top: 30%;
    right: 10%;
    height: 30vmin;
    animation: floating 3s infinite ease-in-out;
}

@keyframes floating {
    from {
        transform: translateY(0px);
    }

    65% {
        transform: translateY(15px);
    }

    to {
        transform: translateY(-0px);
    }
}

.button__badge {
    background-color: #f95858;
    border-radius: 2px;
    color: white;
    padding: 2px;
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-100%, -100%);
    max-width: 20px;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.messageButton {
    position: relative;
}

.button__badge_empty {
    display: none;
}

.goback-btn {
    width: fit-content;
    margin: 30px auto 0px;
}

.goback-btn:hover {
    transform: scale(1.1);
    transition: all ease-in-out 0.75s;
}

.goback-btn:focus {
    box-shadow: unset !important;
}

.cards-wrapper ul li {
    width: 18%;
    margin: 8px;
    position: relative;
}

.cards-wrapper ul li.badges-wrap {
    width: 23%;
}

.cards-inner-wrapper {
    background: transparent linear-gradient(180deg, #1554F0 0%, #21FAFF 100%) 0% 0% no-repeat padding-box;
    padding: 25px;
    min-height: 300px;
    border-radius: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.cards-wrapper ul li.badges-wrap .cards-inner-wrapper {
    flex-direction: row;
    min-height: 185px;
}

.cards-inner-wrapper strong {
    font-weight: 900;
    text-transform: capitalize;
    font-size: 18px;
}

.cards-wrapper ul li.badges-wrap .cards-inner-wrapper strong {
    display: block;
    margin-bottom: 10px;
}

.cards-inner-button {
    background: #fff;
    color: #1E1E24;
    border-radius: 6px;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    transition: all 0.3s;
}

.cards-inner-button:hover {
    background: #1554F0;
    color: #fff;
}

.cards-wrapper ul li.badges-wrap .cards-inner-button {
    padding: 9px 15px;
}

.cards-wrapper ul li.badges-wrap .cards-inner-button:focus {
    box-shadow: none;
}

/* Landing Page*/

.landing-wrap {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 15px;
}

.btn-style-2 {
    padding: 12px 25px;
    font-family: "Montserrat";
    text-transform: uppercase;
    transition: all 0.3s;
    outline: 0;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    color: #000;
    border: none;
    background-color: #2cfafe;
}

.btn-style-2.login {
    background-color: #fff;
}

.btn-style-2:hover {
    background-color: #1554f0;
    color: #fff;
}

.title-heading {
    font-size: 42px;
}

.landing-page .footer-sec .footer-sec-padding {
    margin-top: 0;
}

/* Landing Page end*/
.activity-popup-page {
    margin-top: 160px;
}

.activity-popup .modal-dialog {
    max-width: 620px;
}

.activity-popup .modal-dialog .modal-content,
.activity-popup-page {
    background-color: #323239;
    position: relative;
    border-radius: 12px;
}

.activity-popup .popup-content {
    min-height: 400px;
    justify-content: end;
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 3;
    padding: 200px 40px 30px 40px;
    text-align: center;
}

.activity-popup .popup-lotties .lottie {
    position: absolute;
    top: -40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.activity-popup .popup-lotties .lottie-1 {
    width: 620px;
    z-index: 1;
}

.activity-popup .popup-lotties .lottie-2 {
    width: 500px;
    z-index: 2;
}

.activity-popup .popup-lotties .lottie-2.lottie-cards {
    width: 300px;
}

.activity-popup .popup-content h2 {
    color: #fff;
    font-size: 38px;
    line-height: 45px;
    font-family: "Neon";
    margin-bottom: 25px;
}

.activity-popup .popup-content h3 {
    color: #fff;
    font-size: 28px;
    line-height: 40px;
    font-family: "Neon";
}

.activity-popup .popup-content p {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    opacity: 0.66;
    margin-bottom: 25px;
    padding: 0 35px;
}

.activity-popup .popup-content .popup-btn {
    font-family: "Neon";
    font-size: 16px;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: 900;
    cursor: pointer;
    color: #2cfafe;
    border: 2px solid #2cfafe;
    background: transparent;
    border-radius: 7px;
    padding: 10px 25px;
    transition: all 0.3s;
}

.activity-popup .popup-content .popup-btn:hover {
    color: #000;
    background: #2cfafe;
}

.activity-popup .popup-content .popup-btn-close {
    background: transparent;
    border: none;
    color: #fff;
    opacity: 0.7;
    font-size: 15px;
    padding: 3px;
}

.activity-popup .popup-logo {
    position: absolute;
    width: 180px;
    height: 180px;
    left: 50%;
    top: -84px;
    transform: translateX(-50%);
    z-index: 2;
}

.activity-popup .rewards-anime {
    position: absolute;
    width: 230px;
    height: 230px;
    left: 50%;
    top: -120px;
    transform: translateX(-50%);
    z-index: 2;
}

.rdt_Table {
    background: transparent !important;
}

.rdt_TableHead {
    margin-bottom: 15px;
}

.rdt_TableHeadRow {
    border-bottom: none !important;
}

.rdt_TableRow {
    cursor: default !important;
    margin: 6px 0;
    border-radius: 10px;
    color: #fff !important;
}

.not-slider .item {
    width: 25%;
}


/* .rdt_TableRow.gqdgZx{background-color: #1E1E24 !important;}
.rdt_TableRow.fxHPFs{background-color: #2b2b33 !important;} */
.flex-1 {
    flex: 1;
}

.contest-urls .contest-mini-btn {
    width: 52px;
    height: 52px;
    padding: 0;
    border-radius: 9px;
    font-size: 30px;
    border: none;
}

/* .contest-urls .contest-mini-btn:focus{ } */
.contest-urls .contest-mini-btn.plus {
    background-color: #2cfafe;
    color: #000;
}

.contest-urls .contest-mini-btn.minus {
    background-color: #00000045;
    color: #fff;
}


/* ================== media quriesssssss =================== */
@media (max-width: 1720px) {
    .cards-wrapper ul li.badges-wrap .cards-inner-wrapper {
        min-height: 160px;
    }

    .title-heading {
        font-size: 36px;
    }

    .next-rewards-list ul li .points {
        font-size: 20px;
    }

    .next-rewards-list ul li {
        padding: 14px 30px;
    }

    .claim-rewards .claim-rewards-header {
        max-width: 470px;
    }

    .claim-rewards .treasure-image {
        width: 230px;
        height: 230px;
    }

    .coinmarket-rewards .image-box {
        margin-left: 100px;
        width: 270px;
        height: 160px;
    }

    .coinmarket-rewards .image-box span:first-child {
        font-size: 18px;
    }

    .coinmarket-rewards .image-box span:last-child {
        font-size: 50px;
        line-height: 60px;
    }

    .not-found {
        min-height: 210px;
    }

    .about-details p {
        font-size: 13px;
    }

    .about-details h5 {
        font-size: 18px;
    }

    .main-dashboard .sidebar .sidebar-wrap .sidebar-links ul {
        padding-right: 20px;
    }

    .daily-task-wrapper {
        min-height: 290px;
    }

    .form-modal .form-control {
        border-radius: 12px;
    }

    .sidebar .logo .name span {
        font-size: 12px;
    }

    body {
        font-size: 16px;
    }

    h2 {
        font-size: 34px;
        line-height: 45px;
    }

    h3 {
        font-size: 22px;
        line-height: 32px;
    }

    h4 {
        font-size: 20px;
        line-height: 28px;
    }

    h5 {
        font-size: 18px;
        line-height: 26px;
    }

    .my-nfts .nft-list ul {
        justify-content: start;
    }

    .my-nfts .nft-list ul li {
        width: 33%;
    }

    .game-list ul li.treasury-box {
        margin-top: 90px;
    }

    .game-purchase-wrapper {
        padding: 20px;
    }

    .section-dark-bg {
        padding: 20px;
    }

    .contest-points-bg,
    .daily-points-bg,
    .utility-points-bg,
    .social-points-bg {
        padding: 30px 12px;
    }

    .main-dashboard .sidebar {
        max-width: 280px;
    }

    .sidebar .logo {
        margin-bottom: 35px;
        padding: 20px 0 0;
    }

    .sidebar .logo .image {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .sidebar-links li a .icon {
        margin-right: 15px;
    }

    .sidebar-links li a {
        font-size: 14px;
        line-height: 24px;
        padding: 10px 15px;
    }

    .sidebar-links li.static {
        margin-top: 5px;
    }

    .collectibles-list ul li {
        width: 48%;
    }

    .notifications {
        padding-right: 20px;
    }

    .notifications .list {
        padding-right: 20px;
        /* background: #32323940 ; */
    }

    .notifications li {
        padding: 12px 20px;
        font-size: 15px;
    }

    .notice {
        padding: 25px;
    }

    .user-stats ul li:nth-child(2),
    .user-stats ul li:last-child {
        padding-left: 20px;
    }

    .section-dark-bg .heading,
    .section.heading {
        margin-bottom: 20px;
    }

    .badges-list ul li {
        /* width: 33%; */
    }

    .activities ul li {
        /* padding: 20px; */
    }
    
    .nft-list ul li .image {
        height: 150px;
    }

    .image .sale {
        font-size: 12px;
        padding: 4px 10px;
    }

    .nft-list ul li .name {
        margin-bottom: 10px;
    }

    .image .timer {
        padding: 5px 10px;
        font-size: 12px;
    }

    .badges .item .image {
        width: 120px;
    }

    .badges .item .image-bg {
        padding: 20px;
    }

    .avatar-badge .avatar {
        margin-right: 30px;
    }

    .avatar-badge .image {
        height: 100px;
        width: 100px;
    }

    .main-content-area {
        max-width: calc(100% - 280px);
    }

    .main-content-area .page-title h2 {
        font-size: 32px;
        line-height: 42px;
    }
}

@media (max-width: 1599px) {
    .not-slider .item .nft-image .nft-img-link {
        height: 200px;
    }

    .activity-popup .popup-content h3 {
        font-size: 24px;
        line-height: 35px
    }

    .activity-popup .popup-content h2 {
        font-size: 32px;
        line-height: 42px
    }

    .daily-task-wrapper.achieved .lotties-wrap .claimed-lottie-2 {
        width: 145px;
        height: 145px;
    }

    .progress-stats .progress-tasks .list ul li {
        min-height: 120px;
    }

    .progress-stats .progress-tasks .list ul li {
        width: 23%;
        padding: 17px;
    }

    .progress-stats .progress-tasks .list ul li .task-name,
    .progress-stats .progress-tasks .list ul li .percentage {
        font-size: 17px;
    }

    .cards-wrapper ul li.badges-wrap {
        width: 31%;
    }

    .cards-wrapper ul li.badges-wrap .avatars-badges-img {
        width: 90px;
        height: 90px;
    }

    .cards-wrapper ul li.badges-wrap .cards-inner-wrapper {
        padding: 18px;
    }

    .cards-inner-wrapper strong {
        font-size: 17px;
    }

    .cards-inner-button {
        font-size: 13px;
    }

    .cards-wrapper ul li {
        width: 23%;
    }

    .next-rewards-list ul li .claimed-lottie-1 {
        width: 22px;
        height: 22px;
    }

    .tresure-week .claimed .claim-status strong,
    .tresure-week .disable .claim-status strong {
        font-size: 14px;
    }

    .tresure-week .able .claim-status p {
        margin-bottom: 0;
    }

    .next-rewards-list ul li .claimed-lottie-2 {
        width: 100px;
        height: 100px;
    }

    .tresure-week .able .claim-status h6 .countdown-container .countdown-wrapper .daily,
    .tresure-week .able .claim-status h6 {
        font-size: 15px;
    }

    .next-rewards-list ul li {
        min-height: 135px;
    }

    .next-rewards-list ul li .image {
        width: 70px;
        height: 85px;
    }

    .platform-wallet-address {
        font-size: 15px;
    }

    .stats-sec .chart {
        height: 300px;
    }

    .title-heading {
        font-size: 32px;
    }

    .social-sharing button {
        font-size: 16px;
        padding: 8px 16px;
    }

    .promotion-modal .modal-content .content h2 {
        margin-bottom: 16px;
        font-size: 27px;
        line-height: 37px;
    }


    .setting-wrapper .personal-info .information form .btn-primary,
    .contest-btn {
        clip-path: polygon(2% 4%, 100% 0, 100% 100%, 0 97%);
        border-radius: 20px 8px 8px 12px;
    }

    .collection-list ul li .banner-img {
        width: 140px;
        height: 140px;
        margin-bottom: 50px;
    }

    .collection-list ul li .collection-text h2 {
        font-size: 12px;
    }

    .user-stats ul li span {
        font-size: 12px;
    }

    .user-stats ul li h4 {
        font-size: 15px;
    }

    .user-stats ul li {
        min-height: 60px;
    }

    .user-info .user-about .image {
        margin-bottom: 8px;
    }

    .about-details h5 {
        font-size: 16px;
    }

    .next-rewards-list ul li {
        padding: 10px 25px;
    }

    .next-rewards-list ul li .day {
        font-size: 15px;
    }

    .setting-wrapper .personal-info .information form label {
        font-size: 16px;
        margin-bottom: 4px;
    }

    .setting-wrapper .personal-info .information form .form-control {
        padding: 5px 20px;
        font-size: 16px;
        height: 55px;
    }

    .setting-wrapper .personal-info .information .image {
        margin-right: 40px;
        width: 170px;
        height: 170px;
    }

    .not-found .main-btn-style {
        padding: 10px 20px;
    }

    .notifications-model .notifications-model-content {
        max-width: 1150px;
        padding: 30px;
    }

    /* .notifications-model .notifications-model-content .left-content .avatar-img{width: 250px; height: 250px; margin-bottom: 20px;} */
    .notifications-model .notifications-model-content .right-content th,
    .notifications-model .notifications-model-content .right-content td {
        padding: 15px;
    }

    .user-info .user-about .image {
        width: 85px;
        height: 85px;
    }

    .buyers-rewards .item .overview {
        padding-right: 100px;
    }

    .login-wrap .card .meta-tag .wallet-img {
        margin-right: 80px;
    }

    .collection-list ul li .image {
        width: 55px;
        height: 55px;
        bottom: -25px;
        left: 25px;
    }

    .badges-list ul li {
        width: 28%;
    }

    .daily-task-wrapper .image {
        width: 85px;
        height: 85px;
    }
}

@media (min-width: 1399px) {

    .nft-sec .sc-bczRLJ {
        /* padding: 60px 0px; */
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 1399px) {
    .not-slider .item .nft-image .nft-img-link {
        height: 155px;
    }

    .heading h4,
    .nft-list .item .name a {
        font-size: 15px;
    }

    .item .nft-image {
        margin-left: 12px;
    }

    .activity-popup .popup-content h3 {
        font-size: 22px;
        line-height: 32px;
    }

    .activity-popup .popup-content h2 {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 20px;
    }

    .activity-popup .popup-content p {
        font-size: 18px;
        line-height: 26px;
    }

    .activity-popup .rewards-anime {
        width: 200px;
        height: 200px;
        top: -90px;
    }

    .heading h4 {
        font-size: 15px;
    }

    .messageButton {
        font-size: 15px;
        padding: 4px 8px;
    }

    .progress-stats .progress-tasks .list ul li {
        min-height: 110px;
    }

    .mini-progress-stats .progress-stats .bar-width {
        width: 90px;
    }

    .progress-stats .progress-tasks .list ul li .task-name,
    .progress-stats .progress-tasks .list ul li .percentage {
        font-size: 15px;
    }

    .claim-rewards .claim-rewards-header h3 {
        margin-bottom: 10px;
    }

    .daily-task-wrapper {
        min-height: 270px;
    }

    .cards-wrapper ul li.badges-wrap .cards-inner-button {
        padding: 8px 12px;
        font-size: 12px;
    }

    .avatars-badges-img {
        width: 105px;
        height: 105px;
    }

    .cards-inner-button {
        padding: 8px 18px;
    }

    .cards-inner-wrapper {
        padding: 24px 20px;
        min-height: 260px;
    }

    .claim-rewards .time-img {
        width: 120px;
        height: 130px;
        top: -10px;
        font-size: 18px;
    }

    .claim-rewards .time-title strong {
        margin-bottom: 3px;
    }

    .claim-rewards .time-count {
        font-size: 18px;
    }

    .claim-rewards .time-title {
        max-width: 190px;
        right: 55px;
        padding: 9px 25px;
    }

    .fb-model .modal-title {
        font-size: 20px;
    }

    .sell-avatar-btn {
        padding: 7px 13px;
        font-weight: 500;
    }

    .activity-popup .popup-content p {
        font-size: 20px;
        line-height: 28px;
    }

    .activity-popup .popup-content .popup-btn {
        font-size: 15px;
        padding: 9px 19px;
    }

    .activity-popup .popup-lotties .lottie-1 {
        width: 560px;
    }

    .activity-popup .popup-lotties .lottie-2 {
        width: 450px;
    }

    .activity-popup .popup-lotties .lottie-2.lottie-cards {
        width: 270px;
    }

    .activity-popup .popup-content {
        min-height: 350px;
        padding-top: 180px;
    }

    .all-notifications {
        max-height: 70vh;
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    * {
        scrollbar-width: 6px;
    }

    .btn-style-2 {
        font-size: 15px;
    }

    .title-heading {
        font-size: 27px;
    }

    .connect-twitter-model {
        padding: 30px 20px;
    }

    .success-box .main-btn-style span {
        font-size: 14px;
    }

    .success-box h3 {
        font-size: 17px;
        line-height: 27px;
    }

    .success-popup .points-detail-row,
    .social-share .points-detail-row {
        font-size: 14px;
    }

    .promotion-modal .modal-content .content {
        height: 260px;
    }

    .promotion-modal .modal-content .content h2 {
        font-size: 20px;
        line-height: 30px;
    }

    .social-sharing button,
    .social-sharing button svg {
        font-size: 14px;
    }

    .promotion-modal .modal-content .modal-header {
        width: 40px;
        height: 40px;
    }

    .promotion-modal .modal-content .modal-header .btn-close {
        font-size: 12px;
    }

    .field-wrapper p,
    .contest-card .wallet-address-copy p {
        font-size: 14px;
    }

    .success-popup .points-detail-row {
        font-size: 14px;
    }

    .collection-list ul li .banner-img {
        width: 110px;
        height: 110px;
    }

    .collection-list ul li .image {
        width: 46px;
        height: 46px;
        bottom: -20px;
        left: 17px;
    }

    .stats-sec .heading ul li a {
        font-size: 14px;
        font-weight: 500;
    }

    .sidebar-links li a .icon svg {
        width: 17px;
        height: 17px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body p {
        font-size: 18px;
        margin-bottom: 50px;
    }

    .success-popup .modal-dialog .modal-content .modal-body h2,
    .social-share h2 {
        font-size: 28px;
        margin-bottom: 25px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body .btn-gradient-blue {
        padding: 16px 28px;
    }

    .next-rewards-list {
        padding: 15px;
    }

    .claim-rewards {
        padding: 50px 30px 30px 30px;
    }

    .next-rewards-list ul li {
        border-radius: 12px;
        padding: 8px 22px;
    }

    .next-rewards-list ul li .day {
        font-size: 14px;
    }

    .next-rewards-list ul li .points {
        font-size: 18px;
    }

    .next-rewards-list ul li .image {
        width: 65px;
        height: 65px;
    }

    .claim-rewards .claim-rewards-header {
        max-width: 50%;
    }

    .claim-rewards .treasure-image {
        width: 200px;
        height: 200px;
        top: -160px;
    }

    .coinmarket-rewards .content h3,
    .claim-rewards .claim-rewards-header h3 {
        font-size: 20px;
        line-height: 27px;
    }

    .coinmarket-rewards .image-box {
        margin-left: 60px;
    }

    .coinmarket-rewards .image-box {
        width: 250px;
        height: 140px;
    }

    .coinmarket-rewards .image-box span:first-child {
        font-size: 16px;
    }

    .coinmarket-rewards .image-box span:last-child {
        font-size: 40px;
        line-height: 50px;
    }

    .social-links li button {
        width: 45px;
        height: 45px;
        font-size: 20px;
        margin: 7px;
    }

    .setting-wrapper .personal-info .information form .btn-primary,
    .contest-btn {
        font-size: 15px;
    }

    .setting-wrapper .wallet-address strong,
    .setting-wrapper .personal-info strong {
        font-size: 18px;
    }

    .setting-wrapper .personal-info .information form textarea.form-control {
        height: 150px;
    }

    .success-popup .modal-dialog .modal-content,
    .social-share ul {
        padding: 45px 35px;
    }

    .success-popup .modal-dialog {
        max-width: 1000px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li,
    .social-share ul li {
        padding: 25px;
        min-height: 345px;
        margin: 0 18px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li .image,
    .social-share ul li .image {
        width: 160px;
        height: 160px;
        margin: 0 auto 25px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li span,
    .social-share ul li span {
        font-size: 20px;
    }

    .not-found p {
        font-size: 16px;
    }

    .not-found .main-btn-style {
        padding: 8px 18px;
        font-size: 15px;
    }

    .not-found {
        min-height: 190px;
    }

    .all-notifications ul li {
        padding: 16px 0;
    }

    .all-notifications ul li p {
        font-size: 15px;
    }

    .all-notifications ul li span {
        font-size: 13px;
    }

    .notifications-model .notifications-model-content {
        max-width: 960px;
        padding: 18px;
    }

    .notifications-model .notifications-model-content {
        border-radius: 25px 0 0 25px;
    }

    .notifications-model .notifications-model-content .right-content h2 {
        margin-bottom: 40px;
    }

    .notifications-model .notifications-model-content .right-content h4 {
        font-size: 16px;
    }

    .notifications-model .notifications-model-content .right-content th,
    .notifications-model .notifications-model-content .right-content table tr td {
        font-size: 15px;
    }

    .notifications-model .notifications-model-content .left-content h3 {
        font-size: 20px;
    }

    .gift-card .heading .view-all.icon {
        height: 33px;
        width: 33px;
    }

    .section-dark-bg .heading .view-all,
    .section.heading .view-all,
    .heading .view-all,
    .nft-image .sale {
        font-size: 12px;
    }

    /* .my-nft-sec{min-height: 340px;} */
    .left-sidebar .collection-sec .section-dark-bg,
    .left-sidebar .activities {
        min-height: 320px;
    }

    .left-sidebar .about-sec .section-dark-bg {
        min-height: 250px;
    }

    .user-details .image {
        width: 37px;
        height: 37px;
    }

    .page-header h2 {
        font-size: 25px;
        line-height: 34px;
    }

    .page-header nav {
        margin-bottom: 15px;
        margin-top: 10px;
        padding: 10px 30px;
    }

    .page-header nav .breadcrumb .breadcrumb-item {
        font-size: 15px;
    }

    .main-dashboard .main-content-area {
        padding-top: 35px;
    }

    .badges-list ul li {
        width: 42%;
    }

    .utility-points-bg {
        border-radius: 37px 37px 10px 20px;
    }

    .daily-task-wrapper,
    .game-list ul li,
    .daily-rewards-bg,
    .badges-earned-bg,
    .daily-rewards-bg,
    .section-dark-bg,
    .user-info .user-about-bg,
    .activities ul {
        border-radius: 14px;
    }

    .main-dashboard .sidebar .sidebar-wrap .sidebar-links ul {
        height: calc(100vh + -160px);
    }

    .main-dashboard .sidebar .sidebar-wrap {
        padding: 20px 8px;
    }

    .form-modal .form-control {
        border-radius: 10px;
    }

    .promotion-wrapper .promotion-img .promotion-description a {
        padding: 13px 15px;
        font-size: 13px;
        border-radius: 11px 6px 5px 14px;
        clip-path: polygon(3% 3%, 100% 0, 100% 100%, 0 92%);
    }

    .buyers-rewards .item .overview h5 {
        font-size: 17px;
    }

    .buyers-rewards .item .overview .more {
        font-size: 19px;
    }

    .buyers-rewards .item .others ul li::before,
    .contest-card ul.contest-model li::before {
        font-size: 17px;
    }

    .buyers-rewards .item {
        padding: 40px 0;
    }

    .buyers-rewards .item .overview {
        padding-right: 80px;
    }

    .main-content-area {
        max-width: calc(100% - 260px);
    }

    .avatar-badge .image {
        height: 90px;
        width: 90px;
    }

    .login-wrap .card .meta-tag .wallet-img {
        margin-right: 70px;
    }

    h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .login-wrap .card {
        padding: 23px 40px;
    }

    .login-wrap .card .meta-tag .wallet-img {
        width: 70px;
        height: 70px;
    }

    .game-purchase-wrapper p {
        font-size: 15px;
    }

    .nfts-gallery ul li:first-child .nft-img-link,
    .nfts-gallery ul li:nth-child(2) .nft-img-link {
        height: 170px;
    }

    .nfts-gallery ul li .nft-img-link {
        height: 100px;
    }

    .nfts-gallery .nft-list ul li {
        width: 27%;
    }

    .nfts-gallery .nft-list ul li:first-child,
    .nfts-gallery .nft-list ul li:nth-child(2) {
        width: 45%;
    }

    .nfts-gallery .nft-list ul li .name {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .nfts-gallery .nft-list ul li .price .curreny-image span {
        font-size: 14px;
    }

    .collection-list ul li .image {
        width: 55px;
        height: 55px;
    }

    .user-info .user-about .image {
        /* width: 71px;
        height: 71px; */
    }

    .special-notes-modal .badges-image {
        width: 180px;
        height: 180px;
    }

    .contest-card p {
        font-size: 16px;
    }

    .item .nft-image .nft-img-link {
        width: 200px;
        height: 200px;
    }

    #edit-avatr-modal .title {
        font-size: 27px;
        line-height: 35px;
    }

    .leader-board-header th {
        padding: 13px 8px;
    }

    .daily-task-wrapper h4 {
        font-size: 17px;
    }

    .daily-task-wrapper .image {
        width: 80px;
        height: 80px;
    }

    .contest-points-bg,
    .daily-points-bg,
    .utility-points-bg,
    .social-points-bg {
        height: 120px;
    }

    .utility-points-item-bg::after {
        transform: rotate(-2deg);
    }

    .my-nfts .section-dark-bg,
    .my-nfts-bg {
        border-radius: 14px;
    }

    .promotion-modal .modal-content .content p {
        /* height: 238px; */
        /* display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; */
    }

    .special-notes-modal .modal-content {
        height: 400px;
        padding: 0;
    }

    /* .promotion-modal .modal-content{padding: 0;} */
    .main-dashboard .sidebar {
        max-width: 260px;
    }

    .section-dark-bg,
    .badges-earned-bg {
        padding: 20px;
    }

    .badges-list ul li img {
        height: 90px;
        width: 90px;
    }

    .sidebar .logo {
        margin-bottom: 30px;
        /* padding: 15px 0 0; */
    }

    .sidebar .logo .image {
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }

    .sidebar-links li {
        /* margin-bottom: 10px; */
    }

    .sidebar-links li a {
        font-size: 12px;
        padding: 8px 13px;
    }

    .daily-rewards li {
        padding-bottom: 12px;
        margin-bottom: 15px;
    }

    .section-dark-bg .heading .view-all,
    .section.heading .view-all {
        font-size: 14px;
    }

    .activities ul li {
        padding: 12px;
        font-size: 14px;
    }

    .section-dark-bg .heading,
    .section.heading {
        margin-bottom: 20px;
    }

    .points-list h3 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 2px;
    }

    .points-list h5 {
        font-size: 13px;
    }

    .nft-list ul li {
        width: 50%;
    }

    .custom-padding {
        padding: 0 40px;
    }

    .header .navbar-nav .nav-link {
        padding: 18px 20px;
    }

    .btn-gradient-pink {
        min-width: 200px;
        margin-left: 30px;
    }

    .header .navbar-brand {
        font-size: 18px;
    }

    .badges .item .image {
        width: 100px;
        height: 100px;
    }

    .banner-title {
        padding: 30px 20px 25px;
        margin-bottom: 30px;
    }

    .banner-title h1 {
        font-size: 80px;
        line-height: 80px;
    }

    .banner-title h1 span {
        font-size: 30px;
        margin-bottom: -40px;
    }

    .banner-title p {
        font-size: 20px;
        margin-left: 50px;
    }

    .avatar-badge .avatar {
        margin-right: 20px;
    }

    .buyers-rewards .item .overview>p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .more-items .others ul li span {
        font-size: 14px;
    }

    .main-content-area .page-title h2 {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 0px;
    }

    .coinmarket-rewards .image-box {
        /* max-width: 280px; */
        margin-left: 100px;
        position: relative;
    }

    .coinmarket-rewards .image-box span {
        /* font-size: 20px; */
        /* padding: 10px 15px; */
    }

    .coinmarket-rewards .content .actions .btn-primary {
        height: 55px;
    }

    .fp-col1 .featured-header,
    .fp-col2 .f-col .featured-header {
        padding: 3%;
    }

    .promotion-wrapper img {
        height: 190px;
    }

    .slick-slide .nft-image img {
        /* height: 230px; */
    }

    .nft-image .sale {
        /* right: 16px; */
        /* top: 16px; */
    }

    .nft-list .item .time-wrap span {
        padding: 5px 11px;
        font-size: 14px;
    }

    .user-info .progress-stats h2 {
        font-size: 12px;
        line-height: 15px;
    }

    .main-leaderboard .table-wrapper h2 {
        top: 22px;
    }

    .promotion-modal .modal-dialog {
        max-width: 740px;
    }

    .promotion-modal .modal-content .image {
        width: 200px;
        height: 200px;
        /* margin-left: 125px; */
    }

    .special-notes-modal .modal-content .image {
        width: 220px;
        height: 220px;
        /* margin-left: 100px; */
    }

    .game-list .col-xxl-8 {
        margin-bottom: 70px;
    }
}

@media (max-width: 1199px) {
    .item .nft-image {
        margin-left: 10px;
    }

    .nft-list .item .name a {
        font-size: 14px;
    }

    .mini-progress-stats .progress-stats .bar-width {
        width: 135px;
    }

    .progress-stats .progress-tasks .list ul li {
        width: 29%;
        min-height: 100px;
    }

    .progress-stats .progress-tasks {
        width: 76%;
    }

    .progress-stats .bar-width {
        width: 24%;
    }

    .cards-wrapper ul li.badges-wrap .avatars-badges-img {
        width: 80px;
        height: 80px;
    }

    .cards-wrapper ul li.badges-wrap .cards-inner-wrapper {
        padding: 25px;
    }

    .cards-wrapper ul li.badges-wrap {
        width: 48%;
    }

    .cards-wrapper ul li {
        width: 31%;
        margin: 6px;
    }

    .daily-task-wrapper {
        min-height: 260px;
    }

    .daily-task-wrapper::after {
        height: 45px;
    }

    .stats-sec .chart {
        height: 270px;
    }

    .badges-list ul li {
        width: 26%;
        justify-content: flex-start;
    }

    .badges-earned-bg {
        min-height: 250px;
    }

    .game-list ul li.treasury-box {
        margin-top: 130px;
    }

    .activity-popup .popup-lotties .lottie-1 {
        width: 500px;
    }

    .activity-popup .popup-lotties .lottie-2 {
        width: 420px;
    }

    .activity-popup .popup-lotties .lottie-2.lottie-cards {
        width: 250px;
    }

    .activity-popup .popup-content .popup-btn {
        font-size: 14px;
        padding: 8px 16px
    }

    .activity-popup .popup-content {
        min-height: 320px;
        padding-top: 150px;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    * {
        scrollbar-width: 5px;
    }

    .avatars-badges-img {
        width: 95px;
        height: 95px;
    }

    .create-nft .modal-content input {
        padding: 9px 11px;
    }

    .create-nft .modal-content .curr-heading {
        font-size: 15px;
    }

    .create-nft .curr-chain {
        height: 40px;
        width: 40px;
    }

    .create-nft .curr-chain svg {
        width: 20px;
        height: 20px;
    }

    .create-nft .modal-content .connect-btn {
        font-size: 16px;
        width: 30px;
        height: 30px;
    }

    .create-nft .modal-content .modal-main-body {
        padding-bottom: 20px;
    }

    .create-nft .modal-content .h4.modal-title {
        font-size: 22px;
    }

    .collection-list ul li .banner-img {
        width: 100%;
        height: 140px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body .btn-gradient-blue {
        font-size: 13px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body {
        min-height: 320px;
    }

    .next-rewards-list {
        padding: 10px;
    }

    .claim-rewards {
        padding: 50px 25px 25px 25px;
    }

    .next-rewards-list ul li:last-child {
        width: 100%;
        height: 160px;
        position: relative;
    }

    .next-rewards-list ul li:last-child .image {
        width: 100px;
        height: 100px;
    }

    .next-rewards-list ul li {
        width: 30%;
    }

    .next-rewards-list ul li .points {
        font-size: 16px;
    }

    .claim-rewards .time-count {
        font-size: 20px;
    }

    .coinmarket-rewards .image-box {
        margin-left: 30px;
    }

    .coinmarket-rewards .image-box span:first-child {
        font-size: 15px;
    }

    .coinmarket-rewards .image-box span:last-child {
        font-size: 30px;
        line-height: 35px;
    }

    .notifications-model .notifications-model-content .left-content .rewards-box {
        min-height: 460px;
    }

    .setting-wrapper .personal-info .information form .btn-primary,
    .contest-btn {
        padding: 14px 26px;
        font-size: 14px;
    }

    .copy-wrapper .copy-icon,
    .field-wrapper .copy-icon {
        width: 40px;
    }

    .setting-wrapper .wallet-address strong,
    .setting-wrapper .personal-info strong {
        font-size: 16px;
    }

    .setting-wrapper .personal-info .information form label {
        font-size: 15px;
    }

    .setting-wrapper .personal-info .information form .form-control {
        height: 50px;
        font-size: 14px;
    }

    .success-popup .modal-dialog {
        max-width: 800px;
    }

    .success-popup .modal-dialog .modal-content,
    .social-share ul {
        padding: 30px 25px;
        border-radius: 18px;
    }

    .success-popup .modal-dialog .modal-content .close-btn {
        width: 33px;
        height: 33px;
        font-size: 18px;
        top: 12px;
        right: 12px;
    }

    .success-popup .modal-dialog .modal-content .modal-body h2,
    .social-share h2 {
        margin-bottom: 35px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li span,
    .social-share ul li span {
        font-size: 17px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li,
    .social-share ul li {
        min-height: 280px;
        margin: 0 14px;
        padding: 16px;
        border-radius: 16px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li .image,
    .social-share ul li .image {
        width: 145px;
        height: 145px;
    }

    .all-notifications ul li {
        padding: 13px 0;
    }

    .all-notifications ul li p {
        font-size: 14px;
    }

    /* .notifications-model .notifications-model-content .left-content .avatar-img{width: 200px; height: 200px;} */
    .notifications-model .notifications-model-content .left-content h3 {
        font-size: 16px;
    }

    .notifications-model .notifications-model-content .close-model {
        width: 45px;
        height: 45px;
        font-size: 20px;
        left: 40px;
        top: 40px;
    }

    .notifications-model .notifications-model-content .left-content {
        border-radius: 18px;
    }

    .left-sidebar .collection-sec,
    .left-sidebar .about-sec {
        margin-bottom: 20px;
    }

    .item .nft-image .nft-img-link {
        width: 190px;
        height: 190px;
    }

    .more-items .others ul li {
        width: 75px;
        margin-right: 20px;
    }

    h2 {
        font-size: 28px;
        line-height: 40px;
    }

    .login-wrap .card .meta-tag h4 {
        margin: 0;
        font-size: 15px;
    }

    .login-wrap .card .meta-tag .wallet-img {
        width: 60px;
        height: 60px;
    }

    .login-wrap .card {
        padding: 22px 30px;
    }

    .nfts-gallery ul li .nft-img-link {
        height: 200px;
    }

    .nfts-gallery .nft-list ul li {
        width: 30%;
    }

    .nfts-gallery ul li:first-child .nft-img-link,
    .nfts-gallery ul li:nth-child(2) .nft-img-link {
        height: 270px;
    }

    .nfts-gallery .nft-list ul li:first-child,
    .nfts-gallery .nft-list ul li:nth-child(2) {
        width: 46%;
    }

    .collection-list ul {
        justify-content: flex-start;
    }

    .promotion-modal .modal-content .content h2 {
        font-size: 18px;
        line-height: 26px;
    }

    .user-info .user-about-bg {
        /* clip-path: polygon(2% 0, 97% 0, 100% 99%, 0% 100%); */
        border-radius: 14px;
    }

    .left-sidebar .about-sec {
        margin-bottom: 20px;
    }

    /* .promotion-modal .modal-content .content p{height: 228px;} */
    .nft-sec .sc-gsnTZi:nth-child(3) {
        /* padding: 60px 0px; */
        display: none;
    }

    .others ul li span {
        font-size: 12px !important;
    }

    .level-images ul img {
        width: 98px !important;
    }

    body {
        font-size: 15px;
    }

    h4 {
        font-size: 18px;
        line-height: 26px;
    }

    .section-dark-bg .heading .view-all,
    .section.heading .view-all {
        font-size: 12px;
    }

    .header .navbar-nav .nav-link {
        padding: 14px 16px;
    }

    .header .navbar-brand {
        font-size: 15px;
    }

    .header .navbar-brand .image {
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }

    .btn-gradient-pink {
        min-width: 180px;
        margin-left: 20px;
    }

    .points-list {
        margin-bottom: 20px;
    }

    .my-nfts .nft-list ul li {
        width: 50%;
    }

    .section-dark-bg {
        padding: 20px 25px;
    }

    .image .timer {
        padding: 6px 10px;
    }

    .nft-list ul li .name {
        margin-bottom: 10px;
    }

    .task-rewards {
        margin-top: 20px;
    }

    .game-list ul li {
        margin-bottom: 10px;
    }

    .collectibles-list ul li {
        width: 32%;
    }

    .nft-list ul li {
        width: 48%;
    }

    .nft-list ul li .image {
        height: 200px;
    }

    .custom-padding {
        padding: 0 20px;
    }

    .collection-list ul li {
        /* width: 100%; */
        margin-bottom: 12px;
        margin-right: 12px;
    }

    .collection-list ul li:last-child {
        margin-bottom: 0;
    }

    .user-stats ul li:nth-child(2),
    .user-stats ul li:last-child {
        padding-left: 15px;
    }

    .notifications {
        flex-direction: column;
    }

    .notifications .list {
        padding-right: 0;
        margin-bottom: 20px;
    }

    .notice {
        max-width: 100%;
        width: 100%;
    }

    .setting-wrapper {
        padding-right: 15px;
    }

    .field-wrapper {
        height: 55px;
    }

    .setting-wrapper .personal-info .information .image {
        width: 150px;
        height: 150px;
        margin-right: 30px;
    }

    .banner-title h1 {
        font-size: 60px;
        line-height: 60px;
    }

    .banner-title h1 span {
        font-size: 22px;
        margin-bottom: -25px;
    }

    .banner-title p {
        font-size: 18px;
        margin-left: 30px;
    }

    .coinmarket-rewards .content {
        padding-right: 0;
    }

    .coinmarket-rewards .content p {
        margin-bottom: 25px;
    }

    .coinmarket-rewards .content .actions .btn-primary {
        height: 55px;
        padding: 10px 20px;
        margin-right: 20px;
    }

    .coinmarket-rewards .image-box {
        /* max-width: 250px; */
        margin-left: 50px;
        width: 200px;
        height: 100px;
        border-radius: 12px;
    }

    .next-rewards-list ul {
        flex-wrap: wrap;
    }

    .next-rewards-list ul li {
        flex: inherit;
        cursor: pointer;
    }

    .next-rewards-list ul li .image {
        height: 55px;
        width: 55px;
    }

    /* .avatar-badge .image {
        height: 70px;
        
        width: 50px;
    } */

    .avatar-badge .avatar {
        margin-right: 10px;
    }

    .buyers-rewards .item .overview {
        padding-right: 50px;
    }

    .promotion-modal .modal-dialog {
        /* max-width: 800px; */
    }

    .promotion-modal .modal-content {
        border-radius: 20px;
        /* padding: 120px 0; */
        background-color: transparent;
    }

    .promotion-modal .modal-content .image {
        width: 150px;
        height: 150px;
        /* margin-left: 110px; */
    }

    .special-notes-modal .modal-content .image {
        width: 200px;
        height: 200px;
        /* margin-left: 90px; */
    }

    .promotion-modal .modal-content .content .action-btn {
        /* margin-top: 30px; */
    }

    .promotion-modal .modal-content .content .btn-primary {
        padding: 10px 15px;
        font-size: 14px;
    }

    .masonry-item .content-tags {
        padding: 12px 24px;
    }

    .user-info .progress-stats h2 {
        font-size: 12px;
    }
}

@media (max-width: 991px) {
    .activity-popup .popup-logo {
        width: 145px;
        height: 145px;
        top: -66px;
    }

    .activity-popup .popup-content h2 {
        font-size: 25px;
        line-height: 33px;
    }

    .activity-popup .popup-content h2,
    .activity-popup .popup-content p {
        margin-bottom: 15px;
    }

    .progress-stats .progress-tasks .list ul li .task-name,
    .progress-stats .progress-tasks .list ul li .percentage {
        font-size: 15px;
    }

    .progress-stats .progress-tasks .list ul li {
        min-height: 110px;
    }

    .next-rewards-list ul li {
        min-height: 115px;
    }

    .cards-wrapper ul li.badges-wrap {
        width: 47%;
    }

    .claim-rewards .time-img {
        width: 110px;
        height: 120px;
    }

    /* .claim-rewards .time-title{right: 90px;} */
    .activity-popup .popup-lotties .lottie-1 {
        width: 460px;
    }

    .activity-popup .popup-lotties .lottie-2 {
        width: 370px;
    }

    .activity-popup .popup-lotties .lottie-2.lottie-cards {
        width: 200px;
    }

    .activity-popup .popup-content p {
        font-size: 17px;
        line-height: 24px;
    }

    .activity-popup .popup-content .popup-btn {
        font-size: 13px;
        padding: 7px 15px;
        border-width: 1px;
    }

    .activity-popup .modal-dialog .modal-content .popup-content {
        min-height: 310px;
        padding: 150px 30px 25px 30px;
    }

    .all-notifications {
        max-height: 65vh;
    }

    .btn-style-2 {
        padding: 10px 22px;
    }

    .connect-twitter-model {
        padding: 20px 15px;
    }

    .success-box .main-btn-style span {
        font-size: 12px;
    }

    .success-box h3 {
        font-size: 15px;
        line-height: 22px;
    }

    .profile-modal .modal-content .modal-header {
        height: 65px;
    }

    .profile-modal .modal-content .modal-header .image .icon {
        width: 65px;
        height: 65px;
    }

    .profile-modal .modal-content .main-btn-style {
        font-size: 14px;
    }

    .main-leaderboard .table-wrapper {
        min-height: 400px;
    }

    .main-leaderboard .not-found {
        min-height: 250px;
    }

    .create-nft .modal-content .h4.modal-title {
        font-size: 18px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body {
        min-height: 280px;
    }

    .success-popup .modal-dialog .modal-content .modal-body h2 {
        font-size: 25px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body p {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .next-rewards-list {
        padding: 30px 0 0 0;
    }

    .claim-rewards .treasure-image {
        width: 170px;
        height: 170px;
        top: -135px;
    }

    .coinmarket-rewards .content h3,
    .claim-rewards .claim-rewards-header h3 {
        font-size: 18px;
        line-height: 26px;
    }

    .coinmarket-rewards .content {
        margin-right: 50px;
    }

    .setting-wrapper .personal-info .information form .btn-primary,
    .contest-btn {
        padding: 11px 16px;
        border-radius: 11px 6px 6px 10px;
        font-size: 13px;
    }

    .success-popup .modal-dialog {
        max-width: 640px;
    }

    .success-popup .modal-dialog .modal-content .modal-body {
        padding-left: 0;
        padding-right: 0;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li,
    .social-share ul li {
        margin: 0 8px;
        min-height: 255px;
        padding: 15px;
    }

    .social-share ul li span {
        font-size: 15px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li span {
        font-size: 15px;
        -webkit-line-clamp: 3;
        max-height: 94px;
    }

    .success-popup .modal-dialog .modal-content .modal-body h2,
    .social-share h2 {
        margin-bottom: 27px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li .image,
    .social-share ul li .image {
        width: 125px;
        height: 125px;
        margin: 0 auto 20px;
    }

    .not-found p {
        font-size: 15px;
    }

    .not-found .main-btn-style {
        padding: 7px 16px;
        font-size: 14px;
    }

    .badges-earned-bg {
        min-height: 100%;
    }

    .notifications-model .notifications-model-content {
        border-radius: 20px 0 0 20px;
    }

    .all-notifications ul li p {
        font-size: 13px;
    }

    .all-notifications ul li span {
        font-size: 12px;
    }

    /* .notifications-model .notifications-model-content .left-content .avatar-img{width: 180px; height: 180px;} */

    .left-sidebar .collection-sec .section-dark-bg,
    .left-sidebar .activities {
        min-height: 300px;
    }

    .user-info .user-about-bg::before {
        left: -160px;
    }

    .main-dashboard .sidebar .sidebar-wrap .sidebar-links ul {
        height: 100%;
    }

    .main-dashboard .sidebar .sidebar-wrap {
        padding: 15px 6px;
    }

    .main-dashboard .sidebar .sidebar-wrap::before {
        display: none;
    }

    .more-items .others ul li {
        width: 70px;
        margin-right: 17px;
    }

    .others p {
        margin-bottom: 15px;
    }

    h2 {
        font-size: 26px;
        line-height: 37px;
    }

    .nfts-gallery ul li:first-child .nft-img-link,
    .nfts-gallery ul li:nth-child(2) .nft-img-link {
        height: 170px;
    }

    .nfts-gallery ul li .nft-img-link {
        height: 125px;
    }

    .promotion-modal .modal-content {
        border-radius: 15px;
        border-width: 1px;
    }

    .promotion-modal .modal-content .content h2 {
        font-size: 17px;
    }

    .special-notes-modal .badges-image {
        width: 160px;
        height: 160px;
    }

    .game-list ul li.treasury-box .image {
        top: -110px;
        width: 180px;
        height: 180px;
    }

    .contest-points-bg,
    .daily-points-bg,
    .utility-points-bg,
    .social-points-bg {
        height: 120px;
    }

    .utility-points-item-bg:after {
        transform: rotate(-2deg);
    }

    /* .my-nfts .section-dark-bg{border-radius: 40px 20px 40px 20px;} */
    .special-notes-modal .modal-content {
        height: 350px;
    }

    .promotion-modal .modal-content .content {
        /* height: 220px; */
    }

    .badges-list ul li {
        width: 22%;
    }

    .badges-list ul li img {
        height: 108px;
    }

    .special-notes-modal .modal-body {
        /* padding-top: 45px; */
    }

    .special-notes-modal .modal-body {
        /* padding-top: 45px; */
        padding: 58px 15px 15px;
        height: 100%;
    }

    /* .promotion-modal .modal-content .content p{height: 212px;} */
    .section-dark-bg {
        height: auto;
        min-height: auto;
    }

    .others ul li span {
        font-size: 12px;
    }

    .level-images ul img {
        width: 100px;
    }

    .header .navbar-collapse {
        position: absolute;
        left: 0;
        top: 60px;
        width: 100%;
        background: #000;
        z-index: 15;
        padding-bottom: 20px;
    }

    .header .navbar-collapse .btn-gradient-pink {
        margin: 0 auto;
        width: calc(100% - 40px);
    }

    .header .navbar-nav .nav-link {
        padding: 10px 20px;
    }

    .main-dashboard .main-content-area {
        max-width: 100%;
        width: 100%;
        padding-top: 0;
        position: relative;
    }

    .main-dashboard .sidebar {
        height: 100%;
    }

    /* .main-dashboard .sidebar {
    order: 1;
  }
  .main-dashboard .right-sidebar {
    order: 2;
  }
  .main-dashboard .main-content-area {
    order: 3;
  } */
    .main-dashboard .sidebar,
    .main-dashboard .right-sidebar {
        max-width: 100%;
        margin-bottom: 20px;
        position: relative;
    }

    .main-dashboard {
        padding: 0 15px;
        /* max-width: 700px; */
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .sidebar .logo {
        margin-bottom: 0;
    }

    .toggle-button {
        display: flex;
    }

    .sidebar-links {
        display: none;
        position: absolute;
        /* position: static; */
        width: calc(100% - 0px);
        /* width: 100%; */
        left: 0;
        background: #000;
        z-index: 9;
        padding: 20px;
        top: 100px;
    }

    .sidebar.active .sidebar-links {
        display: block;
    }

    .header .navbar-brand {
        font-size: 16px;
    }

    .header .navbar-brand .image {
        width: 30px;
        height: 30px;
    }

    .header .custom-padding {
        padding: 10px 15px;
    }

    .header .navbar-toggler {
        color: rgba(0, 0, 0, 0.55);
        border-color: rgb(255 252 252 / 55%);
        padding: 3px 7px;
    }

    .main-profile {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .notifications .list {
        padding-right: 0;
        margin-bottom: 20px;
        overflow: hidden;
        overflow-x: auto;
    }

    .left-sidebar {
        margin-bottom: 20px;
    }

    .main-dashboard .main-content-area .container-fluid {
        padding: 0;
    }

    .notifications {
        padding-right: 0;
    }

    .setting-wrapper .personal-info .information {
        flex-direction: column;
    }

    .setting-wrapper .personal-info .information .image {
        margin: 0 auto 30px;
    }

    .field-wrapper {
        height: 45px;
        padding: 0px 0 0px 20px;
    }

    .setting-wrapper .personal-info .information form .form-control {
        padding: 5px 15px;
        font-size: 15px;
        height: 45px;
    }

    .setting-wrapper {
        padding-right: 12px;
    }

    .main-dashboard .sidebar {
        min-height: auto;
        overflow-y: inherit;
        padding-left: 0;
    }

    .breadcrum.daily {
        margin-left: 0;
    }

    .coinmarket-rewards {
        /* flex-wrap: wrap; */
        padding: 0;
    }

    .coinmarket-rewards .image-box span:last-child {
        font-size: 25px;
        line-height: 30px;
    }

    .coinmarket-rewards .image-box span:first-child {
        font-size: 13px;
        margin-bottom: 3px;
    }

    .coinmarket-rewards .content {
        width: 100%;
        /* flex: inherit; */
    }

    .coinmarket-rewards .image-box {
        /* max-width: 250px; */
        margin: 0;
    }

    .next-rewards-list ul {
        /* justify-content: flex-start; */
    }

    .next-rewards-list ul li {
        /* margin-bottom: 20px; */
        padding: 5px 10px;
        /* width: 25%; */
    }

    .next-rewards-list ul li .points {
        font-size: 15px;
    }

    .next-rewards-list ul li .day {
        font-size: 12px;
    }

    .next-rewards-list ul li .image {
        height: 45px;
        width: 45px;
    }

    .buyers-rewards .item {
        padding: 30px 0;
        flex-direction: column;
    }

    .avatar-badge {
        margin-bottom: 20px;
        width: 100%;
    }

    .buyers-rewards .item .overview {
        padding-left: 0;
        width: 100%;
    }

    .main-leaderboard .table-wrapper {
        padding: 80px 30px 40px;
    }

    .main-leaderboard .table-wrapper::before {
        height: 70px;
    }

    .main-leaderboard .table-wrapper::after {
        height: 25px;
    }

    .filters .btn-secondary {
        /* padding: 5px 12px; */
    }

    .contest-card {
        /* max-width: 400px; */
        margin: 30px auto;
        height: auto;
    }

    .avatar-badge .image {
        height: 77px;
        width: 77px;
        margin-bottom: 10px;
    }

    .promotion-modal .modal-dialog {
        max-width: 650px;
    }

    .promotion-modal .modal-content {
        /* padding: 100px 0; */
    }

    .promotion-modal .modal-content .image {
        width: 130px;
        height: 130px;
        /* margin-left: 80px */
    }

    .special-notes-modal .modal-content .image {
        width: 180px;
        height: 180px;
        /* margin-left: 60px; */
    }

    .promotion-modal .modal-content .content p,
    .promotion-modal .modal-content .content li {
        font-size: 14px;
    }

    .posts-myntist .container1 .featured-area-wrapper {
        flex-direction: column;
        height: unset;
    }

    .posts-myntist .container1 .featured-area-wrapper .col1 {
        width: 100%;
        margin-bottom: 10px;
    }

    .posts-myntist .container1 .featured-area-wrapper .fp-col2 {
        width: 100%;
    }

    .main-leaderboard .table-wrapper h2 {
        top: 10px;
    }

    .promotion-modal .modal-content .modal-header {
        right: 15px;
        top: 15px;
    }

    .claim-rewards .time-count {
        font-size: 17px;
    }
}

@media (max-width: 767px) {
    .activity-popup .rewards-anime {
        width: 160px;
        height: 160px;
        top: -70px;
    }

    .not-slider .item {
        width: 50%;
    }

    .activity-popup .popup-content h2 {
        font-size: 22px;
        line-height: 28px;
    }

    .activity-popup .popup-content h3 {
        font-size: 18px;
        line-height: 27px
    }

    .mini-progress-stats .progress-stats .bar-width {
        width: 110px;
    }

    .progress-stats {
        flex-direction: column;
    }

    .progress-stats .bar-width {
        width: 50%;
        margin: 0 auto;
    }

    .progress-stats .progress-tasks .list ul li {
        width: 31%;
    }

    .progress-stats .progress-tasks {
        width: 100%;
        margin-bottom: 30px;
    }

    .tresure-week .able .claim-status p {
        font-size: 13px;
    }

    .tresure-week .able .claim-status h6 .countdown-container .countdown-wrapper .daily,
    .tresure-week .able .claim-status h6 {
        font-size: 12px;
    }

    .setting-wrapper .personal-info .information form .btn-primary,
    .contest-btn,
    .tresure-week .claimed .claim-status strong,
    .tresure-week .disable .claim-status strong {
        font-size: 12px;
    }

    .cards-wrapper ul li.badges-wrap .cards-inner-wrapper {
        min-height: 150px;
    }

    .cards-inner-wrapper {
        min-height: 240px;
        padding: 18px 15px;
    }

    .cards-inner-button {
        font-size: 12px;
    }

    .avatars-badges-img {
        width: 85px;
        height: 85px;
    }

    .custom-modal .modal-dialog {
        max-width: 540px;
    }

    .custom-select__option {
        font-size: 13px !important;
    }

    .fb-model .modal-title {
        font-size: 18px;
    }

    .activity-popup .modal-dialog {
        max-width: 560px;
    }

    .activity-popup .popup-content {
        min-height: 300px;
        padding: 130px 25px 15px 25px;
    }

    .social-share ul {
        min-height: 50vh;
    }

    .title-heading {
        font-size: 25px;
    }

    .btn-style-2 {
        font-size: 14px;
    }

    .social-share .points-detail-row {
        width: 92%;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li span,
    .social-share ul li span {
        font-size: 14px;
    }

    .special-notes-modal .modal-body {
        height: 100%;
        overflow-y: auto;
        padding: 16px;
    }

    .social-sharing {
        justify-content: flex-start;
    }

    .avatars-badges-img {
        /* width: 40px;
        height: 40px; */
    }

    .promotion-wrapper .promotion-img .promotion-description a {
        padding: 10px 15px;
        font-size: 11px;
        border-radius: 11px 6px 5px 14px;
    }

    .progress-stats {
        margin: 0 auto 65px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body {
        min-height: 260px;
    }

    .success-popup .modal-dialog .modal-content .modal-body h2,
    .social-share h2 {
        font-size: 22px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body p {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body .btn-gradient-blue {
        padding: 14px 22px;
    }

    .claim-rewards .time-title strong {
        font-family: "Montserrat";
    }

    .claim-rewards .time-title {
        padding: 10px 23px;
    }

    .claim-rewards .time-img {
        width: 95px;
        height: 95px;
        top: 2px;
        right: -25px;
        font-size: 16px;
    }

    .claim-rewards .time-count {
        font-size: 16px;
    }

    .claim-rewards .treasure-image {
        width: 140px;
        height: 140px;
        top: -115px;
    }

    .coinmarket-rewards .image-box {
        width: 140px;
        height: 85px;
    }

    .coinmarket-rewards {
        align-items: flex-start;
    }

    .coinmarket-rewards .image-box span:last-child {
        font-size: 17px;
        line-height: 20px;
    }

    /* .coinmarket-rewards .content{margin-left: 20px;} */
    .coinmarket-rewards .content p {
        margin-bottom: 20px;
    }

    .field-wrapper p {
        font-size: 14px;
    }

    .success-popup .modal-dialog {
        max-width: 550px;
    }

    .success-popup .modal-dialog .modal-content {
        padding: 50px 22px 22px 22px;
    }

    .success-popup .modal-dialog .modal-content .close-btn {
        top: 10px;
        right: 10px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li .image,
    .social-share ul li .image {
        height: 115px;
        width: 115px;
    }

    .success-popup .modal-dialog .modal-content .modal-body {
        padding: 0;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li,
    .social-share ul li {
        min-height: 235px;
        flex: 1;
    }

    /* .notifications-model .notifications-model-content .left-content .avatar-img{width: 150px; height: 150px;} */
    .notifications-model .notifications-model-content .close-model {
        width: 38px;
        height: 38px;
        font-size: 18px;
        border-radius: 7px;
        left: 28px;
        top: 28px;
    }

    .notifications-model .notifications-model-content .right-content th,
    .notifications-model .notifications-model-content .right-content td {
        padding: 12px;
    }

    .more-items .others ul li {
        width: 55px;
        margin-right: 15px;
    }

    .buyers-rewards .item .overview {
        padding-right: 10px;
    }

    .buyers-rewards .item {
        padding: 15px 0;
    }

    .main-dashboard .sidebar .sidebar-wrap {
        padding: 15px 0;
    }

    .main-dashboard {
        /* max-width: 500px; */
    }

    .promotion-modal .modal-content,
    .special-notes-modal .modal-content {
        background-image: url('./assets/images//popup-1.png');
    }

    .promotion-modal .modal-content .content {
        height: 100%;
    }

    .promotion-modal .modal-content .content p {
        height: auto;
    }

    .my-nfts .section-dark-bg,
    .my-nfts-bg {
        /* border-radius: 30px 15px 30px 15px; */
    }

    .badges-list ul li {
        width: 27%;
    }

    .special-notes-modal .modal-content {
        height: 370px;
    }

    /* .special-notes-modal .modal-content .image img{width: 100px;} */
    .main-content-area select {
        width: 100%;
    }

    .achievement-content {
        flex-wrap: wrap;
        width: 100%;
    }

    .filters {
        flex-direction: column;
    }

    .filters .custom-picker {
        margin-top: 20px;
    }

    .main-leaderboard .table-wrapper {
        padding: 60px 20px 30px;
    }

    .main-leaderboard .table-wrapper::before {
        height: 50px;
    }

    h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .main-leaderboard .table-wrapper h2 {
        top: 5px;
    }

    .post-sidebar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .post-content-wrapper .post-main-content {
        width: 100%;
    }

    .post-content-wrapper .post-sidebar-secondray {
        width: 100%;
        float: unset;
    }

    .post-content-wrapper {
        display: flex;
        flex-direction: column-reverse;
    }

    .special-notes-modal .modal-content .image {
        width: 170px;
        height: 170px;
        /* margin-left: 50px; */
        /* margin-top: 30px; */
    }

    .special-notes-modal .badges-image {
        width: 145px;
        height: 145px;
    }

    .promotion-modal .modal-content {
        padding: 10px 5px;
    }

    /* .promotion-modal .modal-content{height: 100%;} */
    .promotion-modal .modal-content .image {
        margin-bottom: 10px;
    }

    .success-popup .points-detail-row,
    .social-share .points-detail-row {
        font-size: 13px;
        padding: 12px 30px;
    }

}

@media (max-width: 575px) {
    .activity-popup .popup-content h2 {
        margin-bottom: 7px;
        font-size: 20px;
    }

    .activity-popup .popup-content h3 {
        font-size: 17px;
        line-height: 25px
    }

    .mini-progress-stats .progress-stats .bar-width {
        width: 95px;
    }

    .progress-stats .progress-tasks .list ul li {
        width: 47%;
        min-height: 100px;
    }

    .progress-stats .progress-tasks .list ul li .task-name,
    .progress-stats .progress-tasks .list ul li .percentage {
        font-size: 13px;
    }

    .progress-stats .bar-width {
        width: 60%;
    }

    .claim-rewards .time-title strong {
        font-weight: 500;
        font-size: 11px;
    }

    .claim-rewards .time-title {
        padding: 8px 10px;
        max-width: 150px;
    }

    .tresure-week .claimed .claim-status strong,
    .tresure-week .disable .claim-status strong {
        font-size: 11px;
    }

    .cards-wrapper ul li.badges-wrap {
        width: 100%;
    }

    .cards-inner-button {
        padding: 7px 15px;
    }

    .cards-inner-wrapper strong {
        font-size: 16px;
    }

    .cards-wrapper ul li {
        width: 47%;
        margin: 5px;
    }

    .claim-rewards .claim-rewards-header h3 {
        margin-bottom: 15px;
    }

    .claim-rewards .claim-rewards-header p {
        margin-bottom: 20px;
    }

    .claim-rewards .claim-rewards-header {
        max-width: 100%;
        padding-top: 130px;
    }

    .custom-select__option {
        font-size: 12px !important;
    }

    .custom-select__control {
        height: 42px;
    }

    .fb-model .modal-title {
        font-size: 16px;
    }

    .sell-avatar-btn {
        font-size: 14px;
    }

    .stats-sec .chart {
        height: 220px;
    }

    .activity-popup .popup-lotties .lottie-1 {
        width: 380px;
    }

    .activity-popup .popup-lotties .lottie-2 {
        width: 330px;
    }

    .activity-popup .popup-lotties .lottie-2.lottie-cards {
        width: 160px;
    }

    .activity-popup .popup-content p {
        font-size: 13px;
        line-height: 22px;
    }

    .activity-popup .popup-content .popup-btn {
        font-size: 12px;
    }

    .activity-popup .popup-content {
        min-height: 270px;
        padding: 110px 18px 12px 18px;
    }

    ::-webkit-scrollbar {
        width: 4px;
    }

    * {
        scrollbar-width: 4px;
    }

    .btn-style-2 {
        padding: 10px 18px;
        font-size: 13px;
    }

    .title-heading {
        font-size: 21px;
    }

    .social-share ul {
        padding: 14px 12px;
        border-radius: 12px;
    }

    .promotion-modal .modal-content .content h2 {
        font-size: 16px;
    }

    .social-sharing button {
        font-size: 13px;
        padding: 7px 14px;
    }

    .collection-list ul {
        display: block;
    }

    .collection-list ul li {
        width: 100%;
    }

    .coinmarket-rewards {
        display: block;
    }

    .coinmarket-rewards .content {
        margin-bottom: 20px;
    }

    .coinmarket-rewards .image-box {
        width: 100%;
        height: 65px;
    }

    .coinmarket-rewards .content h3,
    .claim-rewards .claim-rewards-header h3 {
        font-size: 16px;
        line-height: 23px;
    }

    /* .coinmarket-rewards .image-box {
        width: 131px;
        height: 75px;
    } */
    .coinmarket-rewards .content p {
        font-size: 14px;
    }

    .coinmarket-rewards .content {
        margin-right: 10px;
    }

    .notifications-model .notifications-model-content .left-content .rewards-box {
        min-height: 400px;
    }

    .notifications-model .notifications-model-content .left-content .avatar-img {
        width: 65px;
        height: 65px;
    }

    .notifications-model .notifications-model-content .left-content .avatar-img.image-1 {
        width: 85px;
        height: 85px;
    }

    .notifications-model .notifications-model-content .left-content .avatar-img.image-2 {
        left: 90px;
    }

    .notifications-model .notifications-model-content .left-content .avatar-img.image-3 {
        top: 50%;
    }

    .success-popup .modal-dialog .modal-content {
        height: 400px;
        overflow: auto;
    }

    .congrats-modal .modal-dialog .modal-content {
        height: auto;
    }

    .success-popup .modal-dialog .modal-content {
        padding: 40px 17px 17px 17px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li .image {
        width: 110px;
        height: 110px;
    }

    .social-share ul li .image {
        width: 75px;
        height: 75px;
        margin: 0 auto 10px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li:first-child {
        margin-left: 6px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li:last-child {
        margin-right: 6px;
    }

    .success-popup .modal-dialog .modal-content .modal-body ul li,
    .social-share ul li {
        width: 90%;
        flex: inherit;
        margin: 6px;
        border-radius: 12px;
        min-height: 190px;
        padding: 10px;
    }

    .social-share ul li {
        min-height: 165px;
        width: 29%;
    }

    .main-dashboard {
        max-width: 100%;
    }

    .success-popup .modal-dialog .modal-content .modal-body h2 {
        font-size: 19px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .social-share h2 {
        font-size: 16px;
        line-height: 20px;
    }

    .social-share ul li span {
        font-family: "Montserrat";
        font-weight: 500;
        text-transform: capitalize;
        line-height: 16px;
        font-size: 13px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body {
        min-height: 230px;
    }

    .login-wrap .card .meta-tag {
        flex-direction: column;
    }

    .login-wrap .card .meta-tag .wallet-img {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
        margin-right: 0;
    }

    .special-notes-modal .badges-image {
        width: 130px;
        height: 130px;
    }

    .special-notes-modal .modal-content .image {
        height: 150px;
        width: 150px;
    }

    .promotion-modal .modal-content .content h2 {
        font-size: 17px;
        line-height: 28px;
        margin-bottom: 12px;
    }

    .contest-card {
        padding: 20px 20px 100px 20px;
    }

    .contest-card .card-bottom {
        padding: 15px;
    }

    .game-list ul li.treasury-box .image {
        top: -100px;
        width: 160px;
        height: 160px;
    }

    .custom-modal .modal-content .modal-header .modal-title {
        font-size: 20px;
    }

    .connect-wallet .modal-content .modal-body h4 {
        margin-bottom: 20px;
    }

    .connect-wallet .modal-content .modal-body h4 {
        font-size: 16px;
    }

    .connect-wallet .modal-content .connect-btn {
        font-size: 14px;
        width: 26px;
        height: 26px;
        top: -5px;
        right: -5px;
        border-radius: 4px;
    }

    .select-save {
        padding: 6px 18px;
    }

    .custom-select__control {
        height: 42px;
        font-size: 12px;
    }

    .profile-modal .modal-content .connect-btn {
        height: 30px;
    }

    .custom-modal .modal-content .modal-header {
        padding: 10px;
    }

    .modal.fade .modal-dialog {
        max-width: 75%;
        margin: 0 auto;
    }

    .level-images .image img {
        margin: 0px 5px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .others ul li span {
        font-size: 8px !important;
    }

    .custom-modal-content .form-check-input[type=checkbox] {
        width: 45px;
        height: 45px;
    }

    .custom-modal-content label {
        font-size: 11px;
    }

    .sidebar .logo .image {
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }

    h5 {
        font-size: 16px;
        line-height: 24px;
    }

    .sidebar .logo .name span {
        font-size: 12px;
        opacity: 0.5;
    }

    .toggle-button {
        width: 40px;
        height: 35px;
    }

    .toggle-button span {
        width: 20px;
        height: 2px;
        margin-bottom: 5px;
    }

    .section-dark-bg {
        padding: 15px;
    }

    .my-nfts .nft-list ul li,
    .nft-list ul li {
        width: 100%;
        margin-bottom: 40px;
    }

    .game-list ul li {
        padding: 15px;
        width: 100%;
        padding-bottom: 50px;
    }

    .game-list ul li h3 {
        height: auto;
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 30px;
    }

    .collectibles {
        margin-bottom: 20px;
    }

    .collectibles .section-dark-bg {
        height: auto;
    }

    .collectibles-list ul li {
        width: 48%;
    }

    .stats-sec {
        margin-top: 20px;
    }

    .stats-sec .section-dark-bg .heading {
        flex-direction: column;
        align-items: flex-start;
    }

    .stats-sec .section-dark-bg .heading h4 {
        margin-bottom: 20px;
    }

    .collection-sec {
        margin-top: 20px;
    }

    .notifications .list ul {
        width: 450px;
    }

    .notice {
        padding: 15px;
    }

    .nft-list ul li .image {
        height: 250px;
    }

    .field-wrapper {
        height: 45px;
        padding: 0px 40px 0px 10px;
    }

    .wallet-address .field-wrapper p {
        overflow: hidden;
    }

    .main-content-area .page-title h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .banner-title {
        padding: 15px;
        margin-bottom: 30px;
        flex-wrap: wrap;
    }

    .banner-title h1 {
        font-size: 50px;
        line-height: 50px;
    }

    .banner-title p {
        font-size: 16px;
        margin-left: 0;
        margin-top: 20px;
        text-align: center;
    }

    .next-rewards-list ul li {
        width: 46%;
        padding: 7px 10px;
        /* margin-bottom: 30px; */
    }

    .next-rewards-list ul li:last-child {
        height: 130px;
    }

    .next-rewards-list ul li:last-child .image {
        width: 70px;
        height: 70px;
    }

    .next-rewards-list {
        /* padding: 30px 15px; */
    }

    h2 {
        font-size: 20px;
        line-height: 28px;
    }

    .main-leaderboard .table-wrapper::after {
        height: 15px;
    }

    .posts-myntist .container1 .featured-area-wrapper .col2,
    .posts-myntist .container1 .featured-area-wrapper .col3,
    .posts-myntist .container1 .featured-area-wrapper .col4,
    .posts-myntist .container1 .featured-area-wrapper .col5 {
        width: 100%;
    }

    .featured-header {
        font-size: 12px;
    }

    .main-leaderboard .table-wrapper h2 {
        top: 2px;
    }

    .setting-wrapper .address-wrapper {
        flex-direction: column;
    }

    .address-wrapper .share-btns {
        width: 100%;
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }

    .address-wrapper .copy-wrapper {
        width: 100%;
    }

    .setting-wrapper {
        padding: 25px 12px;
    }

    .padding25 {
        padding: 0 12px;
    }

    .special-notes-modal .modal-content .image {
        /* margin-left: 20px; */
    }

    .promotion-modal .modal-content .image {
        /* margin-left: 50px; */
    }
}

@media (max-width: 475px) {
    .mini-progress-stats .progress-stats .bar-width {
        width: 85px;
    }

    .progress-stats .progress-tasks .list ul li {
        width: 100%;
        margin-right: 0;
    }

    .fb-model .modal-title {
        font-size: 15px;
    }

    .activity-popup .popup-lotties .lottie-1 {
        width: 300px;
    }

    .activity-popup .popup-lotties .lottie-2 {
        width: 290px;
    }

    .activity-popup .popup-content p {
        font-size: 12px;
        line-height: 18px;
    }

    .activity-popup .popup-content .popup-btn {
        font-size: 11px;
    }

    .activity-popup .popup-content {
        min-height: 235px;
        padding: 95px 15px 10px 15px;
    }

    .all-notifications {
        padding: 20px;
    }

    .title-heading {
        font-size: 19px;
    }

    .social-share ul li span {
        font-size: 11px;
        line-height: 12px;
    }

    .social-share ul li .image {
        width: 45px;
        height: 45px;
        margin: 0 auto 7px;
    }

    .social-share ul li {
        min-height: 120px;
        padding: 5px;
    }

    .social-share .points-detail-row {
        padding: 8px;
        font-size: 11px;
    }

    .success-popup .modal-dialog .modal-content .modal-body h2 {
        font-size: 20px;
    }

    .social-share h2 {
        font-size: 14px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body .btn-gradient-blue {
        padding: 12px 20px;
        font-size: 12px;
    }

    .congrats-modal .modal-dialog .modal-content .modal-body {
        min-height: 200px;
    }

    .next-rewards-list ul li {
        width: 100%;
    }

    .next-rewards-list ul li:last-child {
        height: auto;
    }

    .next-rewards-list ul li:last-child .image {
        width: 45px;
        height: 45px;
    }

    .notifications-model .notifications-model-content .left-content .avatar-img {
        width: 55px;
        height: 55px;
    }

    .notifications-model .notifications-model-content .left-content .avatar-img.image-1 {
        bottom: 120px;
        width: 70px;
        height: 70px;
    }

    .setting-wrapper .personal-info .information .image {
        width: 120px;
        height: 120px;
    }

    .nfts-gallery .nft-list ul li:first-child,
    .nfts-gallery .nft-list ul li:nth-child(2),
    .nfts-gallery .nft-list ul li {
        width: 100%;
    }

    .nfts-gallery ul li:first-child .nft-img-link,
    .nfts-gallery ul li:nth-child(2) .nft-img-link,
    .nfts-gallery ul li .nft-img-link {
        height: 225px;
    }

    .nft-sec .sc-gsnTZi:nth-child(3),
    .nft-sec .sc-gsnTZi:nth-child(2) {
        /* padding: 60px 0px; */
        display: none;
    }

    .level-images ul img {
        width: 75px !important;
    }

    .level-images ul li .image span {
        display: flex;
        align-items: start;
        justify-content: start;
    }

    .social-btns {
        flex-direction: column;
    }

    .social-btns div {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
    }

    .social-btns div:nth-child(1) {
        margin-right: 0 !important;
    }

    .chatbubble .chats .input button {
        padding: 6px;
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .cards-wrapper ul li {
        width: 100%;
        margin: 0 0 12px 0;
    }

    .claim-rewards .time-title {
        padding: 7px;
    }

    .claim-rewards .time-title strong {
        margin-bottom: 2px;
        font-size: 11px;
    }

    .claim-rewards .time-count {
        font-size: 12px;
    }

    .all-notifications {
        padding: 16px;
    }

    .title-heading {
        font-size: 18px;
    }

    .points-list .points-list-col {
        width: 100%;
    }

    .nfts-gallery ul li:first-child .nft-img-link,
    .nfts-gallery ul li:nth-child(2) .nft-img-link,
    .nfts-gallery ul li .nft-img-link {
        height: 200px;
    }

    .level-images ul img {
        width: 60px !important;
    }

    .level-images ul {
        display: flex;
        align-items: center;
        justify-content: space-evenly !important;
    }

    .heading h4 {
        font-size: 16px;
    }

    .score-content .d-flex {
        flex-direction: column;
    }

    .promotion-modal .modal-content .image {
        margin-left: 0;
    }

    .chatbubble {
        right: 0 !important;
    }

    .chatbubble.opened .unexpanded,
    .chatbubble .expanded,
    .chatbubble .chats .input,
    .chatbubble .chats .input.active {
        width: 280px !important;
        margin-left: auto;
        right: 0;
    }

    .chatbubble .unexpanded {
        width: 60px !important;
    }
}

@media (max-width: 375px) {
    .social-share ul li .image {
        width: 35px;
        height: 35px;
    }

    .notifications-model .notifications-model-content .left-content .avatar-img {
        width: 46px;
        height: 46px;
    }

    .notifications-model .notifications-model-content .left-content .avatar-img.image-1 {
        width: 57px;
        height: 57px;
    }

    .notifications-model .notifications-model-content .left-content .avatar-img.image-2 {
        left: 60px;
    }

    .notifications-model .notifications-model-content .left-content .avatar-img.image-3 {
        right: 75px;
    }
}

@media (max-width: 325px) {
    .social-share ul li span {
        font-size: 10px;
        line-height: 10px;
    }

    .social-share ul li {
        min-height: 110px;
    }

    .nfts-gallery ul li:first-child .nft-img-link,
    .nfts-gallery ul li:nth-child(2) .nft-img-link,
    .nfts-gallery ul li .nft-img-link {
        height: 170px;
    }

    .level-images ul img {
        width: 55px !important;
    }
}