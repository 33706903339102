.landing-banner h2{margin-bottom: 35px;}
.landing-banner p{color: #B4B4B4; font-size: 19px; margin-bottom: 60px; padding-right: 120px;}
.landing-banner .text-content{padding-right: 175px;}
.landing-banner .banner-main-lottie .banner-lottie-1{height: 600px; width: 600px; max-width: 100%;}
.landing-banner .banner-main-lottie .banner-lottie-2{position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 550px; height: 500px; max-width: 100%;}
.landing-banner .banner-main-lottie .banner-image-wrapper{position: absolute; top: 47%; left: 47%; transform: translate(-50%, -50%); z-index: 1;}
.landing-banner .banner-main-lottie .banner-image-wrap{width: fit-content; position: relative; width: 470px; height: 470px; max-width: 100%;}
.landing-banner .banner-main-lottie .banner-image-wrap .banner-image-layout{position: absolute;bottom: 15px;width: 292px;left: 50%;transform: translateX(-49%);z-index: 10;}
.landing-banner .banner-main-lottie .banner-image{position: absolute; top: 46%; left: 43%; transform: translate(-50%, -50%); width: 100%; height: 100%;}
.landing-banner .banner-main-lottie .banner-image img{width: 100%; height: 100%; object-fit: contain;}
.landing-banner .banner-img{width: 520px; height: 520px;}
.landing-banner .banner-img img{width: 100%; height: 100%; object-fit: cover;}
.landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img{position: absolute;width: 280px;top: 44%;left: 50%;transform: translate(-50%, -50%);z-index: 2; opacity: 0;animation-duration: 8s;
    animation-iteration-count: infinite;
    /* animation-direction: alternate; */
    animation-name: changeimage1;

}

.landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img-1 {
    z-index: 5;
    /* opacity: 1; */
    animation-delay: 0s;
}
.landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img-2 {
    z-index: 4;
    animation-delay: 2s;
}
.landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img-3 {
    z-index: 3;
    animation-delay: 4s;
}
.landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img-4 {
    z-index: 2;
    animation-delay: 6s;
}

@keyframes changeimage1 {
    0% {
      opacity: 1;
    }
    17% {
        opacity: 1;
       }
       25% {
        opacity: 0;
       }
       92% {
        opacity: 0;
       }
    100% {
     opacity: 1;
    }
  }

@media (max-width:1599px) {
    .landing-banner p{font-size: 18px;}
    .landing-banner .banner-img {width: 500px;height: 500px;}
}
@media (max-width:1399px) {
    .landing-banner p{font-size: 17px;}
    .landing-banner .banner-img {width: 470px;height: 470px;}
    .landing-banner .text-content{padding-right: 130px;}
    .landing-banner .banner-main-lottie .banner-lottie-1{height: 400px; width: 500px;}
    .landing-banner .banner-main-lottie .banner-image-wrap{width: 400px; height: 400px;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-image-layout{width: 250px;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img{width: 230px;}
    .landing-banner .banner-main-lottie .banner-image, .landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img{top: 50%;}
    .landing-banner .banner-main-lottie .banner-lottie-2{top: 55%;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-image-layout{bottom: 0;}
}
@media (max-width:1199px) {
    .landing-banner .banner-img {width: 450px;height: 450px;}
    .landing-banner .text-content{padding-right: 70px;}
    .landing-banner p{padding-right: 40px;}
    .landing-banner .banner-main-lottie .banner-image-wrap{width: 350px; height: 350px;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-image-layout{width: 216px;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img{width: 190px;}
    .landing-banner .banner-main-lottie .banner-lottie-2{width: 450px; height: 450px;}
}
@media (max-width:991px) {
    .landing-banner p{font-size: 16px; padding-right: 0;}
    .landing-banner .text-content{padding-right: 0; margin-bottom: 40px;}
    .landing-banner h2 {margin-bottom: 25px;}
    .landing-banner p {margin-bottom: 35px;}
    .landing-banner .banner-img{margin: 40px auto 0;}
    .landing-banner .banner-main-lottie .banner-lottie-1{margin: 0 auto; height: 500px; width: 600px;}
}
@media (max-width:767px) {
    .landing-banner .banner-img {width: 380px;height: 380px;}
}
@media (max-width:575px) {
    .landing-banner p{font-size: 15px;}
    .landing-banner .banner-img, .landing-banner .banner-main-lottie .banner-image-wrap {width: 300px;height: 300px;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-image-layout{width: 185px;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img{width: 162px;}
    .landing-banner .banner-main-lottie .banner-lottie-2{width: 360px; height: 360px;}
    .landing-banner .banner-main-lottie .banner-lottie-1{height: 400px; width: 500px;}

}
@media (max-width:400px) {
    .landing-banner .banner-img {width: 260px;height: 260px;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-image-layout{width: 155px;}
    .landing-banner .banner-main-lottie .banner-image-wrap {width: 250px;height: 250px;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img{width: 133px;}
}
@media (max-width:350px) {
    .landing-banner .banner-img {width: 240px;height: 240px;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-image-layout{width: 141px;}
    .landing-banner .banner-main-lottie .banner-image-wrap {width: 230px;height: 230px;}
    .landing-banner .banner-main-lottie .banner-image-wrap .banner-inner-img{width: 125px;}
    .landing-banner .banner-main-lottie .banner-lottie-1{height: 300px;}
}






