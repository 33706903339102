.footer-sec {
    background-color: #333639;
}

.footer-sec-padding {
    padding: 80px 96px 0 96px;
    margin-top: 30px;
}

.footer-sec .footer-logo {
    width: 50px;
    height: 50px;
}
.footer-sec .footer-logo img{width: 100%;height: 100%; object-fit: contain;}
.footer-sec .navbar-brand {
    color: #FFF;
    font-family: 'Neon';
    margin-bottom: 25px;
}

.footer-sec .footer-wrapper p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 60px;
}

.footer-sec .social-links li{
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 50px;
    font-size: 20px;
}

.footer-sec .social-links li {
    margin-bottom: 8px;
}

.footer-sec .social-links li.fa{
    color: #1877f2;
}

.footer-sec .social-links li.fa:hover {
    background-color: #1877f2;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.me {
    color: #02b875;
}

.footer-sec .social-links li.me:hover {
    background-color: #02b875;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.in{
    color: #c32aa3;
}

.footer-sec .social-links li.in:hover {
    background-color: #c32aa3;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.li{
    color: #0077B5;
}

.footer-sec .social-links li.yt {
    color: #ff0505;
}

.footer-sec .social-links li.li:hover {
    background-color: #0077B5;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.yt:hover {
    background-color: #df0404;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.re {
    color: #ff4500;
}

.footer-sec .social-links li.re:hover{
    background-color: #ff4500;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.gi{
    color: #171515;
}

.footer-sec .social-links li.gi:hover{
    background-color: #171515;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.te {
    color: #08c;
}

.footer-sec .social-links li.te:hover{
    background-color: #08c;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.yo {
    color: #FF2D2D;
}

.footer-sec .social-links li.yo:hover {
    background-color: #FF2D2D;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .social-links li.tw {
    color: #0995DE;
}

.footer-sec .social-links li.tw:hover{
    background-color: #0995DE;
    color: #fff;
    transition: .3s ease-in-out;
}

.footer-sec .gamification-logo span:first-child {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
}

.footer-sec .gamification-logo span:last-child {
    font-family: "Neon";
    text-transform: uppercase;
    font-size: 17px;
}

.footer-links-wrapper h5 {
    margin-bottom: 50px;
}

.footer-links-ul li {
    margin-bottom: 15px;
}

.footer-links-ul a {
    font-weight: 400;
    font-size: 18px;
}

.footer-links-ul a:hover {
    color: #97b5ff;
    padding-bottom: 5px;
    transition: .3s ease-in-out;
}

.footer-links-col {
    margin-bottom: 40px;
}

.footer-sec hr {
    border-top: 2px solid #404040;
    opacity: 1;
}
.footer-logo-text h5{margin-bottom: 0; font-size: 20px; font-family: "Neon"; line-height: 22px; font-weight: 500;font-weight: 500;letter-spacing: 2px;}
.footer-logo-text span{font-size: 13px; letter-spacing: 1px; font-family: "Neon"; line-height: 13px; font-weight: 500;}


@media (max-width:1599px) {
    .footer-sec .social-links li, .parent-tag .dropdown-nav section ul.social-links a {width: 40px;height: 40px;}
    .footer-links-ul a {font-size: 16px;}
    .footer-sec .footer-wrapper p {font-size: 17px;}
    .footer-links-ul a{font-size: 17px;}
}
@media (max-width:1399px) {
    .footer-sec .footer-logo {width: 45x;height: 45px;}
    .footer-sec .gamification-logo span:last-child {font-size: 16px;}
    .footer-sec .social-links li{width: 37px;height: 37px;font-size: 17px;}
    .footer-links-ul a {font-size: 15px;}
    .footer-sec .footer-wrapper p {font-size: 16px;}
    .footer-sec-padding {padding: 40px 35px 0px 35px;}
    .footer-links-wrapper h5 {margin-bottom: 20px;}
    .footer-sec .footer-wrapper p {margin-bottom: 40px;}
    .footer-links-col {margin-bottom: 20px;}
    .footer-links-ul li {margin-bottom: 10px;}
    .all-right-services {padding: 15px 0;}
}
@media (max-width:991px) {
    .footer-sec .gamification-logo span:first-child {width: 40px;height: 40px;}
    .footer-wrapper {margin-bottom: 20px;}
    .footer-sec-padding {padding: 30px 12px 0px 12px;}
    .footer-logo-text h5{font-size: 17px;}
    .footer-logo-text span{font-size: 11px;}
}
@media (max-width:767px) {
    .footer-sec .footer-wrapper p {margin-bottom: 20px;}
}
@media (max-width:575px) {
    .footer-sec-padding {padding: 20px 8px 0px 8px;}
    .footer-sec .footer-wrapper p {margin-bottom: 20px;}
    .footer-links-wrapper h5 {margin-bottom: 10px;}
    .footer-sec .footer-wrapper p {font-size: 14px;}
    .footer-links-ul a {font-size: 13px;}
}