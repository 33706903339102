.main-earning{background-color: #212329; padding: 70px 0;position: relative;}
.main-earning .landing-index{z-index: 1;position: relative;}
.main-earning .section-earning{margin-bottom: 80px;}
.main-earning .layer-1{position: absolute; width: 400px; height: 900px; max-width: 100%; top: -150px; left: 0; z-index: 0;}
.main-earning .layer-2{position: absolute; width: 650px; height: 650px; max-width: 100%; top: 500px; right: 0; z-index: 0;}
.main-earning .layer-3{position: absolute; width: 100%; height: 500px; max-width: 100%; bottom: 440px; left: 0; z-index: 0;}
.main-earning .layer-4{position: absolute; width: 100%; height: 400px; max-width: 100%; bottom: 100px; left: 0; z-index: 0;}
.main-earning .layer-1 img, .main-earning .layer-3 img{width: 100%; height: 100%; object-fit: contain;}
.main-earning .section-earning h2{margin-bottom: 80px;}
.main-earning .section-earning ul li{width: 330px; height: 330px; background-color: #fff; border-radius: 50%; position: relative; display: flex;
justify-content: center; align-items: center; padding: 7px; margin: 0 50px;}
.main-earning .section-earning .inner-points{width: 100%;height: 100%;color: #000;display: flex;flex-direction: column; justify-content: center;padding: 10px;border: 1px solid #545455;border-radius: 60%;}
.main-earning .section-earning ul li::before{content: "";position: absolute;left: 50%;top: 50%;width: 120%;height: 120%;display: inline-block;border-radius: 50%;border-top-color: transparent;border-bottom-color: transparent;transform: translate(-50%, -50%) rotate(90deg);}
.main-earning .section-earning ul li.one::before{border: 12px solid #ffaa00; border-top-color: transparent; border-bottom-color: transparent;}
.main-earning .section-earning ul li.two::before{border: 12px solid #00c3e6; border-top-color: transparent; border-bottom-color: transparent;}
.main-earning .section-earning ul li.three::before{border: 12px solid #4B3AD1; border-top-color: transparent; border-bottom-color: transparent;}
.main-earning .section-earning ul li h2{margin-bottom: 20px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis; padding: 0;}
.main-earning .section-earning ul li h2 span{font-size: 55px; font-family: "Neon"; padding: 0; margin-bottom: 20px;}
.main-earning .section-earning ul li .inner-points-h{font-weight: 500;}
.main-earning .section-earning ul li.one h2,.main-earning .section-earning ul li.one span{color: #ffaa00;}
.main-earning .section-earning ul li.two h2,.main-earning .section-earning ul li.two span{color: #00c3e6;}
.main-earning .section-earning ul li.three h2,.main-earning .section-earning ul li.three span{color: #4B3AD1;}
.main-earning .section-earning ul li span{font-size: 24px; text-transform: uppercase; padding: 0 38px;}
.main-earning .all-steps{padding: 10px 0; margin-bottom: 15px;}
.main-earning .all-steps h2{margin-bottom: 30px;font-family: "Neon";}
.main-earning .all-steps p{font-size: 19px; margin-bottom: 17px; color: #B4B4B4;}
.main-earning .all-steps .step-img{width: 470px; height: 470px;}
.main-earning .all-steps .step-img img{width: 100%; height: 100%; object-fit: contain;}
.main-earning .all-steps .step-content{padding-right: 70px;}
.stay-in-loop {padding: 50px 0;}
.stay-in-loop .stay-in-loop-holder{min-height: 370px;position: relative;}
.stay-in-loop h2{margin:0 auto;}
.stay-in-loop .signup-form-holder{text-align: center;position: relative;z-index: 11;}
.stay-in-loop h3{font-size: 35px;}
.stay-in-loop .lottie-animation-holder{position: absolute; width:344px;height:344px;border-radius:100%;overflow: hidden; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0.4;}
.stay-in-loop .lottie-animation-holder:after{content:"";width:100%;height:100%;position: absolute;left: 0;top:0;border-radius: 100%;overflow: hidden;background:url('https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1657095752/hex-nft/assets/stay-loop-spin_znhzmq.png');background-repeat: no-repeat;background-size: contain;}
.stay-in-loop .lottie-animation-holder > div{position: relative;z-index:1;width:100%;height:100%;}
.stay-in-loop .signup-form-holder h2{font-size: 31px;}
.stay-in-loop .signup-form-holder h3{font-size: 27px;}

@media(max-width:1720px){
    .stay-in-loop h3{font-size: 33px;}
}
@media(max-width: 1599px) {
    .main-earning .all-steps p{font-size: 18px;}
    .main-earning .section-earning ul li{width: 300px; height: 300px; margin: 0 40px;}
    .main-earning .section-earning ul li:first-child{margin-left: 0;}
    .main-earning .section-earning ul li:last-child{margin-right: 0;}
    .main-earning .section-earning ul li h2 span{font-size: 40px;}
    .main-earning .section-earning ul li span{font-size: 19px;}
    .main-earning .section-earning{margin-bottom: 55px;}
    .main-earning .all-steps .step-img{width: 440px; height: 440px;}
    .gamifi-legit .reward-box .lottie-wrap{width: 100px; height: 100px;}
    .gamifi-legit .reward-box h5{font-size: 18px; margin-bottom: 15px;}
    .gamifi-legit .reward-box p{font-size: 15px;}
    .gamifi-legit .reward-box{padding: 18px; min-height: 280px;}
    .stay-in-loop {padding: 40px 0;}
    .stay-in-loop h3{font-size: 29px;}
    .stay-in-loop .signup-form-holder h2{font-size: 28px;}
    .stay-in-loop .signup-form-holder h3{font-size: 25px;}
}
@media (max-width:1399px) {
    .main-earning .all-steps p{font-size: 17px;}
    .main-earning .section-earning ul li{width: 270px; height: 270px; margin: 0 35px;}
    .main-earning .section-earning ul li.one::before, 
    .main-earning .section-earning ul li.two::before, 
    .main-earning .section-earning ul li.three::before{border-width: 10px;}
    .main-earning .section-earning ul li h2 span{font-size: 30px;}
    .main-earning .section-earning ul li span{font-size: 17px;}
    .main-earning .all-steps .step-img{width: 430px; height: 430px;}
    .main-earning .all-steps .step-content{padding-right: 50px;}
    .gamifi-legit .reward-box .lottie-wrap{width: 80px; height: 80px;}
    .main-earning .layer-1{width: 200px; height: 600px; top: -120px; }
    .main-earning .layer-2{width: 500px; height: 500px; top: 300px; }
    .main-earning .layer-3{height: 400px; bottom: 340px; }
    .main-earning .layer-4{height: 350px; bottom: 70px; }
    .stay-in-loop {padding: 30px 0;}
    .stay-in-loop h3{font-size: 24px;}
    .stay-in-loop .signup-form-holder h2{font-size: 27px;line-height: 35px;}
    .stay-in-loop .signup-form-holder h3{font-size: 24px;}
}
@media (max-width:1199px) {
    .main-earning .section-earning h2{margin-bottom: 60px;}
    .main-earning .all-steps p{font-size: 16px;}
    .main-earning .section-earning ul li{width: 230px; height: 230px; margin: 0 30px;}
    .main-earning .section-earning ul li.one::before,
    .main-earning .section-earning ul li.two::before, 
    .main-earning .section-earning ul li.three::before{border-width: 8px;}
    .main-earning .section-earning ul li h2 span{font-size: 26px;}
    .main-earning .section-earning ul li span{font-size: 14px; padding: 0 33px;}
    .main-earning .all-steps .step-img{width: 400px; height: 400px;}
    .main-earning .all-steps .step-content{padding-right: 30px;}
    .main-earning{padding: 50px 0;}
    .main-earning .all-steps h2{margin-bottom: 25px;}
    .gamifi-legit .reward-box p{font-size: 14px;}
    .gamifi-legit .reward-box h5{font-size: 17px;}
    .main-earning .section-earning ul li h2{margin-bottom: 10px;}
    .stay-in-loop .lottie-animation-holder{width: 250px; height: 250px;}
}
@media (max-width:991px) {
    .main-earning .all-steps p, .main-earning .all-steps .step-content{padding-right: 0;}
    .main-earning .section-earning ul li{width: 190px; height: 190px; margin: 0 20px;}
    .main-earning .section-earning ul li.one::before,
    .main-earning .section-earning ul li.two::before, 
    .main-earning .section-earning ul li.three::before{border-width: 6px;}
    .main-earning .section-earning ul li::before{width: 116%; height: 116%;}
    .main-earning .section-earning ul li h2 span{font-size: 23px;}
    .main-earning .section-earning ul li span{font-size: 13px; padding: 0 18px;}
    .main-earning .all-steps .step-img{margin: 20px auto 10px;width: 380px; height: 380px;}
    .gamifi-legit .reward-box{max-width: 500px; margin: 0 auto 20px;}
    .stay-in-loop .signup-form-holder p{margin:0 0 30px;}
    .stay-in-loop .lottie-animation-holder{width:350px;height:350px;}
    .stay-in-loop .signup-form-holder h3{font-size: 22px;}
}

@media (max-width:767px) {
    .main-earning .section-earning ul li{width: 150px; height: 150px; margin: 0 15px;}
    .main-earning .section-earning ul li.one::before,
    .main-earning .section-earning ul li.two::before, 
    .main-earning .section-earning ul li.three::before{border-width: 5px;}
    .main-earning .section-earning ul li::before{width: 115%; height: 115%;}
    .main-earning .section-earning ul li h2 span{font-size: 21px;}
    .main-earning .section-earning ul li span{font-size: 12px; padding: 0 7px;}
    .main-earning .all-steps .step-img{width: 350px; height: 350px;}
    .main-earning .layer-4 {height: 300px;bottom: 10px;}
    .stay-in-loop .stay-in-loop-holder{min-height: 330px;}
    .stay-in-loop h3{font-size: 23px; line-height: 28px;}
    .main-earning{padding: 30px 0;}
    .stay-in-loop .signup-form-holder h2{font-size: 25px;}
    .stay-in-loop .signup-form-holder h3{font-size: 20px;}
}

@media (max-width:575px) {
    .main-earning .all-steps h2{margin-bottom: 20px;}
    .main-earning .section-earning h2{margin-bottom: 50px; padding: 0 60px;}
    .main-earning .all-steps p{font-size: 15px;}
    .main-earning .section-earning ul li:first-child, .main-earning .section-earning ul li:last-child, .main-earning .section-earning ul li{margin: 0 auto 60px;}
    .main-earning .section-earning ul li{width: 240px; height: 240px;}
    .main-earning .section-earning ul li h2 span{font-size: 24px;}
    .main-earning .section-earning ul li span{font-size: 14px; padding: 0 7px;}
    .main-earning .all-steps .step-img{width: 300px; height: 300px;}
    .main-earning{padding: 40px 0;}
    .main-earning .all-steps {padding: 20px 0;}
    .stay-in-loop h3{font-size: 19px;}
    .stay-in-loop .signup-form-holder h2{font-size: 21px; line-height: 30px;}
    .stay-in-loop .signup-form-holder h3{font-size: 16px;line-height: 23px;}

}
@media only screen and (max-width:479px){
    .stay-in-loop .lottie-animation-holder{width:255px;height:255px;}
    .stay-in-loop h3{font-size: 17px;}
    .stay-in-loop .signup-form-holder h2{font-size: 18px; line-height: 25px;}
    .stay-in-loop .signup-form-holder h3{font-size: 15px;}

}
@media (max-width:400px) {
    .main-earning .section-earning h2{margin-bottom: 40px;padding: 0 30px;}
    .main-earning .all-steps .step-img{width: 280px; height: 280px;}
    .main-earning .section-earning ul li{width: 220px; height: 220px;}
    .stay-in-loop .signup-form-holder h2{font-size: 16px;}
    .stay-in-loop .signup-form-holder h3{font-size: 14px;}
}
@media (max-width:350px) {
    .main-earning .section-earning h2{padding: 0px;}
    .main-earning .all-steps .step-img{width: 220px; height: 220px;}
    .main-earning .layer-3 {height: 350px;bottom: 240px;}
    .main-earning .section-earning ul li{width: 210px; height: 210px;}
}







