.theme-header .site-logo .name{font-family: "Neon";}
.theme-header .site-logo .name h5{font-weight: 500;letter-spacing: 2px; font-size: 20px;}
.theme-header .site-logo .name span{font-size: 13px;font-weight: 500;letter-spacing: 1px;}
.theme-header .site-logo .image{width: 50px; height: 50px;}
.theme-header .site-logo .image img{width: 100%; height: 100%; object-fit: contain;}
.theme-header .site-navlinks a{font-size:15px; font-family: "Neon"; letter-spacing: 0px; transition: all 0.3s; color: #FFFFFF; text-transform: uppercase;opacity: 0.4; margin-right: 40px; font-weight: 700;}
.theme-header .site-navlinks a:hover{opacity: 1;}
.theme-header .site-navlinks .btn-style-1{opacity: 1; padding: 12px 25px;font-family: "Neon";transition: all 0.3s;outline: 0;font-size: 16px;font-weight: 700;border-radius: 8px;color: #2CFAFE;border: 2px solid #2CFAFE;background-color: transparent;}
.theme-header .site-navlinks .btn-style-1:hover{color: #000; background-color: #2CFAFE;}
.theme-header .navbar-toggler{background: #1554f0;width: 40px;height: 40px;border-color: #1554f0; padding: 3px 6px;}
.theme-header .navbar-toggler:focus{box-shadow: none;}
.theme-header .navbar-toggler img {width: 22px;height: 22px; object-fit: cover;}

/* search btn */
.search-login-btn .search-icon {
    margin-left: 12px;
    padding: 8px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    border: 2px solid #1554F0;
    color: #FFF;
    background: transparent;
    transition: all 0.3s;
}
.search-login-btn .search-icon:hover {
    background: #1554F0;
    color: #fff;
}
.search-login-btn {
    position: relative;
    /* z-index: 2; */
}
.search-login-btn .refresh-search {
    position: absolute;
    right: 50px;
    background: transparent;
    border: none;
    color: #979494;
    top: 3px;
    z-index: 3;
    padding: 7px 12px;
}
.search-login-btn .input-width {
    width: 450px;
    padding: 10px 90px 10px 10px;
    opacity: 1;
    z-index: 3;
    border: 2px solid #1554f0;
}
.btn-align{height: 44px;opacity: 1;}
.search-login-btn input {
    width: 0;
    padding: 0;
    transition: all .3s ease-in-out;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 7px;
    background: transparent;
    border: none;
    opacity: 0;
    z-index: -1;
    /* border-right-width: 1px; */
}
.search-login-btn .search-btn-block{position: relative; z-index: 2;}

.search-login-btn input:focus-visible {
    outline: none;
}
.search-login-btn .login-btn {
    border: 2px solid #2CFAFE;
    border-radius: 6px;
    color: #2CFAFE;
    opacity: 1;
    padding: 9px 14px !important;
}

.search-login-btn .login-btn:hover {
    background-color: #2CFAFE;
    color: #030203;
}
.search-login-btn .search-icon.active{position: relative; z-index: 4; color: #fff;}
/* serach btn */




@media (max-width:1599px) {
    .theme-header .site-navlinks a{margin-right: 25px; font-size: 14px;}
    .btn-align {height: 43px;}
    .search-login-btn .search-icon{width: 43px;}
    .theme-header .site-navlinks .btn-style-1{padding: 11px 24px; font-size: 15px;}
}
@media (max-width:1399px) {
    .theme-header .site-navlinks a{margin-right: 25px; font-size: 13px;}
    .btn-align {height: 41px;}
    .search-login-btn .search-icon{width: 41px;}
    /* .theme-header .site-logo .name h5{font-size: 18px;} */
    .search-login-btn .refresh-search{right: 40px; top: 2px;}
    .theme-header .site-navlinks .btn-style-1{padding: 10px 22px; font-size: 13px; border-width: 1px;}
}

@media(max-width: 992px) {
    .navbar-collapse {
        position: absolute;
        top: calc(100% + 20px);
        width: 100%;
        background: #181515;
        padding: 14px;
        z-index: 9;
        border-radius: 0 0 15px 15px;
        left: 0;
    }
    .theme-header .navbar-toggler{width: 36px; height: 36px; border-radius: 5px;}
    .search-login-btn .search-icon{width: 36px; border-radius: 5px;}
    .btn-align{height: 36px; width: 36px; display: flex; justify-content: center; align-items: center; padding: 5px; border-radius: 5px;}
    .btn-align .icon{height: 17px; margin-top: 2px;}
    .search-login-btn .search-icon svg, .btn-align .icon svg{font-size: 15px;}
    .theme-header .site-logo .image{width: 45px; height: 45px;}
    .theme-header .site-navlinks a{margin-right: 0; padding: 12px;}
    .theme-header .site-logo .name h5{font-size: 17px;line-height: 20px;}
    .theme-header .site-logo .name span{font-size: 11px;}
    .search-login-btn .input-width{width: 270px; max-width: 100%;}
    .theme-header .site-navlinks .btn-style-1{padding: 9px 18px; font-size: 12px;}
}
@media(max-width: 767px) {
    .theme-header .navbar-toggler{width: 32px; height: 32px;}
    .search-login-btn .search-icon{width: 32px;}
    .btn-align{height: 32px; width: 32px;}
    .search-login-btn{position: static;}
    .theme-header .header-navbar{position: relative;}
    .search-login-btn input{top: 100%;}
    .search-login-btn .refresh-search{right: 0; top: 100%;}
    .navbar-collapse {
        top: calc(100% + 13px);
    }
}
@media(max-width: 575px) {
    .theme-header .navbar-toggler{width: 30px; height: 30px;}
    .search-login-btn .search-icon{width: 30px;}
    .btn-align{height: 30px; width: 30px;}
    .theme-header .site-logo .image{width: 35px; height: 35px;}
    .theme-header .site-logo .name h5{font-size: 16px; line-height: 16px;}
    .search-login-btn .search-icon svg, .btn-align .icon svg{font-size: 14px;}
    .theme-header .site-navlinks .btn-style-1 {padding: 6px 10px;font-size: 13px;font-weight: 400;border-radius: 5px; border-width: 1px; font-family: "Montserrat";}
}    
