.chatbubble {
    position: fixed;
    bottom: 0;
    right: 30px;
    transform: translateY(300px);
    transition: transform .3s ease-in-out;
    z-index: 1000000000000000;
}

.chatbubble.opened {
    transform: translateY(0)
}

.chatbubble .unexpanded .title {
    color: #f0f0f0;
    text-align: center;
    cursor: pointer;
    
}

.unexpanded{
    display: flex;
    padding:10px;
    justify-content: center;
    align-items: center;
    background: #1554F0;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
    margin-left: auto;
    cursor: pointer;
    /* transition: all 0.1s; */
}

.chatbubble .expanded {
    height: 300px;
    width: 350px;
    background-color: #fff;
    text-align: left;
    padding: 10px;
    color: #333;
    text-shadow: none;
    font-size: 14px;
}
.opened .unexpanded{width: 100%; border-radius: 5px 5px 0 0; margin-bottom: 0; justify-content: end; cursor: default;} 
.chatbubble .loader-wrapper {
    margin-top: 50px;
    text-align: center;
    display: none;  
}
.chatbubble .loader-wrapper.active {
    margin-top: 50px;
    text-align: center;
    display: block;
}


.chatbubble .messages {
  
    list-style: none;
    margin-bottom: 40px;
    padding: 0;
    display: none;
}
.chatbubble .messages.active {
    list-style: none;
    margin-bottom: 40px;
    padding: 0;
    display: block;
    text-align: start;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    height: 220px;
    background: none;
}

/* width */
.chatbubble .messages.active::-webkit-scrollbar {
   width: 6px;
 }
 
 /* Track */
 .chatbubble .messages.active::-webkit-scrollbar-track {
   border-radius: 10px;
 }
  
 /* Handle */
 .chatbubble .messages.active::-webkit-scrollbar-thumb {
   background: #fff; 
   border-radius: 10px;
 }
 .chatbubble .messages.active::-webkit-scrollbar-track{background: #1554F0;}
 /* Handle on hover */
 .chatbubble .messages.active::-webkit-scrollbar-thumb:hover {
   background: #e23e3e; 
 }

.chatbubble .messages li {
    width: 85%;
    float: left;
    padding: 10px;
    border-radius: 5px 5px 5px 0;
    font-size: 14px;
    background: #c9f1e6;
    margin-bottom: 10px;
    word-break: break-word;
    
}

.chatbubble .messages li .sender {
    font-weight: 500;
}

.chatbubble .messages li.reply {
    float: right;
    text-align: left;
    color: #fff;
    background: transparent linear-gradient(79deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0 5px;
    margin-left: 40px;
}

.chatbubble .chats .input {
    position: absolute;
    bottom: 0;
    padding: 10px;
    left: 0;
    width: 100%;
    background: #f0f0f0;
   display: none;
}
.chatbubble .chats .input.active {
    position: absolute;
    bottom: 0;
    padding: 10px;
    left: 0;
    width: 100%;
    background: #f0f0f0;
   display: block;
}
.date{
   font-size: 10px;
   font-weight: 500;
}
.chatbubble .chats .input .form-group {
    width: 80%;
}
.chatbubble .chats .input input {
    width: 100%;
}
.chatbubble .chats .input input:focus{box-shadow: none;}

.chatbubble .chats .input button {
    width: 20%;
    background: transparent linear-gradient(79deg, #1554F0 0%, #11D6B9 100%) 0% 0% no-repeat padding-box;
    border-color: #1554F0;
}

/* .chatbubble .chats {

} */

.chatbubble .join-screen {
margin-top: 20px;
display: none;
}

.chatbubble .chats.active,
.chatbubble .join-screen.active {
display: block;
text-align: center;
background: none;
}

/* Loader Credit: https://codepen.io/ashmind/pen/zqaqpB */
.chatbubble .loader {
color: #1554F0;
font-family: Consolas, Menlo, Monaco, monospace;
font-weight: bold;
font-size: 10vh;
opacity: 0.8;
}

.chatbubble .loader span {
display: inline-block;
-webkit-animation: pulse 0.4s alternate infinite ease-in-out;
        animation: pulse 0.4s alternate infinite ease-in-out;
}

.chatbubble .loader span:nth-child(odd) {
-webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
}

@-webkit-keyframes pulse {
to {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
}

}

@keyframes pulse {

to {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
}

}
.join-screen button {
   /* background: #e23e3e;
   border: 2px solid #e23e3e; */
   border-radius: 32px;
   height: 48px;
   width: 120px;
   color: white;
   font-weight: 400;
}
.join-screen button:hover {
   /* background: white; */
   /* border: 2px solid #e23e3e; */
   /* color: #e23e3e; */
   cursor: pointer;
   transform: scale(1.05);
   transition: 0.5s ease-in-out;
}

.input button {
   /* background: #e23e3e;
   border: 2px solid #e23e3e; */
   border-radius: 3px;
   height: 38px;
   color: white;
   font-weight: 400;
   align-items: center;
   margin-left: 2px;
}
.input button:hover {
   /* background: white;
   border: 2px solid #e23e3e;
   color: #e23e3e; */
   cursor: pointer;
   transform: scale(1.02);
   transition: 0.2s ease-in-out;
}
.form-inline{
   display: flex;
}
@keyframes append-animate {
	from {
		transform: translateX(200px) scale(0);
        opacity: 0;
	}
	to {
		transform: translateX(0) scale(1);
        opacity: 1;
		
	}
}
@keyframes append-animate-user {
	from {
		transform: translateX(-200px) scale(0);
		opacity: 0;
	}
	to {
		transform: translateX(0) scale(1);
		opacity: 1;
	}
}

 /* animate new box  */
.new-box {
	animation: append-animate .3s ease-in;
}
.new-box-user {
	animation: append-animate-user .3s ease-in;
}
.chatbubble {
    position: fixed;
    bottom: 0;
    right: 30px;
    transform: translateY(300px);
    transition: transform .3s ease-in-out;
    z-index: 1000000000000000;
}

.chatbubble.opened {
    transform: translateY(0)
}

.chatbubble .unexpanded .title {
    color: #fff;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    font-size: 23px;
    line-height: 23px;    
}
.opened .unexpanded .title{
    width: 30px;
    background-color: #ffffff80;
    border-radius: 5px;
    font-size: 17px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
}
.opened .unexpanded .title.expanded-title-window{
    background-color: transparent !important;
    width: 100% !important;
    height: 100% !important;
}
.loadingMessage{
    position: absolute;
    right: -20px;
    top: 0;
    width: 1rem;
    height: 1rem
}
.message{
    position: relative;
}
.historyLoader{
    position: absolute;
    right: 50%;
    left: 46%;
    top: 50%;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 1;
}
.historyLoaderWrapper{
    background-color: #c9f1e6;
    height: 300px;
    width: 330px;
}
    /* background-color: #f95858;
    border-radius: 2px;
    color: white;
    padding: 2px;
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-100%, -100%);
    max-width: 21px;
    width: fit-content;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center; */